import Paginations from "@/components/Paginations.vue";
export const dashboardServices = {
  components: {
    pagination: Paginations,
  },
  data: () => ({
    rows: [],
    currentPage: 0,
    rowCountPage: 45,
    totalRows: 0,
    pageRange: 10,
    recordsFrom: 0,
    recordsTo: 0,
    fetchUrl: null,
    fetchedData: {},
    editIndex: null,
    newFilter: {
      from: new Date()
        .toLocaleString("fr-CA", { timeZone: "Africa/Kigali" })
        .slice(0, 10),
      to: new Date()
        .toLocaleString("fr-CA", { timeZone: "Africa/Kigali" })
        .slice(0, 10),
    },
    checkedRows: [],
    checkAll: false,
  }),
  computed: {
    hasKeyword() {
      return this.filterKey != null && this.filterKey.trim().length >= 2;
    },
    reportLabel() {
      let from = !this.newFilter.from
        ? new Date()
            .toLocaleString("fr-CA", { timeZone: "Africa/Kigali" })
            .slice(0, 10)
        : this.newFilter.from;
      if (!this.newFilter.to) return this.chatDate(from);
      else
        return `${this.chatDate(from)} - ${this.chatDate(this.newFilter.to)}`;
    },
  },
  created() {
    this.handlePaginationSearch();
  },
  watch: {
    checkAll() {
      var checkbox = document.querySelectorAll(".row-checker");
      if (this.checkAll)
        checkbox.forEach((elt) => {
          if (!elt.checked) elt.click();
        });
      else
        checkbox.forEach((elt) => {
          if (elt.checked) elt.click();
        });
    },
  },
  methods: {
    handlePaginationSearch(fetchUrl = null) {
      let url = this.fetchUrl;
      if (typeof fetchUrl == "string") {
        url = fetchUrl;
      }
      if (this.hasKeyword) {
        this.$store.commit("SET_REQUEST_FLAG", "PAGINATION_SEARCH");
        if (url.indexOf("?") > -1)
          url += `&q=${this.filterKey}&per_page=${this.rowCountPage}`;
        else url += `?q=${this.filterKey}&per_page=${this.rowCountPage}`;
      }
      this.$store.dispatch("getRequest", url).then((response) => {
        this.rows = response.data.rows.data;
        this.currentPage = response.data.rows.current_page;
        this.totalRows = response.data.rows.total;
        this.recordsFrom = response.data.rows.from;
        this.recordsTo = response.data.rows.to;
        this.fetchedData = response.data;
      });
    },
    handlePagination(obj) {
      this.rowCountPage = Number(obj.per_page);
      var url = `${this.fetchUrl}?page=${obj.page_number}&per_page=${obj.per_page}&`;
      if (this.filterKey) {
        url = `${this.fetchUrl}?page=${obj.page_number}&per_page=${obj.per_page}&q=${this.filterKey}&`;
      }
      let data = this.newFilter;
      for (let d in data) {
        if (!this.empty(data[d]))
          url +=
            encodeURIComponent(d) + "=" + encodeURIComponent(data[d]) + "&";
      }
      this.$store.dispatch("getRequest", url).then((response) => {
        this.rows = response.data.rows.data;
        this.currentPage = response.data.rows.current_page;
        this.recordsFrom = response.data.rows.from;
        this.recordsTo = response.data.rows.to;
        this.totalRows = response.data.rows.total;
      });
    },
    computeUserAvatar(user) {
      var firstname = user.first_name[0];
      var lastname = user.last_name ? user.last_name[0] : "";
      return `${firstname} ${lastname}`;
    },
    /*getDateDifference(date, otherDate) {
      const diffDays = (date, otherDate) =>
        Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));
      return diffDays;
    }, */
    handleRecordEdit(rows, record, index, url) {
      this.$store
        .dispatch("postRequest", {
          url,
          formData: this.formData(record),
        })
        .then((response) => {
          if (response.data.status) {
            rows[index] = response.data.record;
            this.toggleModal();
          }
        });
    },
    encodeQuery(url, data) {
      let query = "";
      for (let d in data) {
        if (!this.empty(data[d]))
          query +=
            encodeURIComponent(d) + "=" + encodeURIComponent(data[d]) + "&";
      }
      return `${url}?${query.slice(0, -1)}`;
    },
    handlePaginationFilter() {
      let fetchUrl = this.encodeQuery(this.fetchUrl, this.newFilter);
      this.$store.commit("SET_REQUEST_FLAG", "PAGINATION_SEARCH");
      this.handlePaginationSearch(fetchUrl);
    },
    generateVoucherNo(no) {
      let len = no.toString().length;
      console.log(len);
      if (len >= 4) return no;
      if (len == 1) return `000${no}`;
      if (len == 2) return `00${no}`;
      if (len == 3) return `0${no}`;
    },
  },
};

/**
 * 'en-CA'
  'fr-CA'
  'lt-LT'
  'sv-FI'
  'sv-SE'
 */
