var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"nk-content-inner"},[_c('div',{staticClass:"nk-content-body"},[_c('div',{staticClass:"components-preview wide-md mx-auto"},[_vm._m(0),_c('div',{staticClass:"nk-block nk-block-lg"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-inner"},[_c('form',{staticClass:"\n                  nk-wizard nk-wizard-simple\n                  is-vertical is-alter\n                  wizard\n                  clearfix\n                "},[_c('div',{staticClass:"content clearfix"},[_c('div',{staticClass:"nk-wizard-content body current"},[_c('div',{staticClass:"row gy-3"},[_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"fw-vr-first-name"}},[_vm._v("Base Color")]),_c('div',{staticClass:"d-flex align-items-center no-gutters"},[_c('div',{staticClass:"col-10"},[_c('div',{staticClass:"form-control-wrap"},[_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.newSettings.base_color),expression:"newSettings.base_color"}],staticClass:"\n                                    form-control\n                                    required\n                                    no-right-radius\n                                  ",class:{
                                    'is-invalid': _vm.errors.has('Base Color'),
                                  },attrs:{"type":"text","name":"Base Color","placeholder":"Eg: #1dbe72"},domProps:{"value":(_vm.newSettings.base_color)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newSettings, "base_color", $event.target.value)}}})])]),_c('div',{staticClass:"col-2"},[_c('div',{staticClass:"form-control-wrap"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newSettings.base_color),expression:"newSettings.base_color"}],staticClass:"form-control no-left-radius",attrs:{"type":"color"},domProps:{"value":(_vm.newSettings.base_color)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newSettings, "base_color", $event.target.value)}}})])])])])]),_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"fw-vr-last-name"}},[_vm._v("Second Color")]),_c('div',{staticClass:"d-flex align-items-center no-gutters"},[_c('div',{staticClass:"col-10"},[_c('div',{staticClass:"form-control-wrap"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newSettings.secondary_color),expression:"newSettings.secondary_color"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"\n                                    form-control\n                                    required\n                                    no-right-radius\n                                  ",class:{
                                    'is-invalid': _vm.errors.has('Second Color'),
                                  },attrs:{"type":"text","placeholder":"Eg: #364a63","name":"Second Color"},domProps:{"value":(_vm.newSettings.secondary_color)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newSettings, "secondary_color", $event.target.value)}}})])]),_c('div',{staticClass:"col-2"},[_c('div',{staticClass:"form-control-wrap"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newSettings.secondary_color),expression:"newSettings.secondary_color"}],staticClass:"form-control no-left-radius",attrs:{"type":"color"},domProps:{"value":(_vm.newSettings.secondary_color)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newSettings, "secondary_color", $event.target.value)}}})])])])])]),_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("App Name")]),_c('div',{staticClass:"form-control-wrap"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newSettings.site_name),expression:"newSettings.site_name"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has('App Name'),
                              },attrs:{"type":"text","placeholder":"Eg: Stockify","name":"App Name"},domProps:{"value":(_vm.newSettings.site_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newSettings, "site_name", $event.target.value)}}})])])]),_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("TIN number")]),_c('div',{staticClass:"form-control-wrap"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newSettings.app_tin),expression:"newSettings.app_tin"}],staticClass:"form-control",attrs:{"type":"number","placeholder":"TIN Number"},domProps:{"value":(_vm.newSettings.app_tin)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newSettings, "app_tin", $event.target.value)}}})])])]),_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Email")]),_c('div',{staticClass:"form-control-wrap"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newSettings.app_email),expression:"newSettings.app_email"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has('Email'),
                              },attrs:{"type":"email","placeholder":"Eg: john@company.com","name":"Email"},domProps:{"value":(_vm.newSettings.app_email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newSettings, "app_email", $event.target.value)}}})])])]),_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Telephone")]),_c('div',{staticClass:"form-control-wrap"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newSettings.app_phone),expression:"newSettings.app_phone"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control no-left-radius",class:{
                                'is-invalid': _vm.errors.has('Phone Number'),
                              },attrs:{"type":"tel","placeholder":"Eg: Phone number","name":"Phone Number"},domProps:{"value":(_vm.newSettings.app_phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newSettings, "app_phone", $event.target.value)}}})])])]),_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Address Line")]),_c('div',{staticClass:"form-control-wrap"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newSettings.site_address),expression:"newSettings.site_address"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control no-left-radius",class:{
                                'is-invalid': _vm.errors.has('Address'),
                              },attrs:{"type":"text","placeholder":"KN 145 ST 76, Nyarugenge, Kigali, Rwanda","name":"Address"},domProps:{"value":(_vm.newSettings.site_address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newSettings, "site_address", $event.target.value)}}})])])]),_c('div',{staticClass:"col-lg-2 col-sm-3"},[_c('input-file',{attrs:{"label":'Logo(<em>250px x 64x</em>)',"current-file":_vm.newSettings.site_logo},on:{"uploaded":function($event){return _vm.handleUploadedFile($event, 'site_logo')}}})],1),_c('div',{staticClass:"col-lg-2 col-sm-3"},[_c('input-file',{attrs:{"label":'Favicon',"current-file":_vm.newSettings.favicon},on:{"uploaded":function($event){return _vm.handleUploadedFile($event, 'favicon')}}})],1),_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Currency")]),_c('div',{staticClass:"form-control-wrap"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.newSettings.currency),expression:"newSettings.currency"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control no-left-radius",class:{
                                'is-invalid': _vm.errors.has('Currency'),
                              },attrs:{"type":"text","name":"Currency"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.newSettings, "currency", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"null","disabled":"","hidden":""}},[_vm._v(" Select Currency ")]),_c('option',{attrs:{"value":"RWF"}},[_vm._v("RWF")]),_c('option',{attrs:{"value":"$"}},[_vm._v("USD")])])])])])])])]),_c('hr'),_c('div',{staticClass:"actions clearfix float-right"},[_c('wolf-button',{attrs:{"classes":"btn btn-primary","disabler":_vm.submitDisabled,"activator":"saving_records"},on:{"btn-role":_vm.handleSubmit}},[_c('span',{attrs:{"slot":"caption"},slot:"caption"},[_c('em',{staticClass:"icon ni ni-save"}),_c('span',[_vm._v("Save Changes")])])])],1)])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nk-block-head nk-block-head-lg wide-sm"},[_c('div',{staticClass:"nk-block-head-content"},[_c('div',{staticClass:"nk-block-head-sub"},[_c('a',{staticClass:"back-to",attrs:{"href":"javascript:void(0)"}},[_c('em',{staticClass:"icon ni ni-arrow-left"}),_c('span',[_vm._v("Settings")])])]),_c('h5',{staticClass:"nk-block-title mb-0"},[_vm._v("General Settings")])])])}]

export { render, staticRenderFns }