<template>
  <div class="position-relative">
    <div class="">
      <input
        type="search"
        class="form-control form-control-lg"
        :id="componentId"
        :placeholder="placeholder"
        @focus="searchActive = true"
        autocomplete="off"
        v-model="filterKeyword"
      />
    </div>
    <div
      class="vanilla-autocomplete-suggestions"
      style="display: block; left: 0; top: 40px; width: 100%"
      v-if="filterKeyword && filteredData.length && searchActive"
    >
      <div
        class="vanilla-autocomplete-suggestion"
        v-for="(data, i) in filteredData"
        :key="'auto-search' + componentId + i"
        @click="handleSelectedOption(data)"
      >
        {{ data[label] }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AutoComplete",
  props: {
    tracks: {
      type: Array,
      default: function () {
        return [];
      },
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    componentId: {
      type: String,
      default: "AutoComplete",
    },
    placeholder: {
      type: String,
      default: "Search for an record",
    },
  },
  data: () => ({
    filterKeyword: null,
    searchActive: false,
  }),
  computed: {
    filteredData: {
      get: function () {
        let filterKeyword =
          this.filterKeyword && this.filterKeyword.toLowerCase();
        let tracks = this.tracks;
        if (filterKeyword) {
          tracks = tracks.filter((row) => {
            return Object.keys(row).some((key) => {
              return String(row[key]).toLowerCase().indexOf(filterKeyword) > -1;
            });
          });
        }
        return tracks;
      },
      set: function (args = []) {
        return args;
      },
    },
  },
  methods: {
    handleSelectedOption(option) {
      this.$emit("selected", option);
      this.searchActive = false;
      this.filterKeyword = null;
    },
  },
};
</script>
<style lang="css" scoped>
.area-input {
  margin: 10px;
  padding: 10px;
  text-align: center;
  position: relative;
}
input {
  outline: 0;
}
input[type="search"]:not(.form-control) {
  width: 100%;
  padding: 10px;
  border: solid 1px transparent;
  border-radius: 2px;
  font-size: 1em;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.vanilla-autocomplete-suggestions::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.vanilla-autocomplete-suggestions::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}
.vanilla-autocomplete-suggestions::-webkit-scrollbar-track {
  background: #fff;
}
.vanilla-autocomplete-suggestions::-webkit-scrollbar {
  width: 5px;
  border-radius: 20px;
}

.vanilla-autocomplete-suggestions {
  text-align: left;
  cursor: default;
  border-top: 0;
  background-color: #ffff !important;
  border: 1px solid #fefefe !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  display: none;
  z-index: 9999;
  max-height: 254px;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
}

.vanilla-autocomplete-suggestion {
  position: relative;
  padding: 14px 16px;
  line-height: 5px;
  overflow: hidden;
  font-size: 16px;
  color: #333;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.vanilla-autocomplete-suggestion.selected {
  background: rgba(0, 0, 0, 0.08);
}
</style>
