<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="wide-xl mx-auto">
          <div class="card card-stretch p-4">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">
                      <span v-if="empty(record)">Receive Items</span>
                      <span v-else
                        >Edit Receiving &nbsp;
                        <a href="javascript:void(0)"
                          >#{{ record.code }}</a
                        ></span
                      >
                    </h3>
                    <div class="nk-block-des text-soft">
                      <p v-if="!empty(order)">
                        Receiver Items from purchase order
                        <span class="text-primary"> #{{ order.code }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="nk-block-head-content w-25">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="javascript:void(0)"
                        class="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                        ><em class="icon ni ni-more-v"></em
                      ></a>
                      <div
                        class="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul class="nk-block-tools g-3">
                          <li v-if="objectType == null">
                            <type-head
                              url="stock/products/search"
                              label="name"
                              tracker="name"
                              placeholder="Search for a product"
                              @changed="handleSelectedItem"
                            ></type-head>
                          </li>
                          <li class="w-50" v-if="false">
                            <div class="form-control-wrap w-100">
                              <auto-complete
                                :tracks="rows"
                                label="name"
                                placeholder="Search by item name"
                                value="id"
                                @selected="handleSelectedItem"
                              ></auto-complete>
                            </div>
                          </li>
                          <li class="nk-block-tools-opt" v-if="false">
                            <button
                              type="button"
                              class="btn btn-icon btn-primary d-md-none"
                            >
                              <em class="icon ni ni-plus"></em>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="items.length > 0">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col nk-tb-col-check">
                      <div
                        class="
                          custom-control custom-control-sm custom-checkbox
                          notext
                        "
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="pid"
                        /><label class="custom-control-label" for="pid"></label>
                      </div>
                    </div>
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Name</span></div>
                    <div class="nk-tb-col"><span>Code</span></div>
                    <div class="nk-tb-col"><span>Cost Price</span></div>
                    <div class="nk-tb-col"><span>Quantity</span></div>
                    <div class="nk-tb-col"><span>Total Amount</span></div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>
                  <div
                    class="nk-tb-item"
                    v-for="(product, i) in items"
                    :key="'product' + i"
                  >
                    <div class="nk-tb-col nk-tb-col-check">
                      <div
                        class="
                          custom-control custom-control-sm custom-checkbox
                          notext
                        "
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="pid1"
                        /><label
                          class="custom-control-label"
                          for="pid1"
                        ></label>
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ i + 1 }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ product.name }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">#{{ product.code }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <div class="form-control-wrap">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">{{
                              appSettings.currency
                            }}</span>
                          </div>
                          <input
                            type="number"
                            class="form-control"
                            v-model="items[i].price"
                            :placeholder="`Price in ${appSettings.currency}`"
                            :name="'Cost Price' + i"
                            :class="{
                              'is-invalid': errors.has('Cost Price' + i),
                            }"
                            v-validate="'required'"
                            @keyup="findTotalAmount(product)"
                          />
                        </div>
                        <span
                          class="text-danger"
                          v-if="errors.has('Cost Price' + i)"
                          >Cost Price is required</span
                        >
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <input
                        type="number"
                        :name="'Quantity' + i"
                        v-model="items[i].quantity"
                        :class="{ 'is-invalid': errors.has('Quantity' + i) }"
                        v-validate="'required'"
                        class="form-control"
                        placeholder="Quantity"
                        @keyup="findTotalAmount(product)"
                      />
                      <span
                        class="text-danger"
                        v-if="errors.has('Quantity' + i)"
                        >Quantity is required</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-sub">
                        {{ appSettings.currency }}
                        {{ addComma(items[i].total_amount) }}
                      </span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <a
                        href="javascript:void(0)"
                        @click.prevent="removeItem(i)"
                        ><em class="icon ni ni-trash text-danger"></em
                        ><span></span
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="w-100" v-if="items.length > 0">
                  <hr class="preview-hr" />
                  <div class="float-right w-25">
                    <h6
                      class="d-flex mb-3 align-items-center justify-content-end"
                    >
                      Grand Total:
                      <span class="ml-auto"
                        >{{ appSettings.currency }}
                        {{ addComma(grandTotal) }}</span
                      >
                    </h6>
                  </div>
                  <div class="w-100">
                    <div
                      class="
                        d-flex
                        justify-content-end
                        align-items-center
                        actions
                        w-100
                      "
                    >
                      <div class="col-3">
                        <multiselect
                          v-model="supplier"
                          :options="suppliers"
                          placeholder="Select supplier"
                          label="name"
                          track-by="name"
                        ></multiselect>
                      </div>

                      <div
                        class="form-group col-3"
                        style="flex: 0 0 13%; max-width: 13%"
                      >
                        <ul class="custom-control-group">
                          <li>
                            <div
                              class="
                                custom-control
                                custom-control-sm
                                custom-checkbox
                                custom-control-pro
                              "
                            >
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                v-model="has_invoice"
                                id="btnRadio1"
                                value="1"
                              /><label
                                class="custom-control-label"
                                for="btnRadio1"
                                style="white-space: nowrap"
                                >Invoice Given</label
                              >
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div
                        class="form-group col mb-0"
                        v-if="has_invoice == 1"
                        style="flex: 0 0 20%; max-width: 20%"
                      >
                        <select
                          class="form-control form-control-lg"
                          v-model="vat"
                        >
                          <option value="INCLUSIVE">VAT Inclusive</option>
                          <option value="EXCLUSIVE">VAT Exclusive</option>
                        </select>
                      </div>
                      <div class="form-group col-3 mb-0">
                        <input
                          type="date"
                          v-model="received_date"
                          class="form-control form-control-lg"
                          placeholder="Receiving Date"
                        />
                      </div>
                      <wolf-button
                        classes="btn btn-primary ml-2"
                        @btn-role="handleSubmit"
                        :disabler="submitDisabled"
                        activator="saving_records"
                      >
                        <span slot="caption"
                          ><em class="icon ni ni-save"></em
                          ><span>Confirm and Save</span></span
                        >
                      </wolf-button>
                    </div>
                  </div>
                </div>
                <div class="row g-gs" v-else>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="example-alert">
                          <div class="alert alert-pro alert-danger">
                            <div class="alert-text">
                              <h6>You haven't added an item</h6>
                              <p>
                                No items found in receiving order. Search for
                                product and add to the list.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AutoComplete from "@/components/AutoComplete.vue";
import TypeHead from "@/components/TypeHead.vue";
import Multiselect from "vue-multiselect";
export default {
  name: "Reload",
  components: { AutoComplete, Multiselect, TypeHead },
  data: () => ({
    items: [],
    rows: [],
    selectedItemIds: [],
    suppliers: [],
    supplier: null,
    received_date: new Date()
      .toLocaleString("fr-CA", { timeZone: "Africa/Kigali" })
      .slice(0, 10),
    has_invoice: 0,
    vat: "EXCLUSIVE",
    objectType: null,
    isEditMode: false,
    order: null,
    order_id: null,
    record: {},
  }),
  computed: {
    grandTotal() {
      return this.items.reduce(
        (prev, cur) => prev + Number(cur.total_amount),
        0
      );
    },
    submitDisabled() {
      return !this.supplier || !this.items.length;
    },
  },
  beforeCreate() {
    let reference = this.$route.params.reference;
    if (reference) {
      localStorage.removeItem("receiveItems");
      this.$store
        .dispatch("getRequest", `orders/report/${reference}/details`)
        .then((response) => {
          let items = response.data.items;
          this.objectType = "PURCHASE_ORDER";
          this.order = response.data.order;
          if (!this.empty(this.order)) {
            this.order_id = this.order.id;
            this.supplier = this.order.vendor;
          }
          items.forEach((item) => {
            this.items.push({
              id: item.product.id,
              name: item.product.name,
              code: item.product.code,
              quantity: item.quantity,
              total_amount: item.total_amount,
              price: item.price,
              current_qty: item.product.quantity,
            });
          });
        });
    } else {
      let action = this.$route.query.action;
      let reference = this.$route.query.reference;
      if (typeof action == "string" && action == "edit") {
        localStorage.removeItem("receiveItems");
        this.$store
          .dispatch("getRequest", `/reports/receive/${reference}/voucher`)
          .then((response) => {
            let items = response.data.items;
            this.record = response.data.record;
            this.supplier = this.record.vendor;
            this.vat = this.record.vat;
            this.has_invoice = this.record.has_invoice;
            this.received_date = new Date(this.record.received_date)
              .toLocaleString("fr-CA", { timeZone: "Africa/Kigali" })
              .slice(0, 10);
            items.forEach((item) => {
              this.items.push({
                id: item.product.id,
                name: item.product.name,
                code: item.product.code,
                quantity: item.quantity,
                total_amount: item.quantity * item.price,
                price: item.price,
              });
              this.selectedItemIds.push(item.product.id);
            });
          });
      }
    }
  },
  mounted() {
    this.$store
      .dispatch("getRequest", "settings/suppliers")
      .then((response) => {
        this.suppliers = response.data.rows.data;
      });
  },
  created() {
    let reference = this.$route.params.reference;
    if (!reference) {
      let cart = localStorage.getItem("receiveItems");
      if (cart) {
        let result = JSON.parse(cart);
        this.items = result.items;
        this.selectedItemIds = result.selectedItemIds;
      }
    } else localStorage.removeItem("receiveItems");
    let action = this.$route.query.action;
    this.isEditMode = typeof action == "string" && action == "edit";
  },
  beforeDestroy() {
    if (this.items.length) {
      if (!this.objectType && !this.isEditMode)
        localStorage.setItem(
          "receiveItems",
          JSON.stringify({
            items: this.items,
            selectedItemIds: this.selectedItemIds,
          })
        );
      else localStorage.removeItem("receiveItems");
    }
  },
  watch: {
    items() {
      if (!this.items.length) {
        localStorage.removeItem("receiveItems");
      }
    },
  },
  methods: {
    removeItem(key) {
      this.items.splice(key, 1);
      this.selectedItemIds.splice(key, 1);
      localStorage.setItem(
        "receiveItems",
        JSON.stringify({
          items: this.items,
          selectedItemIds: this.selectedItemIds,
        })
      );
    },
    handleSelectedItem(item) {
      if (!this.selectedItemIds.includes(item.id)) {
        this.items = this.handleArrayPush(this.items, {
          id: item.id,
          name: item.name,
          code: item.code,
          quantity: 1,
          total_amount: item.cost_price || item.cost,
          price: item.cost_price || item.cost,
          current_qty: item.quantity,
        });
        this.selectedItemIds = this.handleArrayPush(
          this.selectedItemIds,
          item.id
        );
      } else {
        this.$notifier({
          type: "info",
          message: "Item already added to the list",
        });
      }
    },
    findTotalAmount(item) {
      item.total_amount = Number(item.price) * Number(item.quantity);
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store
            .dispatch("postRequest", {
              url: "stock/products/receive",
              formData: this.formData({
                order_id: this.order_id,
                supplier: this.supplier.id,
                received_date: this.received_date,
                has_invoice: this.has_invoice,
                vat: this.vat,
                items: this.items,
                total_amount: this.grandTotal,
                record: this.record.id || null,
                record_items: this.selectedItemIds,
                object_type: this.objectType,
              }),
            })
            .then((response) => {
              if (response.data.status) {
                localStorage.removeItem("receiveItems");
                this.supplier = null;
                // this.received_date = new Date()
                //   .toLocaleString("fr-CA", { timeZone: "Africa/Kigali" })()
                //   .slice(0, 10);
                this.has_invoice = 1;
                this.items = [];
                this.selectedItemIds = [];
                if (this.order_id) {
                  this.$store.commit("SET_FLASH_MESSAGE", {
                    type: "success",
                    message: response.data.message,
                  });
                }
                if (!this.empty(this.record)) {
                  this.$router.replace({ name: "ReceiveReport" });
                }
              }
            })
            .catch((err) => console.log(err));
        }
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
