<template>
  <div class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch p-4">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">Sales Items</h3>
                    <div class="nk-block-des text-soft">
                      <p>You have total {{ totalRows }} sales items</p>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="javascript:void(0)"
                        class="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                        ><em class="icon ni ni-more-v"></em
                      ></a>
                      <div
                        class="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul class="nk-block-tools g-3">
                          <li>
                            <div class="form-control-wrap">
                              <div class="form-icon form-icon-right">
                                <em class="icon ni ni-search"></em>
                              </div>
                              <input
                                type="text"
                                class="form-control"
                                id="default-04"
                                placeholder="Search by name"
                                v-model="filterKey"
                                @keyup="handlePaginationSearch"
                              />
                            </div>
                          </li>
                          <li v-if="false">
                            <div class="dropdown">
                              <a
                                href="javascript:void(0)"
                                class="
                                  dropdown-toggle dropdown-indicator
                                  btn btn-outline-light btn-white
                                "
                                data-toggle="dropdown"
                                aria-expanded="false"
                                >Status</a
                              >
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                style=""
                              >
                                <ul class="link-list-opt no-bdr">
                                  <li>
                                    <a href="javascript:void(0)"
                                      ><span>New Items</span></a
                                    >
                                  </li>
                                  <li>
                                    <a href="javascript:void(0)"
                                      ><span>Out of Stock</span></a
                                    >
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </li>
                          <li class="nk-block-tools-opt">
                            <router-link
                              :to="{ name: 'BranchItemsCreator' }"
                              class="toggle btn btn-icon btn-primary d-md-none"
                              ><em class="icon ni ni-plus"></em></router-link
                            ><router-link
                              :to="{ name: 'BranchItemsCreator' }"
                              class="
                                toggle
                                btn btn-primary
                                d-none d-md-inline-flex
                              "
                              ><em class="icon ni ni-plus"></em
                              ><span>Add New</span></router-link
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="totalRows > 0">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col nk-tb-col-check">
                      <div
                        class="
                          custom-control custom-control-sm custom-checkbox
                          notext
                        "
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="pid"
                          v-model="checkAll"
                        /><label class="custom-control-label" for="pid"></label>
                      </div>
                    </div>
                    <div class="nk-tb-col"><span>Name</span></div>
                    <div class="nk-tb-col"><span>Code</span></div>
                    <div class="nk-tb-col"><span>Item Type</span></div>
                    <div class="nk-tb-col"><span>Sales Price</span></div>
                    <div class="nk-tb-col"><span>Count Unit</span></div>
                    <div class="nk-tb-col"><span>Quantity</span></div>
                    <div class="nk-tb-col"><span>Ingredient</span></div>
                    <div class="nk-tb-col"><span>Total cost</span></div>
                    <div class="nk-tb-col"><span>Perc (%)</span></div>
                    <div class="nk-tb-col">
                      <span><em class="icon ni ni-setting"></em></span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1 my-n1">
                        <li class="mr-n1">
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger"
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <a href="javascript:void(0)"
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Remove Selected</span></a
                                  >
                                </li>
                                <li v-if="false">
                                  <a href="javascript:void(0)"
                                    ><em class="icon ni ni-setting"></em
                                    ><span>Configure Selected</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="nk-tb-item"
                    v-for="(product, i) in rows"
                    :key="'product' + i"
                    loading="lazy"
                  >
                    <div class="nk-tb-col nk-tb-col-check">
                      <div
                        class="
                          custom-control custom-control-sm custom-checkbox
                          notext
                        "
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input row-checker"
                          :id="'uid' + i"
                          v-model="checkedRows"
                          :value="product.id"
                        /><label
                          class="custom-control-label"
                          :for="'uid' + i"
                        ></label>
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ product.name }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">#{{ product.code }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{ product.type || "N/A" }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead"
                        >{{ appSettings.currency }}
                        {{ addComma(product.price) || "N/A" }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-sub">{{ product.unit }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-sub">{{ product.quantity }}</span>
                    </div>
                    <div class="nk-tb-col text-center">
                      <span class="tb-sub">{{
                        product.ingredients || "N/A"
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead"
                        ><span v-if="product.cost_price"
                          >{{ appSettings.currency }} </span
                        >{{ `${addComma(product.cost_price) || "N/A"}` }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-sub"
                        >{{
                          calculateItemPercentage(product).toFixed(2)
                        }}%</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span v-if="product.ingredients"
                        ><em
                          class="icon ni ni-check-circle-cut text-primary"
                        ></em
                      ></span>
                      <span v-else
                        ><em class="icon ni ni-clock text-warning"></em
                      ></span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1 my-n1">
                        <li class="mr-n1">
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger"
                              data-toggle="dropdown"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div class="dropdown-menu dropdown-menu-right">
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      getItemConfigurations(product, i)
                                    "
                                    ><em class="icon ni ni-setting"></em
                                    ><span>Configure Item</span></a
                                  >
                                </li>
                                <li>
                                  <router-link
                                    :to="{
                                      name: 'BranchItemsCreator',
                                      params: { reference: product.code },
                                    }"
                                    ><em class="icon ni ni-edit"></em
                                    ><span>Edit Item</span></router-link
                                  >
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      handleDeleteAlertModal({
                                        tableName: 'items',
                                        entityIdentifer: 'item',
                                        entity: `${product.name || ''}`,
                                        url: `stock/products/delete/${product.id}`,
                                        callback: () => {
                                          rows.splice(i, 1);
                                        },
                                      })
                                    "
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Remove Item</span></a
                                  >
                                </li>
                                <li>
                                  <a href="javascript:void(0)"
                                    ><em class="icon ni ni-activity-round"></em
                                    ><span>Item Sales</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row g-gs" v-else>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="example-alert">
                          <div class="alert alert-fill alert-danger alert-icon">
                            <em class="icon ni ni-cross-circle"></em>
                            <strong>No Records found</strong>! Please check your
                            search keywords or add new data.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bootstrap-modal
      v-if="modalOpen"
      @close="toggleModal"
      style="display: block"
      :modalSize="'modal-lg'"
      :showFooter="false"
    >
      <span slot="head" style="padding-top: 10px">
        Item configurations <br />
        <small
          ><span class="text-primary text-capitalize">{{
            selectedProduct.name
          }}</span></small
        >
        &nbsp;
        <small
          ><i>Sales Price:</i>&nbsp;<span
            class="text-primary text-capitalize"
            >{{
              `${appSettings.currency} ${addComma(selectedProduct.price)}` ||
              "N/A"
            }}</span
          ></small
        >
      </span>
      <div slot="body">
        <div class="nk-block-head nk-block-head-sm">
          <div class="nk-block-between">
            <div class="nk-block-head-content">
              <h6 class="nk-block-title mb-0">Ingredients</h6>
              <div class="nk-block-des text-soft">
                <p>Add ingredients of the item</p>
              </div>
            </div>
            <div class="nk-block-head-content">
              <div class="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#"
                  class="btn btn-icon btn-trigger toggle-expand me-n1"
                  data-target="pageMenu"
                  ><em class="icon ni ni-menu-alt-r"></em
                ></a>
                <div class="toggle-expand-content" data-content="pageMenu">
                  <ul class="nk-block-tools g-3">
                    <li>
                      <type-head
                        url="branches/ingredients"
                        label="name"
                        tracker="name"
                        placeholder="Search for a ingredent"
                        @changed="selectedIngredient"
                      ></type-head>
                      <multiselect
                        v-model="newItem.product"
                        :options="ingredients"
                        placeholder="Select ingredient"
                        label="name"
                        track-by="name"
                        v-if="false"
                      ></multiselect>
                    </li>
                    <li style="width: 120px">
                      <input
                        type="number"
                        v-model="newItem.quantity"
                        class="form-control form-control-lg"
                        placeholder="Quantity"
                      />
                    </li>
                    <li class="nk-block-tools-opt">
                      <div class="drodown">
                        <button
                          type="button"
                          class="dropdown-toggle btn btn-icon btn-primary"
                          @click.prevent="addIngredient"
                          :disabled="
                            !newItem.quantity || empty(newItem.product)
                          "
                        >
                          <em class="icon ni ni-plus"></em>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <div class="card card-full">
            <div class="nk-tb-list mt-n2">
              <div class="nk-tb-item nk-tb-head">
                <div class="nk-tb-col"><span>#</span></div>
                <div class="nk-tb-col"><span>Ingredient</span></div>
                <div class="nk-tb-col"><span>Cost</span></div>
                <div class="nk-tb-col"><span>Quantity</span></div>
                <div class="nk-tb-col"><span>Amount</span></div>
                <div class="nk-tb-col nk-tb-col-tools"></div>
              </div>
              <div
                class="nk-tb-item"
                v-for="(item, i) in configurations"
                :key="'item' + i"
              >
                <div class="nk-tb-col">
                  <span class="tb-lead"
                    ><a href="javascript:void(0)">#{{ i + 1 }}</a></span
                  >
                </div>
                <div class="nk-tb-col">
                  <div class="user-card">
                    <div class="user-name">
                      <span class="tb-lead">{{ item.name }}</span>
                    </div>
                  </div>
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub tb-amount"
                    ><span>{{ appSettings.currency }} </span
                    >{{ itemCost(item) }}</span
                  >
                </div>
                <div class="nk-tb-col">
                  <span>{{ item.quantity }}</span>
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub tb-amount"
                    ><span>{{ appSettings.currency }} </span
                    >{{ itemTotalAmount(item) }}</span
                  >
                </div>
                <div class="nk-tb-col nk-tb-col-tools">
                  <a
                    href="javascript:void(0)"
                    class="text-danger"
                    @click.prevent="handleIngredientDeletion(item, i)"
                    ><em class="icon ni ni-trash text-danger"></em><span></span
                  ></a>
                </div>
              </div>
            </div>
            <template v-if="configurations.length">
              <hr class="preview-hr my-2" />
              <div class="w-100 d-flex align-items-center">
                <h5 class="mb-0">Total Amount</h5>
                <h6 class="mb-0 ml-auto">
                  {{ appSettings.currency }} {{ addComma(totalAmount) }}
                  <em>({{ percentage.toFixed(2) }}%)</em>
                </h6>
              </div>
              <hr class="preview-hr my-2" />
              <div class="text-right w-100">
                <wolf-button
                  classes="btn btn-primary ml-2"
                  @btn-role="handleSubmit"
                  :disabler="hasNoChanges"
                  activator="saving_records"
                >
                  <span slot="caption"
                    ><em class="icon ni ni-save"></em
                    ><span>Save Changes</span></span
                  >
                </wolf-button>
              </div>
            </template>
          </div>
        </div>
      </div>
    </bootstrap-modal>
  </div>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
import Multiselect from "vue-multiselect";
import TypeHead from "@/components/TypeHead.vue";
export default {
  name: "SalesItems",
  mixins: [dashboardServices],
  components: { Multiselect, TypeHead },
  data: () => ({
    fetchUrl: "branches/items",
    configurations: [],
    newItem: {
      product: null,
      quantity: 1,
    },
    ingredients: [],
    selectedItemIds: [],
    currentIngredients: [],
    selectedProduct: null,
    configuredIndex: null,
  }),
  computed: {
    totalAmount() {
      return this.configurations.reduce(
        (prev, cur) =>
          cur.tariff != null
            ? prev + cur.quantity * cur.tariff
            : prev + Number(cur.total_amount),
        0
      );
    },
    hasNoChanges() {
      return this.configurations.length == this.currentIngredients.length;
    },
    percentage() {
      let salesPrice = this.selectedProduct.price || 0;
      if (!salesPrice) return 0;
      return (this.totalAmount * 100) / salesPrice;
    },
  },
  methods: {
    itemCost(item) {
      if (item.tariff) return this.addComma(item.tariff);
      else return this.addComma(item.cost);
    },
    itemTotalAmount(item) {
      if (item.tariff) return this.addComma(item.quantity * item.tariff);
      else return this.addComma(item.total_amount);
    },
    calculateItemPercentage(item) {
      let costPrice = item.cost_price || 0;
      let salesPrice = item.price || 0;
      if (!salesPrice) return 0;
      return (costPrice * 100) / salesPrice;
    },
    handleIngredientDeletion(item, key) {
      this.$store.commit("SET_REQUEST_FLAG", "MODAL_REQUEST");
      this.$store
        .dispatch(
          "getRequest",
          `production/item/ingredients/delete/${this.selectedProduct.id}/${item.ingredient}`
        )
        .then((response) => {
          this.removeIngredient(key);
          if (response.data.deleted) {
            this.currentIngredients.splice(key, 1);
          }
        });
    },
    handleSubmit() {
      this.$store
        .dispatch("postRequest", {
          url: "branches/item/configure",
          formData: this.formData({
            product_id: this.selectedProduct.id,
            configurations: this.configurations,
          }),
        })
        .then((response) => {
          if (response.data.status) {
            this.toggleModal();
            this.rows[this.configuredIndex] = response.data.product;
            this.configurations = [];
            this.currentIngredients = [];
            this.selectedProduct = null;
            this.configuredIndex = null;
          }
        })
        .catch((err) => console.log(err));
    },
    getItemConfigurations(product, index) {
      this.configurations = [];
      this.currentIngredients = [];
      this.selectedItemIds = [];
      this.selectedProduct = product;
      this.configuredIndex = index;
      this.$store
        .dispatch("getRequest", `production/item/configurations/${product.id}`)
        .then((response) => {
          this.configurations = response.data.rows;
          this.currentIngredients = [...response.data.rows];
          this.currentIngredients.forEach((item) => {
            this.selectedItemIds.push(item.ingredient);
          });
          this.toggleModal();
        });
    },
    selectedIngredient(ingredent) {
      this.newItem.product = ingredent;
    },
    addIngredient() {
      if (this.empty(this.newItem.product)) {
        this.$notifier({
          type: "danger",
          message: "Please select Ingredient",
        });
      } else {
        let product = this.newItem.product;
        this.handleSelectedIngredient({
          ingredient: product.id,
          name: product.name,
          code: product.code,
          quantity: this.newItem.quantity,
          cost: product.cost,
          total_amount: product.cost * this.newItem.quantity,
        });
      }
    },
    removeIngredient(key) {
      this.configurations.splice(key, 1);
      this.selectedItemIds.splice(key, 1);
    },
    handleSelectedIngredient(item) {
      if (!this.selectedItemIds.includes(item.ingredient)) {
        this.configurations.push(item);
        this.selectedItemIds.push(item.ingredient);
        this.clearObject(this.newItem);
      } else {
        this.$notifier({
          type: "info",
          message: "Ingredient already added to the list",
        });
      }
    },
  },
  mounted() {
    /* this.$store
      .dispatch("getRequest", `branches/ingredients`)
      .then((response) => {
        this.ingredients = response.data.rows || [];
      }); */
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
