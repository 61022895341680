<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="components-preview wide-md mx-auto">
          <div class="nk-block-head nk-block-head-lg wide-sm">
            <div class="nk-block-head-content">
              <div class="nk-block-head-sub">
                <a class="back-to" href="javascript:void(0)"
                  ><em class="icon ni ni-arrow-left"></em
                  ><span>Settings</span></a
                >
              </div>
              <h5 class="nk-block-title mb-0">General Settings</h5>
            </div>
          </div>
          <div class="nk-block nk-block-lg">
            <div class="card">
              <div class="card-inner">
                <form
                  class="
                    nk-wizard nk-wizard-simple
                    is-vertical is-alter
                    wizard
                    clearfix
                  "
                >
                  <div class="content clearfix">
                    <div class="nk-wizard-content body current">
                      <div class="row gy-3">
                        <div class="col-lg-4 col-md-6">
                          <div class="form-group">
                            <label class="form-label" for="fw-vr-first-name"
                              >Base Color</label
                            >
                            <div class="d-flex align-items-center no-gutters">
                              <div class="col-10">
                                <div class="form-control-wrap">
                                  <input
                                    type="text"
                                    class="
                                      form-control
                                      required
                                      no-right-radius
                                    "
                                    name="Base Color"
                                    :class="{
                                      'is-invalid': errors.has('Base Color'),
                                    }"
                                    v-validate="'required'"
                                    v-model="newSettings.base_color"
                                    placeholder="Eg: #1dbe72"
                                  />
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-control-wrap">
                                  <input
                                    type="color"
                                    class="form-control no-left-radius"
                                    v-model="newSettings.base_color"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                          <div class="form-group">
                            <label class="form-label" for="fw-vr-last-name"
                              >Second Color</label
                            >
                            <div class="d-flex align-items-center no-gutters">
                              <div class="col-10">
                                <div class="form-control-wrap">
                                  <input
                                    type="text"
                                    class="
                                      form-control
                                      required
                                      no-right-radius
                                    "
                                    placeholder="Eg: #364a63"
                                    v-model="newSettings.secondary_color"
                                    name="Second Color"
                                    :class="{
                                      'is-invalid': errors.has('Second Color'),
                                    }"
                                    v-validate="'required'"
                                  />
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="form-control-wrap">
                                  <input
                                    type="color"
                                    class="form-control no-left-radius"
                                    v-model="newSettings.secondary_color"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                          <div class="form-group">
                            <label for="">App Name</label>
                            <div class="form-control-wrap">
                              <input
                                type="text"
                                class="form-control"
                                v-model="newSettings.site_name"
                                placeholder="Eg: Stockify"
                                name="App Name"
                                :class="{
                                  'is-invalid': errors.has('App Name'),
                                }"
                                v-validate="'required'"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                          <div class="form-group">
                            <label for="">TIN number</label>
                            <div class="form-control-wrap">
                              <input
                                type="number"
                                class="form-control"
                                v-model="newSettings.app_tin"
                                placeholder="TIN Number"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                          <div class="form-group">
                            <label for="">Email</label>
                            <div class="form-control-wrap">
                              <input
                                type="email"
                                class="form-control"
                                v-model="newSettings.app_email"
                                placeholder="Eg: john@company.com"
                                name="Email"
                                :class="{
                                  'is-invalid': errors.has('Email'),
                                }"
                                v-validate="'required'"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                          <div class="form-group">
                            <label for="">Telephone</label>
                            <div class="form-control-wrap">
                              <input
                                type="tel"
                                class="form-control no-left-radius"
                                v-model="newSettings.app_phone"
                                placeholder="Eg: Phone number"
                                name="Phone Number"
                                :class="{
                                  'is-invalid': errors.has('Phone Number'),
                                }"
                                v-validate="'required'"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                          <div class="form-group">
                            <label for="">Address Line</label>
                            <div class="form-control-wrap">
                              <input
                                type="text"
                                class="form-control no-left-radius"
                                v-model="newSettings.site_address"
                                placeholder="KN 145 ST 76, Nyarugenge, Kigali, Rwanda"
                                name="Address"
                                :class="{
                                  'is-invalid': errors.has('Address'),
                                }"
                                v-validate="'required'"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-2 col-sm-3">
                          <input-file
                            :label="'Logo(<em>250px x 64x</em>)'"
                            :current-file="newSettings.site_logo"
                            @uploaded="handleUploadedFile($event, 'site_logo')"
                          ></input-file>
                        </div>
                        <div class="col-lg-2 col-sm-3">
                          <input-file
                            :label="'Favicon'"
                            :current-file="newSettings.favicon"
                            @uploaded="handleUploadedFile($event, 'favicon')"
                          ></input-file>
                        </div>
                        <div class="col-lg-4 col-md-6">
                          <div class="form-group">
                            <label for="">Currency</label>
                            <div class="form-control-wrap">
                              <select
                                type="text"
                                class="form-control no-left-radius"
                                v-model="newSettings.currency"
                                name="Currency"
                                :class="{
                                  'is-invalid': errors.has('Currency'),
                                }"
                                v-validate="'required'"
                              >
                                <option value="null" disabled hidden>
                                  Select Currency
                                </option>
                                <option value="RWF">RWF</option>
                                <option value="$">USD</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="actions clearfix float-right">
                    <wolf-button
                      classes="btn btn-primary"
                      @btn-role="handleSubmit"
                      :disabler="submitDisabled"
                      activator="saving_records"
                    >
                      <span slot="caption"
                        ><em class="icon ni ni-save"></em
                        ><span>Save Changes</span></span
                      >
                    </wolf-button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputFile from "./InputFile.vue";
export default {
  components: { InputFile },
  name: "GeneralSettings",
  data: () => ({
    newSettings: {
      base_color: null,
      secondary_color: null,
      site_logo: null,
      favicon: null,
      site_address: null,
      site_name: null,
      currency: null,
      contact_one: null,
      app_phone: null,
      app_email: null,
      app_tin: null,
    },
    imageKey: null,
  }),
  computed: {
    submitDisabled() {
      return !this.newSettings.site_logo || !this.newSettings.favicon;
    },
  },
  created() {
    this.$store
      .dispatch("postRequest", {
        url: "frontend/preloaders",
        formData: this.formData({ keys: Object.keys(this.newSettings) }),
      })
      .then((response) => {
        let result = response.data.result;
        this.newSettings = { ...this.newSettings, ...result };
      });
  },
  methods: {
    handleUploadedFile(filePath, key) {
      this.newSettings[key] = filePath;
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          var items = [];
          let obj = this.newSettings;
          for (let key in obj) {
            if (obj[key] !== null) {
              if (typeof obj[key] === "object")
                items.push({ key, value: JSON.stringify(obj[key]) });
              else items.push({ key, value: obj[key] });
            }
          }
          this.$store.dispatch("postRequest", {
            url: "frontend/preloaders/update",
            formData: this.formData({ items }),
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.no-right-radius {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.no-left-radius {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
