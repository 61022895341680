<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch p-4">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h5 class="nk-block-title page-title">Genera Reports</h5>
                    <div class="nk-block-des text-soft">
                      <!-- <p>You have total {{ totalRows }} requests.</p> -->
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="javascript:void(0)"
                        class="btn btn-icon btn-trigger toggle-expand mr-n1"
                        ><em class="icon ni ni-more-v"></em
                      ></a>
                      <div class="toggle-expand-content">
                        <ul class="nk-block-tools g-3">
                          <li></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="reports">
                  <div class="row g-gs">
                    <template v-if="empty(loggedUser.branch)">
                      <div class="col-lg-4">
                        <div class="report-section">
                          <h4>
                            <svg
                              data-name="Layer 1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              class="icon lpanel"
                            >
                              <path d="M0 0h512v512H0z" fill="none"></path>
                              <path
                                d="M479.24 371L425.4 117.71a31.87 31.87 0 00-37.84-24.56l-262.89 56.06-15.84-74.52a16 16 0 00-6.91-10.07L56.81 35.19A16 16 0 1039.32 62L78.9 87.81l53.75 252.84A72.7 72.7 0 10164 334l-32.68-153.49 262.8-56 53.79 253.06L314.14 406a16 16 0 106.65 31.3l133.88-28.5a31.88 31.88 0 0024.57-37.8zm-324.66-4.09a40.64 40.64 0 0133.9 8.06 40.68 40.68 0 11-33.9-8.06z"
                              ></path>
                              <path
                                d="M250.6 248a16 16 0 01-3.31-31.66L304 204.32a16 16 0 016.65 31.3l-56.72 12a16 16 0 01-3.33.38z"
                              ></path>
                            </svg>
                            Store Reports
                          </h4>
                          <div class="nav flex-column">
                            <div
                              class="nav-link"
                              v-for="(item, i) in storeReports"
                              :key="'store_report' + i"
                            >
                              <router-link
                                class="ember-view"
                                :to="{ name: item.route }"
                                ><div class="ember-view">
                                  <span class="text-nowrap">
                                    <span
                                      ><svg
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0"
                                        y="0"
                                        viewBox="0 0 512 512"
                                        xml:space="preserve"
                                        class="
                                          icon icon-sm
                                          align-text-top
                                          fill-light-silver
                                        "
                                      >
                                        <path
                                          d="M512 195.6l-171.6-34.7L256 0l-84.4 160.9L0 195.6l119.5 134.1L97.8 512 256 434l158.2 78-21.7-182.3L512 195.6zM256.3 381.4l-102.8 50.7 14.1-118.5L90 226.4l111.5-22.5 54.8-104.6L311.2 204l111.5 22.5-77.6 87.2 14.1 118.5-102.9-50.8z"
                                        ></path></svg
                                    ></span>
                                    <span class="pl-2">{{ item.name }}</span>
                                  </span>
                                </div></router-link
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="report-section">
                          <h4>
                            <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0"
                              y="0"
                              viewBox="0 0 512 512"
                              xml:space="preserve"
                              class="icon lpanel"
                            >
                              <path
                                d="M255.8 512c-7.8 0-15.6-.4-23.3-1.1-8.8-.8-15.1-7.4-15.1-15.7 0-8.4 7-15.7 15-15.7.4 0 .8 0 1.2.1 7.1.7 14.3 1 21.5 1 43.8 0 86.1-12.3 122.3-35.5 33.6-21.5 60.9-51.7 78.9-87.3 19.1-37.5 27.1-80 23.2-122.9-3.9-43.2-19.7-83.4-45.6-116.4C391 63.9 324.3 31.3 255.4 31.3c-14.9 0-29.7 1.5-44.1 4.6C167.8 45 128.8 65.8 98.4 96c-29.8 29.6-50.7 66.4-60.5 106.7-10.1 41.6-8.3 84.9 5.4 125.2 13.1 38.6 37.6 74.4 68.9 100.6l1.6 1.3.5-62.6c.1-8.7 6.9-15.3 15.8-15.3 4.4 0 8.5 1.7 11.4 4.6 2.8 2.8 4.3 6.6 4.2 10.9l-.8 95.1c-.1 8.6-7.1 15.6-15.6 15.6l-93.3-.8c-8.8-.1-15.4-6.9-15.3-15.8 0-8.9 6.6-15.5 15.3-15.5l48.9.4-1.9-1.7c-3.6-3.2-7-6.6-10.2-9.9-33.8-34.9-56.9-78.9-67-127.2-10.1-48.7-6.2-98.2 11.5-143.2C34.4 120.8 61.8 83.3 96.6 56c38-29.8 83-48.6 130.2-54.1 9.6-1.1 19.3-1.7 29-1.7 40.2 0 80.6 9.8 117 28.4 36.5 18.7 68.2 45.9 91.7 78.7 26.9 37.5 43 82.1 46.6 129 3.6 46.8-5.4 93.4-26 134.6-20.6 41.1-51.8 75.9-90.1 100.5-41.8 26.5-89.8 40.6-139.2 40.6zm98-191.5c-2.5 0-4.9-.6-7.2-1.8L248 269.6c-5.2-2.4-8.5-7.3-8.5-13.1V108.4c0-8.4 7.1-14.7 16-14.7s16 6.3 16 14.7v137.7l90.4 45.2c6.3 3.2 9.3 9.6 7.8 16.3-1.8 7.5-8.5 12.9-15.9 12.9z"
                              ></path>
                            </svg>
                            Production Reports
                          </h4>
                          <div class="nav flex-column">
                            <div
                              class="nav-link"
                              v-for="(item, i) in production"
                              :key="'production_report' + i"
                            >
                              <router-link
                                class="ember-view"
                                :to="{ name: item.route }"
                                ><div class="ember-view">
                                  <span class="text-nowrap">
                                    <span
                                      ><svg
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0"
                                        y="0"
                                        viewBox="0 0 512 512"
                                        xml:space="preserve"
                                        class="
                                          icon icon-sm
                                          align-text-top
                                          fill-light-silver
                                        "
                                      >
                                        <path
                                          d="M512 195.6l-171.6-34.7L256 0l-84.4 160.9L0 195.6l119.5 134.1L97.8 512 256 434l158.2 78-21.7-182.3L512 195.6zM256.3 381.4l-102.8 50.7 14.1-118.5L90 226.4l111.5-22.5 54.8-104.6L311.2 204l111.5 22.5-77.6 87.2 14.1 118.5-102.9-50.8z"
                                        ></path></svg
                                    ></span>
                                    <span class="pl-2">{{ item.name }}</span>
                                  </span>
                                </div></router-link
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="report-section">
                          <h4>
                            <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0"
                              y="0"
                              viewBox="0 0 512 512"
                              xml:space="preserve"
                              class="icon lpanel"
                            >
                              <path
                                d="M320 192h32v32h-32zM160 192h32v32h-32z"
                              ></path>
                              <path
                                d="M416 128h-62.4V96.6c0-30.6-8.6-54.8-25.6-71.9-17.1-17.1-41.1-25.8-71.6-25.8s-54.6 8.7-71.6 25.8-25.6 41.3-25.6 71.9V128H96c-17.7 0-32 14.3-32 32v320c0 17.7 14.3 32 32 32h320c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32zM191.2 96.6c0-21.8 5.5-38.4 16.3-49.3s27.3-16.4 48.9-16.4c21.7 0 38.1 5.5 48.9 16.4s16.3 27.5 16.3 49.3V128H191.2V96.6zM416 480H96V160h320v320z"
                              ></path>
                            </svg>
                            Suppliers Reports
                          </h4>
                          <div class="nav flex-column">
                            <div
                              class="nav-link"
                              v-for="(item, i) in supplierReports"
                              :key="'suppliers_report' + i"
                            >
                              <router-link
                                class="ember-view"
                                :to="{ name: item.route }"
                                ><div class="ember-view">
                                  <span class="text-nowrap">
                                    <span
                                      ><svg
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0"
                                        y="0"
                                        viewBox="0 0 512 512"
                                        xml:space="preserve"
                                        class="
                                          icon icon-sm
                                          align-text-top
                                          fill-light-silver
                                        "
                                      >
                                        <path
                                          d="M512 195.6l-171.6-34.7L256 0l-84.4 160.9L0 195.6l119.5 134.1L97.8 512 256 434l158.2 78-21.7-182.3L512 195.6zM256.3 381.4l-102.8 50.7 14.1-118.5L90 226.4l111.5-22.5 54.8-104.6L311.2 204l111.5 22.5-77.6 87.2 14.1 118.5-102.9-50.8z"
                                        ></path></svg
                                    ></span>
                                    <span class="pl-2">{{ item.name }}</span>
                                  </span>
                                </div></router-link
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <div class="col-lg-4">
                      <div class="report-section">
                        <h4>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="icon lpanel"
                          >
                            <path
                              d="M284.5 479.9h-28.3v-96.2c0-8.8-7.2-16-16-16H128.1c-4.2 0-8.3 1.7-11.3 4.7s-4.7 7.1-4.7 11.3v96.2H87.9c-13.2 0-24-10.8-24-24V50.1C63.9 40.3 72 32 81.6 32h202.2c9.6 0 17.7 8.3 17.7 18.1V112c0 8.8 7.2 16 16 16s16-7.2 16-16V50.1c0-27.6-22.3-50.1-49.7-50.1H81.7C54.2 0 31.9 22.5 31.9 50.1v405.8c0 30.9 25.1 56 56 56h196.6c8.8 0 16-7.2 16-16s-7.2-16-16-16zM144 399.7h80.1v80.1H144v-80.1z"
                            ></path>
                            <path
                              d="M256 128c0-8.8-7.2-16-16-16H112c-8.8 0-16 7.2-16 16s7.2 16 16 16h128c8.8 0 16-7.1 16-16zm-144 96c-8.8 0-16 7.2-16 16s7.2 16 16 16h79.9c8.8 0 16-7.2 16-16s-7.2-16-16-16H112zm354.9-42.2c-26.8-21-58.5-24.1-74.9-24.1s-48.1 3.1-74.9 24.1c-27.8 21.8-41.9 55.4-41.9 99.9 0 93.3 100.1 218 104.4 223.2 3 3.8 7.6 5.9 12.4 5.9s9.4-2.2 12.4-5.9c4.3-5.3 104.4-130 104.4-223.2 0-44.6-14.1-78.1-41.9-99.9zM392 468.4c-26.2-35.8-84.8-122.8-84.8-186.7 0-34.2 10-59.3 29.6-74.7 19.3-15.1 42.9-17.3 55.2-17.3s36 2.3 55.2 17.3c19.6 15.4 29.6 40.5 29.6 74.7 0 63.9-58.6 150.9-84.8 186.7z"
                            ></path>
                            <circle cx="392" cy="271.5" r="37.8"></circle>
                          </svg>
                          Branches Reports
                        </h4>
                        <div class="nav flex-column">
                          <div
                            class="nav-link"
                            v-for="(item, i) in branchReports"
                            :key="'branches_report' + i"
                            :class="{
                              'd-none':
                                empty(loggedUser.branch) &&
                                !empty(item.for_branch),
                            }"
                          >
                            <router-link
                              class="ember-view"
                              :to="{ name: item.route }"
                              ><div class="ember-view">
                                <span class="text-nowrap">
                                  <span
                                    ><svg
                                      version="1.1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      x="0"
                                      y="0"
                                      viewBox="0 0 512 512"
                                      xml:space="preserve"
                                      class="
                                        icon icon-sm
                                        align-text-top
                                        fill-light-silver
                                      "
                                    >
                                      <path
                                        d="M512 195.6l-171.6-34.7L256 0l-84.4 160.9L0 195.6l119.5 134.1L97.8 512 256 434l158.2 78-21.7-182.3L512 195.6zM256.3 381.4l-102.8 50.7 14.1-118.5L90 226.4l111.5-22.5 54.8-104.6L311.2 204l111.5 22.5-77.6 87.2 14.1 118.5-102.9-50.8z"
                                      ></path></svg
                                  ></span>
                                  <span class="pl-2">{{ item.name }}</span>
                                </span>
                              </div></router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "ReportsIndex",
  data: () => ({
    storeReports: [
      { name: "Store Status", route: "StoreStatus" },
      { name: "Receive Report", route: "ReceiveReport" },
      { name: "Transfer Report", route: "TransferReport" },
      { name: "Purchases By Items", route: "ItemPurchaseReport" },
      { name: "Transfer By Items", route: "ItemTransferReport" },
      { name: "Purchase Orders", route: "OrdersReports" },
      { name: "Spoiled Items Reports", route: "SpoiledItemsReport" },
    ],
    production: [
      { name: "Store Status", route: "ProductionStoreStatus" },
      { name: "Stock Receives", route: "" },
      { name: "Production Report", route: "" },
      { name: "Sales Report", route: "ProductionSalesReport" },
      { name: "Customers Balance", route: "" },
      { name: "Sales By Items", route: "" },
      { name: "Payment Reports", route: "" },
    ],
    supplierReports: [
      { name: "Supplier Balance", route: "SupplierPayments" },
      { name: "Payment Report", route: "" },
    ],
    branchReports: [
      { name: "Recipes Costing Report", route: "RecipesCosting" },
      {
        name: "Receiving Report",
        route: "BranchReceingReport",
        for_branch: true,
      },
      {
        name: "Store Status Report",
        route: "BranchStoreStatus",
        for_branch: true,
      },
    ],
  }),
};
</script>
<style scoped>
.report-section {
  margin-bottom: 30px;
  max-width: 250px;
}
.flex-column {
  flex-direction: column !important;
}
.nav {
  display: flex;
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
  flex-wrap: wrap;
  margin: 0 !important;
}
.report-section .nav .nav-link {
  padding: 10px 0px;
  border-bottom: 1px dashed rgb(233, 233, 233);
}
.nav .nav-link {
  position: relative;
}
svg.icon {
  width: 16px;
  height: 16px;
  fill: currentColor;
}
svg.icon.fill-light-silver {
  fill: #cbcbcb;
}
svg.icon.icon-sm {
  height: 14px;
  width: 14px;
}
h4 {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.1;
}
</style>
