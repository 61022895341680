<template>
  <div class="row g-gs">
    <div class="col-xxl-3 col-sm-6">
      <div class="card card-bordered">
        <div class="nk-ecwg nk-ecwg6">
          <div class="card-inner">
            <div class="card-title-group">
              <div class="card-title">
                <h6 class="title">Receiving Chart</h6>
              </div>
            </div>
            <div class="data">
              <div class="data-group">
                <div class="amount">
                  {{ `${appSettings.currency} ${addComma(receives.total)}` }}
                </div>
                <div class="nk-ecwg6-ck dash-cards">
                  <line-chart
                    ref="receivesChart"
                    :chart-data="receivesData"
                    :options="option"
                  ></line-chart>
                </div>
              </div>
              <div class="info d-none">
                <span class="change up text-danger"
                  ><em class="icon ni ni-arrow-long-up"></em>4.63%</span
                ><span> vs. last week</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-3 col-sm-6">
      <div class="card card-bordered">
        <div class="nk-ecwg nk-ecwg6">
          <div class="card-inner">
            <div class="card-title-group">
              <div class="card-title">
                <h6 class="title">Transfers Chart</h6>
              </div>
            </div>
            <div class="data">
              <div class="data-group">
                <div class="amount">
                  {{
                    `${appSettings.currency} ${addComma(transfers.total.total)}`
                  }}
                </div>
                <div class="nk-ecwg6-ck dash-cards">
                  <line-chart
                    ref="transfersChart"
                    :chart-data="transfersData"
                    :options="option"
                  ></line-chart>
                </div>
              </div>
              <div class="info d-none">
                <span class="change down text-danger"
                  ><em class="icon ni ni-arrow-long-down"></em>2.34%</span
                ><span> vs. last week</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-3 col-sm-6">
      <div class="card card-bordered">
        <div class="nk-ecwg nk-ecwg6">
          <div class="card-inner">
            <div class="card-title-group">
              <div class="card-title">
                <h6 class="title">Purchase Orders</h6>
              </div>
            </div>
            <div class="data">
              <div class="data-group">
                <div class="amount">
                  {{ `${appSettings.currency} ${addComma(orders.total)}` }}
                </div>
                <div class="nk-ecwg6-ck dash-cards">
                  <line-chart
                    ref="transfersChart"
                    :chart-data="ordersData"
                    :options="option"
                  ></line-chart>
                </div>
              </div>
              <div class="info d-none">
                <span class="change up text-danger"
                  ><em class="icon ni ni-arrow-long-up"></em>4.63%</span
                ><span> vs. last week</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-3 col-sm-6">
      <div class="card card-bordered">
        <div class="nk-ecwg nk-ecwg6">
          <div class="card-inner">
            <div class="card-title-group">
              <div class="card-title">
                <h6 class="title">Spoiled Items</h6>
              </div>
            </div>
            <div class="data">
              <div class="data-group">
                <div class="amount">
                  {{ `${appSettings.currency} ${addComma(spoiled.total)}` }}
                </div>
                <div class="nk-ecwg6-ck dash-cards">
                  <line-chart
                    ref="transfersChart"
                    :chart-data="spoiledData"
                    :options="option"
                  ></line-chart>
                </div>
              </div>
              <div class="info d-none">
                <span class="change down text-danger"
                  ><em class="icon ni ni-arrow-long-down"></em>2.34%</span
                ><span> vs. last week</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LineChart from "@/components/LineChart";
export default {
  name: "InventoryCards",
  props: ["receives", "transfers", "orders", "spoiled"],
  components: { LineChart },
  data: () => ({
    option: {
      legend: {
        display: false,
        rtl: false,
        labels: { boxWidth: 12, padding: 20, fontColor: "#6783b8" },
      },
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: true,
        rtl: false,
        callbacks: {
          title: function () {
            return false;
          },
          label: function (a, t) {
            return `$ ${t.datasets[a.datasetIndex].data[a.index]}`;
          },
        },
        backgroundColor: "#1c2b46",
        titleFontSize: 8,
        titleFontColor: "#fff",
        titleMarginBottom: 4,
        bodyFontColor: "#fff",
        bodyFontSize: 8,
        bodySpacing: 4,
        yPadding: 6,
        xPadding: 6,
        footerMarginTop: 0,
        displayColors: false,
      },
      scales: {
        yAxes: [
          {
            display: false,
            ticks: {
              beginAtZero: false,
              fontSize: 12,
              fontColor: "#9eaecf",
              padding: 0,
            },
            gridLines: {
              color: "#526484",
              tickMarkLength: 0,
              zeroLineColor: "#526484",
            },
          },
        ],
        xAxes: [
          {
            display: false,
            ticks: {
              fontSize: 12,
              fontColor: "#9eaecf",
              source: "auto",
              padding: 0,
              reverse: false,
            },
            gridLines: {
              color: "transparent",
              tickMarkLength: 0,
              zeroLineColor: "#526484",
              offsetGridLines: true,
            },
          },
        ],
      },
    },
  }),
  computed: {
    receivesData() {
      return {
        labels: this.receives.labels,
        dataUnit: this.appSettings.currency,
        lineTension: 0.3,
        datasets: [
          {
            label: "Receives",
            borderColor: "#854fff",
            backgroundColor: "transparent",
            data: this.receives.data,
          },
        ],
      };
    },
    transfersData() {
      return {
        labels: this.transfers.labels,
        dataUnit: this.appSettings.currency,
        lineTension: 0.3,
        datasets: [
          {
            label: "Transfers",
            borderColor: "#33d895",
            backgroundColor: "transparent",
            data: this.transfers.data,
          },
        ],
      };
    },
    ordersData() {
      return {
        labels: this.orders.labels,
        dataUnit: this.appSettings.currency,
        lineTension: 0.3,
        datasets: [
          {
            label: "Purchase Orders",
            borderColor: "#ff63a5",
            backgroundColor: "transparent",
            data: this.orders.data,
          },
        ],
      };
    },
    spoiledData() {
      return {
        labels: this.spoiled.labels,
        dataUnit: this.appSettings.currency,
        lineTension: 0.3,
        datasets: [
          {
            label: "Spoiled",
            borderColor: "#559bfb",
            backgroundColor: "transparent",
            data: this.spoiled.data,
          },
        ],
      };
    },
  },
};
</script>
<style>
.nk-ecwg6-ck.dash-cards div {
  height: 40px;
}
</style>
