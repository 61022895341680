<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="wide-lg mx-auto">
          <div class="invoice" id="invoice-container" v-if="!empty(record)">
            <div class="invoice-action">
              <a
                class="
                  btn btn-icon btn-lg btn-white btn-dim btn-outline-primary
                "
                href="javascript:void(0)"
                @click.prevent="printElem('invoice-container')"
                ><em class="icon ni ni-printer-fill"></em
              ></a>
            </div>
            <div class="invoice-wrap">
              <div class="invoice-brand text-center">
                <img :src="`${apiURL}/${appSettings.site_logo}`" alt="" />
              </div>
              <div class="invoice-head">
                <div class="invoice-contact">
                  <span class="overline-title">Branch</span>
                  <div class="invoice-contact-info">
                    <h4 class="title">{{ record.branch.name }}</h4>
                    <ul class="list-plain">
                      <li>
                        <em class="icon ni ni-map-pin-fill"></em
                        ><span>{{ record.branch.location }}</span>
                      </li>
                      <li>
                        <em class="icon ni ni-call-fill"></em
                        ><span>{{ record.branch.phone }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="invoice-desc">
                  <h3 class="title">Transfer</h3>
                  <ul class="list-plain">
                    <li class="invoice-id">
                      <span>Voucher No:</span>:<span>{{ record.code }}</span>
                    </li>
                    <li class="invoice-date">
                      <span>Date</span>:<span>{{
                        chatDate(record.delivery_date)
                      }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="invoice-bills">
                <div class="table-responsive">
                  <table class="table table-striped-">
                    <thead>
                      <tr>
                        <th class="w-150px">Item Code</th>
                        <th class="w-200px">Item Type</th>
                        <th class="w-350px">Item Name</th>
                        <th>Price</th>
                        <th>Qty</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in items" :key="'item' + i">
                        <td>#{{ item.product.code }}</td>
                        <td>
                          {{ item.product.type }}
                        </td>
                        <td>{{ item.product.name }}</td>
                        <td>
                          {{ appSettings.currency }}
                          {{ addComma(item.price) }}
                        </td>
                        <td>{{ addComma(item.quantity) }}</td>
                        <td>
                          <span class="tb-sub tb-amount"
                            ><span>{{ appSettings.currency }} </span
                            >{{ addComma(item.quantity * item.price) }}</span
                          >
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="3"></td>
                        <td colspan="2">Subtotal</td>
                        <td>
                          {{ appSettings.currency
                          }}{{ addComma(record.total_amount) }}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3"></td>
                        <td colspan="2">Shipping fee</td>
                        <td>{{ appSettings.currency }}0.00</td>
                      </tr>
                      <tr>
                        <td colspan="3"></td>
                        <td colspan="2">TAX</td>
                        <td>{{ appSettings.currency }}0.00</td>
                      </tr>
                      <tr>
                        <td colspan="3"></td>
                        <td colspan="2">Grand Total</td>
                        <td>
                          {{ appSettings.currency
                          }}{{ addComma(record.total_amount) }}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  <div class="nk-notes ff-italic fs-12px text-soft">
                    <!-- Invoice was created on a computer and is valid without
                        the signature and seal. -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TransferVoucher",
  data: () => ({
    items: [],
    record: {},
  }),
  computed: {},
  beforeCreate() {
    let reference = this.$route.params.reference;
    if (reference) {
      this.$store
        .dispatch("getRequest", `/reports/transfer/${reference}/voucher`)
        .then((response) => {
          this.items = response.data.items;
          this.record = response.data.record;
        });
    }
  },
  methods: {
    printElem(elem) {
      var mywindow = window.open("", "PRINT", "height=1122,width=793");

      mywindow.document.write(
        `<html>
        <head>
          <title>${document.title}</title>
          <style media="print">
            @page {
            size: A4;
            margin: 0;
          }
          html, body {
            width: 210mm;
            height: 297mm;
          }
          </style>
        `
      );
      mywindow.document.write("</head><body >");
      mywindow.document.write("<h1>" + document.title + "</h1>");
      mywindow.document.write(document.getElementById(elem).innerHTML);
      mywindow.document.write("</body></html>");

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();

      return true;
    },
  },
};
</script>
