<template>
  <div class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch p-4">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">Production Status</h3>
                    <div class="nk-block-des text-soft">
                      <!-- <p>You have total {{ totalRows }} products</p> -->
                    </div>
                  </div>
                  <div class="nk-block-head-content w-25">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="javascript:void(0)"
                        class="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                        ><em class="icon ni ni-more-v"></em
                      ></a>
                      <div
                        class="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul class="nk-block-tools g-3">
                          <li class="nk-block-tools-opt" v-if="false">
                            <button
                              type="button"
                              class="btn btn-icon btn-primary d-md-none"
                            >
                              <em class="icon ni ni-plus"></em>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="rows.length > 0">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Produce</span></div>
                    <div class="nk-tb-col"><span>Item Name</span></div>
                    <div class="nk-tb-col"><span>Item Code</span></div>
                    <div class="nk-tb-col"><span>Item Type</span></div>
                    <div class="nk-tb-col"><span>Cost Price</span></div>
                    <div class="nk-tb-col"><span>Sales Price</span></div>
                    <div class="nk-tb-col"><span>Requested</span></div>
                    <div class="nk-tb-col"><span>Produced</span></div>
                    <div class="nk-tb-col"><span>Balance</span></div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>
                  <div
                    class="nk-tb-item"
                    v-for="(item, i) in rows"
                    :key="'production' + i"
                  >
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ i + 1 }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ item.employee }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ item.product.name }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span>#{{ item.product.code }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span>{{ item.product.type }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span>{{ appSettings.currency }} {{ addComma(item.product.cost_price) }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span>{{ `${appSettings.currency} ${addComma(item.product.price)}` }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span>{{ `${addComma(item.requested)}` }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <input
                        type="number"
                        class="form-control"
                        v-model="rows[i].produced"
                        :name="'Produced' + i"
                        @keyup="computeBalance(item)"
                        :class="{ 'is-invalid': errors.has('Produced' + i) }"
                        v-validate="
                          'min_value:0|max_value:' + rows[i].requested
                        "
                      />
                      <span
                        class="text-danger"
                        v-if="errors.has('Produced' + i)"
                        >Must be less or equal to
                        {{ rows[i].requested }}
                      </span>
                    </div>
                    <div class="nk-tb-col">
                      <input
                        type="number"
                        v-model="rows[i].balance"
                        class="form-control"
                        readonly
                      />
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <a
                        href="javascript:void(0)"
                        @click.prevent="removeItem(product)"
                        ><em class="icon ni ni-trash text-danger"></em
                        ><span></span
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="w-100" v-if="rows.length > 0">
                  <div class="float-right">
                    <div
                      class="d-flex justify-content-center align-items-center actions"
                    >
                      <wolf-button
                        classes="btn btn-primary ml-2"
                        @btn-role="handleSubmit"
                        :disabler="submitDisabled"
                        activator="saving_records"
                      >
                        <span slot="caption"
                          ><em class="icon ni ni-check-circle"></em
                          ><span>Receive</span></span
                        >
                      </wolf-button>
                    </div>
                  </div>
                </div>
                <div class="row g-gs" v-else>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="example-alert">
                          <div class="alert alert-pro alert-warning">
                            <div class="alert-text">
                              <h6>No records found</h6>
                              <p>There are not pending productions.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
export default {
  name: "Status",
  mixins: [dashboardServices],
  data: () => ({
    fetchUrl: "production/status",
  }),
  computed: {
    submitDisabled() {
      return !this.rows.length;
    },
  },
  methods: {
    removeItem() {},
    computeBalance(item) {
      item.balance = Number(item.requested) - Number(item.produced);
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store
            .dispatch("postRequest", {
              url: "production/status/update",
              formData: this.formData({
                items: this.rows,
              }),
            })
            .then((response) => {
              if (response.data.status) {
                this.rows = response.data.rows.data;
                this.currentPage = response.data.rows.current_page;
                this.totalRows = response.data.rows.total;
                this.fetchedData = response.data;
              }
            })
            .catch((err) => console.log(err));
        }
      });
    },
  },
};
</script>

