<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch p-4">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h5 class="nk-block-title page-title">
                      Receiving Report<small>
                        <a href="javascript:void(0)">({{ reportLabel }})</a>
                      </small>
                    </h5>
                    <div class="nk-block-des text-soft">
                      <!-- <p>You have total {{ totalRows }} requests.</p> -->
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="javascript:void(0)"
                        class="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="more-options"
                        ><em class="icon ni ni-more-v"></em
                      ></a>
                      <div
                        class="toggle-expand-content"
                        data-content="more-options"
                      >
                        <ul class="nk-block-tools g-3">
                          <li>
                            <multiselect
                              v-model="supplier"
                              :options="suppliers"
                              placeholder="Filter By supplier"
                              label="name"
                              track-by="name"
                              @select="handleSupplierFilter"
                            ></multiselect>
                          </li>
                          <li>
                            <div class="d-flex align-items-center">
                              <span class="mr-2"> From: </span>
                              <input
                                type="date"
                                class="form-control"
                                v-model="newFilter.from"
                              />
                            </div>
                          </li>
                          <li>
                            <div class="d-flex align-items-center">
                              <span class="mr-2"> To: </span>
                              <input
                                type="date"
                                class="form-control"
                                v-model="newFilter.to"
                              />
                            </div>
                          </li>
                          <li class="nk-block-tools-opt">
                            <button
                              type="button"
                              class="btn btn-primary d-none d-md-inline-flex"
                              :disabled="empty(newFilter.from)"
                              @click="handlePaginationFilter"
                            >
                              <em class="icon ni ni-filter"></em
                              ><span>Filter</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="totalRows">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Date</span></div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Reference</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Items</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Amount</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Invoice</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">VAT</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Supplier</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Created By</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1 my-n1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="
                                dropdown-toggle
                                btn btn-icon btn-trigger
                                mr-n1
                              "
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <a href="javascript:void(0)"
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Remove Seleted</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(row, i) in rows"
                    :key="'row' + i"
                  >
                    <div class="nk-tb-col">
                      <span>{{ i + 1 }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="title">{{
                        chatDate(row.received_date)
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">#{{ row.code }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span>{{ addComma(row.items) }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount"
                        >{{ appSettings.currency }}
                        {{ addComma(row.total_amount) }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span
                        class="badge badge-dot bg-success"
                        v-if="row.has_invoice"
                        >Available</span
                      >
                      <span class="badge badge-dot bg-warning" v-else
                        >No Invoice</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span
                        class="
                          badge badge-sm badge-dim
                          bg-outline-success
                          d-none d-md-inline-flex
                        "
                        :title="row.vat"
                        >{{ row.vat }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead" v-if="!empty(row.vendor)">{{
                        row.vendor.name
                      }}</span>
                      <span v-else>N/A</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead" v-if="!empty(row.creator)">{{
                        row.creator.name
                      }}</span>
                      <span v-else>N/A</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger"
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      getItemVoucher(row.reference)
                                    "
                                    ><em class="icon ni ni-eye"></em
                                    ><span>View Details</span></a
                                  >
                                </li>
                                <li>
                                  <router-link
                                    :to="{
                                      name: 'ReceiveVoucher',
                                      params: { reference: row.reference },
                                    }"
                                    ><em class="icon ni ni-file-text"></em
                                    ><span>View Voucher</span></router-link
                                  >
                                </li>
                                <li>
                                  <router-link
                                    :to="{
                                      name: 'ProductReceive',
                                      query: {
                                        action: 'edit',
                                        reference: row.reference,
                                      },
                                    }"
                                    ><em class="icon ni ni-edit"></em
                                    ><span>Edit</span></router-link
                                  >
                                </li>
                                <li>
                                  <a href="javascript:void(0)"
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Delete</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="nk-tb-item">
                    <div class="nk-tb-col"><span></span></div>
                    <div class="nk-tb-col"><h6>Grand Total</h6></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col">
                      <h6>
                        {{ `${appSettings.currency} ${addComma(grandTotal)}` }}
                      </h6>
                    </div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                  </div>
                </div>
                <div class="row g-gs" v-else>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="example-alert">
                          <div class="alert alert-fill alert-danger alert-icon">
                            <em class="icon ni ni-cross-circle"></em>
                            <strong>No records found </strong>! Please check
                            your search keywords and try again
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bootstrap-modal
      v-if="modalOpen"
      @close="toggleModal"
      style="display: block"
      :modalSize="'modal-lg'"
      :showFooter="false"
    >
      <span slot="head" style="padding-top: 10px"
        >RECEIVING VOUCHER
        <a href="javascript:void(0)">{{ record.code }}</a></span
      >
      <div slot="body">
        <div class="table-responsive">
          <div class="card card-full">
            <div class="nk-tb-list mt-n2">
              <div class="nk-tb-item nk-tb-head">
                <div class="nk-tb-col"><span>S/N</span></div>
                <div class="nk-tb-col"><span>Name</span></div>
                <div class="nk-tb-col"><span>Code</span></div>
                <div class="nk-tb-col"><span>Type</span></div>
                <div class="nk-tb-col"><span>Cost Price</span></div>
                <div class="nk-tb-col"><span>Quantity</span></div>
                <div class="nk-tb-col"><span>Tot. Amount</span></div>
              </div>
              <div
                class="nk-tb-item"
                v-for="(item, i) in items"
                :key="'item' + i"
              >
                <div class="nk-tb-col">
                  <span>{{ i + 1 }}</span>
                </div>
                <div class="nk-tb-col">
                  <div class="user-card">
                    <div class="user-name">
                      <span class="tb-lead">{{ item.product.name }}</span>
                    </div>
                  </div>
                </div>
                <div class="nk-tb-col">
                  <span class="tb-lead"
                    ><a href="javascript:void(0)"
                      >#{{ item.product.code }}</a
                    ></span
                  >
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub">{{ item.product.type }}</span>
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub"
                    >{{ appSettings.currency }} {{ addComma(item.price) }}</span
                  >
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub">{{ addComma(item.quantity) }}</span>
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub tb-amount"
                    ><span>{{ appSettings.currency }} </span
                    >{{ addComma(item.quantity * item.price) }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </bootstrap-modal>
  </section>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
import Multiselect from "vue-multiselect";
export default {
  name: "Receive",
  components: { Multiselect },
  mixins: [dashboardServices],
  data: () => ({
    fetchUrl: "reports/receive",
    items: [],
    record: {},
    suppliers: [],
    supplier: null,
  }),
  mounted() {
    this.$store
      .dispatch("getRequest", "settings/suppliers")
      .then((response) => {
        this.suppliers = response.data.rows.data;
      });
  },
  computed: {
    grandTotal() {
      return this.rows.reduce((a, b) => a + Number(b.total_amount), 0);
    },
  },
  methods: {
    handleSupplierFilter(supplier) {
      if (!this.empty(supplier)) {
        this.newFilter.supplier = supplier.id;
        this.handlePaginationFilter();
      }
    },
    getItemVoucher(reference) {
      this.items = [];
      this.record = {};
      this.$store
        .dispatch("getRequest", `/reports/receive/${reference}/voucher`)
        .then((response) => {
          this.items = response.data.items;
          this.record = response.data.record;
          this.toggleModal();
        });
    },
  },
};
</script>
<style scoped>
.badge[title="EXCLUSIVE"] {
  color: #09c2de;
  border-color: #6bdaeb;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
