<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch p-4">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h5 class="nk-block-title page-title">
                      Items Transfer Report
                      <small>
                        <a href="javascript:void(0)">({{ reportLabel }})</a>
                      </small>
                    </h5>
                    <div class="nk-block-des text-soft">
                      <!-- <p>You have total {{ totalRows }} requests.</p> -->
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="javascript:void(0)"
                        class="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="more-options"
                        ><em class="icon ni ni-more-v"></em
                      ></a>
                      <div
                        class="toggle-expand-content"
                        data-content="more-options"
                      >
                        <ul class="nk-block-tools g-3">
                          <li>
                            <multiselect
                              v-model="destination"
                              :options="destinations"
                              group-values="rows"
                              group-label="destination"
                              placeholder="Destination"
                              label="name"
                              track-by="name"
                              @select="filterByDestination"
                            ></multiselect>
                          </li>
                          <li>
                            <div class="d-flex align-items-center">
                              <span class="mr-2"> From: </span>
                              <input
                                type="date"
                                class="form-control"
                                v-model="newFilter.from"
                              />
                            </div>
                          </li>
                          <li>
                            <div class="d-flex align-items-center">
                              <span class="mr-2"> To: </span>
                              <input
                                type="date"
                                class="form-control"
                                v-model="newFilter.to"
                              />
                            </div>
                          </li>
                          <li class="nk-block-tools-opt">
                            <button
                              type="button"
                              class="btn btn-primary d-none d-md-inline-flex"
                              :disabled="empty(newFilter.from)"
                              @click="handlePaginationFilter"
                            >
                              <em class="icon ni ni-filter"></em
                              ><span>Filter</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="totalRows">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Item Name</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Item Code</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Item Type</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Destination</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Cost Price</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Tot. Quantity</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Tot. Amount</span>
                    </div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(row, i) in rows"
                    :key="'row' + i"
                  >
                    <div class="nk-tb-col">
                      <span>{{ i + 1 }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="title">{{ row.product.name }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">#{{ row.product.code }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span>{{ row.product.type }}</span>
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount" v-if="!empty(row.source)">{{
                        row.source || "N/A"
                      }}</span>
                      <span class="tb-amount" v-else>{{
                        row.branch || "N/A"
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead"
                        >{{ appSettings.currency
                        }}{{
                          addComma(row.product.cost || row.product.cost_price)
                        }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{ addComma(row.quantity) }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount"
                        >{{ appSettings.currency }}
                        {{
                          addComma(
                            row.quantity *
                              (row.product.cost || row.product.cost_price)
                          )
                        }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="row g-gs" v-else>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="example-alert">
                          <div class="alert alert-fill alert-danger alert-icon">
                            <em class="icon ni ni-cross-circle"></em>
                            <strong>No records found </strong>! Please
                            check your search keywords and try again
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
import Multiselect from "vue-multiselect";
import { mapState } from "vuex";
export default {
  name: "ItemTransfer",
  components: { Multiselect },
  mixins: [dashboardServices],
  data: () => ({
    fetchUrl: "reports/items/transfer",
    items: [],
    record: {},
    sources: [],
    destination: null,
  }),
  computed: {
    ...mapState({
      branches: (state) => state.branches,
    }),
    destinations() {
      return [
        {
          destination: "Branches",
          rows: this.branches,
        },
        {
          destination: "Production",
          rows: this.sources,
        },
      ];
    },
  },
  mounted() {
    this.$store
      .dispatch("getRequest", "production/sources")
      .then((response) => {
        this.sources = response.data.sources;
      });
  },
  methods: {
    filterByDestination(destination) {
      if (!this.empty(destination)) {
        this.newFilter.branch = null;
        this.newFilter.source = null;
        let keys = Object.keys(destination);
        if (keys.includes("phone")) this.newFilter.branch = destination.id;
        else this.newFilter.source = destination.id;
        this.handlePaginationFilter();
      }
    },
  },
};
</script>
<style scoped>
.badge[title="EXCLUSIVE"] {
  color: #09c2de;
  border-color: #6bdaeb;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
