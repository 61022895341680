<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch p-4">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between" v-if="isReport">
                  <div class="nk-block-head-content">
                    <h5 class="nk-block-title page-title">
                      Production Sales Report
                      <small>
                        <a href="javascript:void(0)">({{ reportLabel }})</a>
                      </small>
                    </h5>
                    <div class="nk-block-des text-soft">
                      <!-- <p>You have total {{ totalRows }} requests.</p> -->
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="javascript:void(0)"
                        class="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="more-options"
                        ><em class="icon ni ni-more-v"></em
                      ></a>
                      <div
                        class="toggle-expand-content"
                        data-content="more-options"
                      >
                        <ul class="nk-block-tools g-3">
                          <li>
                            <div class="d-flex align-items-center">
                              <span class="mr-2"> From: </span>
                              <input
                                type="date"
                                class="form-control"
                                v-model="newFilter.from"
                              />
                            </div>
                          </li>
                          <li>
                            <div class="d-flex align-items-center">
                              <span class="mr-2"> To: </span>
                              <input
                                type="date"
                                class="form-control"
                                v-model="newFilter.to"
                              />
                            </div>
                          </li>
                          <li class="nk-block-tools-opt">
                            <button
                              type="button"
                              class="btn btn-primary d-none d-md-inline-flex"
                              :disabled="empty(newFilter.from)"
                              @click="handlePaginationFilter"
                            >
                              <em class="icon ni ni-filter"></em
                              ><span>Filter</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="nk-block-between" v-else>
                  <div class="nk-block-head-content">
                    <h5 class="nk-block-title page-title">
                      Production Sales Invoices
                      <small>
                        <a href="javascript:void(0)">({{ newFilter.from }})</a>
                      </small>
                    </h5>
                    <div class="nk-block-des text-soft">
                      <p>You have total {{ totalRows }} Invoices.</p>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <div class="toggle-expand-content">
                        <ul class="nk-block-tools g-3">
                          <li class="nk-block-tools-opt">
                            <router-link
                              :to="{ name: 'ProductionSales' }"
                              class="btn btn-primary d-md-inline-flex"
                            >
                              <em class="icon ni ni-plus"></em
                              ><span>Create New</span>
                            </router-link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="totalRows">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Date</span></div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Client</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Tot. Amount</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Discount</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Disc. Total</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Amount Paid</span>
                    </div>
                    <div class="nk-tb-col" v-if="false">
                      <span class="sub-text">Remain Amount</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Pay. Method</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Status</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text" v-if="isReport">Created By</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(row, i) in rows"
                    :key="'row' + i"
                  >
                    <div class="nk-tb-col">
                      <span>#{{ generateVoucherNo(row.id) }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="title">{{
                        chatDate(row.committed_date)
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <div class="user-card">
                        <avatar
                          :name="`${row.client.first_name} ${
                            row.client.last_name || ''
                          }`"
                          width="32px"
                          height="32px"
                        ></avatar>
                        <div class="user-name">
                          <span class="tb-lead">{{ row.client.name }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-sub tb-amount"
                        ><span>{{ appSettings.currency }}</span>
                        {{ addComma(row.total_amount) }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span>{{ row.discount_perc }}%</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-sub tb-amount"
                        ><span>{{ appSettings.currency }}</span>
                        {{ addComma(row.discounted_total) }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-sub tb-amount"
                        ><span>{{ appSettings.currency }}</span>
                        {{ addComma(row.amount_paid) }}</span
                      >
                    </div>
                    <div class="nk-tb-col" v-if="false">
                      <span class="tb-sub tb-amount"
                        >{{ appSettings.currency }}
                        {{ addComma(row.amount_remain) }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{
                        row.payment_method || "N/A"
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span
                        class="badge badge-dot badge-dot-xs bg-success"
                        v-if="row.paid == 1"
                        >Paid</span
                      >
                      <span
                        class="badge badge-dot badge-dot-xs bg-warning"
                        v-else
                        >Due</span
                      >
                    </div>
                    <div class="nk-tb-col" v-if="isReport">
                      <span class="tb-lead" v-if="!empty(row.creator)">{{
                        row.creator.name
                      }}</span>
                      <span v-else>N/A</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger"
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="getSaleItems(row)"
                                    ><em class="icon ni ni-eye"></em
                                    ><span>View Details</span></a
                                  >
                                </li>
                                <li>
                                  <a href="javascript:void(0)"
                                    ><em class="icon ni ni-printer-fill"></em
                                    ><span>Print</span></a
                                  >
                                </li>
                                <li v-if="row.paid != 1">
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="togglePaymentModal(row)"
                                    ><em class="icon ni ni-coins"></em
                                    ><span>Pay Remaining</span></a
                                  >
                                </li>
                                <li>
                                  <router-link
                                    :to="{
                                      name: 'ProductionSales',
                                      params: {
                                        reference: row.reference,
                                      },
                                    }"
                                    ><em class="icon ni ni-edit"></em
                                    ><span>Edit</span></router-link
                                  >
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      handleDeleteAlertModal({
                                        tableName: 'sales',
                                        entityIdentifer: 'sales record',
                                        entity: row.reference,
                                        url: `sales/delete/${row.id}`,
                                        callback: () => {
                                          rows.splice(i, 1);
                                        },
                                      })
                                    "
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Delete</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="nk-tb-item">
                    <div class="nk-tb-col"><span></span></div>
                    <div class="nk-tb-col"><h6>Grand Total</h6></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col">
                      <h6>
                        {{ `${appSettings.currency} ${addComma(totalAmount)}` }}
                      </h6>
                    </div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col">
                      <h6>
                        {{
                          `${appSettings.currency} ${addComma(totalDiscounted)}`
                        }}
                      </h6>
                    </div>
                    <div class="nk-tb-col">
                      <h6>
                        {{ `${appSettings.currency} ${addComma(totalPaid)}` }}
                      </h6>
                    </div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col" v-if="isReport"></div>
                    <div class="nk-tb-col"></div>
                  </div>
                </div>
                <div class="row g-gs" v-else>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="example-alert">
                          <div class="alert alert-fill alert-danger alert-icon">
                            <em class="icon ni ni-cross-circle"></em>
                            <strong>No records found </strong>! Please check
                            your search keywords and try again
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bootstrap-modal
      v-if="modalOpen"
      @close="toggleModal"
      style="display: block"
      :modalSize="'modal-lg'"
      :showFooter="false"
    >
      <span slot="head" style="padding-top: 10px"
        >RECEIPT <a href="javascript:void(0)">#{{ record.reference }}</a></span
      >
      <div slot="body">
        <div class="invoice" id="invoice-container" v-if="!empty(record)">
          <div class="invoice-action">
            <a
              class="btn btn-icon btn-lg btn-white btn-dim btn-outline-primary"
              href="javascript:void(0)"
              ><em class="icon ni ni-printer-fill"></em
            ></a>
          </div>
          <div class="invoice-wrap">
            <div class="invoice-brand">
              <img :src="`${apiURL}/${appSettings.site_logo}`" alt="" />
            </div>
            <div class="invoice-head">
              <div class="invoice-contact">
                <span class="overline-title">CLIENT</span>
                <div class="invoice-contact-info">
                  <h4 class="title">{{ record.client.name }}</h4>
                  <ul class="list-plain">
                    <li class="py-0">
                      <em class="icon ni ni-map-pin-fill"></em
                      ><span>{{ record.client.address }}</span>
                    </li>
                    <li class="my-0">
                      <em class="icon ni ni-call-fill"></em
                      ><span>{{ record.client.phone }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="invoice-desc" style="width: 180px">
                <h3 class="title">RECEIPT</h3>
                <ul class="list-plain">
                  <li class="invoice-id">
                    <span>Voucher No:</span>:<span>{{
                      generateVoucherNo(record.id)
                    }}</span>
                  </li>
                  <li class="invoice-date">
                    <span>Date</span>:<span>{{
                      chatDate(record.committed_date)
                    }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="invoice-bills">
              <div class="table-responsive">
                <table class="table table-striped-">
                  <thead>
                    <tr>
                      <th>$/N</th>
                      <th class="w-200px">Item Name</th>
                      <th>Price</th>
                      <th>Qty</th>
                      <th>Sub. Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in items" :key="'item' + i">
                      <td>{{ i + 1 }}</td>
                      <td>{{ item.product.name }}</td>
                      <td>
                        <span class="tb-sub tb-amount">
                          <span>{{ appSettings.currency }}</span>

                          {{ addComma(item.price) }}
                        </span>
                      </td>
                      <td>{{ addComma(item.quantity) }}</td>
                      <td>
                        <span class="tb-sub tb-amount"
                          ><span>{{ appSettings.currency }} </span
                          >{{ addComma(item.total_amount) }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="2"></td>
                      <td colspan="2">Subtotal</td>
                      <td>
                        {{ appSettings.currency
                        }}{{ addComma(record.total_amount) }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2"></td>
                      <td colspan="2">Discount</td>
                      <td>{{ record.discount_perc }}%</td>
                    </tr>
                    <tr>
                      <td colspan="2"></td>
                      <td colspan="2">Grand Total</td>
                      <td>
                        {{ appSettings.currency
                        }}{{ addComma(record.discounted_total) }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <div class="nk-notes ff-italic fs-12px text-soft">
                  <!-- Invoice was created on a computer and is valid without
                        the signature and seal. -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </bootstrap-modal>
    <bootstrap-modal
      v-if="paymentModal"
      @close="togglePaymentModal"
      style="display: block"
      :modalSize="'modal-default'"
      :showFooter="false"
    >
      <span slot="head" style="padding-top: 10px"
        >PAYMENT FOR VOUCHER
        <a href="javascript:void(0)"
          >#{{ generateVoucherNo(record.id) }}</a
        ></span
      >
      <div slot="body">
        <div class="row g-gs align-items-center">
          <div class="col-6">
            <div class="form-group">
              <label class="form-label">Total Amount</label>
              <div class="form-control-wrap">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text px-2">{{
                      appSettings.currency
                    }}</span>
                  </div>
                  <input
                    type="text"
                    :value="record.discounted_total"
                    class="form-control form-control-lg"
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="form-label">Paid Amount</label>
              <div class="form-control-wrap">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text px-2">{{
                      appSettings.currency
                    }}</span>
                  </div>
                  <input
                    type="text"
                    :value="record.amount_paid"
                    class="form-control form-control-lg"
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="form-label">Amount To Pay</label>
              <div class="form-control-wrap">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text px-2">{{
                      appSettings.currency
                    }}</span>
                  </div>
                  <input
                    type="number"
                    v-model="newPayment.amount_paid"
                    class="form-control form-control-lg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="form-label">Issued date</label>
              <div class="form-control-wrap">
                <input
                  type="date"
                  v-model="newPayment.committed_date"
                  class="form-control form-control-lg"
                  placeholder="Issued date"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="form-label">Payment Method</label>
              <div class="form-control-wrap">
                <select
                  class="form-control form-control-lg"
                  v-model="newPayment.payment_method"
                >
                  <option>CASH</option>
                  <option>MOMO</option>
                  <option>CHEQUE</option>
                  <option>BANK TRANSFER</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group d-block">
              <label class="form-label w-100">&nbsp;&nbsp;</label>
              <wolf-button
                classes="btn btn-primary"
                @btn-role="handlePartialPayment"
                :disabler="!newPayment.amount_paid"
                activator="saving_records"
              >
                <span slot="caption"
                  ><em class="icon ni ni-save"></em
                  ><span>Confirm and Save</span></span
                >
              </wolf-button>
            </div>
          </div>
        </div>
      </div>
    </bootstrap-modal>
  </section>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
import Avatar from "@/components/Avatar";
export default {
  name: "SalesReport",
  mixins: [dashboardServices],
  components: { Avatar },
  data: () => ({
    fetchUrl: "sales/PRODUCTION",
    items: [],
    record: {},
    paymentModal: false,
    newPayment: {
      record_id: null,
      amount_paid: 0,
      amount_remain: 0,
      payment_method: "CASH",
      committed_date: new Date()
        .toLocaleString("fr-CA", { timeZone: "Africa/Kigali" })
        .slice(0, 10),
    },
  }),
  computed: {
    isReport() {
      return this.$route.name == "ProductionSalesReport";
    },
    totalAmount() {
      return this.rows.reduce((a, b) => a + Number(b.total_amount), 0);
    },
    totalDiscounted() {
      return this.rows.reduce((a, b) => a + Number(b.discounted_total), 0);
    },
    totalPaid() {
      return this.rows.reduce((a, b) => a + Number(b.amount_paid), 0);
    },
  },
  methods: {
    togglePaymentModal(record = null) {
      if (record) {
        this.record = record;
        this.newPayment.record_id = record.id;
        this.newPayment.amount_paid = record.amount_remain;
      }
      this.toggleModalOpen();
      this.paymentModal = !this.paymentModal;
    },
    getSaleItems(record) {
      this.items = [];
      this.record = record;
      this.$store
        .dispatch("getRequest", `/sales/items/${record.id}`)
        .then((response) => {
          this.items = response.data.items;
          this.toggleModal();
        });
    },
    handlePartialPayment() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.newPayment.amount_remain =
            this.record.discounted_total -
            (this.record.amount_paid + this.newPayment.amount_paid);
          this.$store
            .dispatch("postRequest", {
              url: "sales/payments/partial",
              formData: this.formData(this.newPayment),
            })
            .then((response) => {
              if (response.data.status) {
                this.clearObject(this.newPayment);
                this.togglePaymentModal();
              }
            })
            .catch((err) => console.log(err));
        }
      });
    },
  },
};
</script>
