<template>
  <section class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="wide-xl mx-auto">
          <div class="card card-stretch p-4">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h5 class="nk-block-title page-title">
                      Suppliers Bulk Payment
                    </h5>
                  </div>
                  <div class="nk-block-head-content"></div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="items.length">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>Due Date</span></div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Voucher No</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Supplier</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Balance</span>
                    </div>
                    <div class="nk-tb-col">Amount To Pay</div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(row, i) in items"
                    :key="'row' + i"
                  >
                    <div class="nk-tb-col">
                      <span class="title">{{
                        chatDate(row.received_date)
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">#{{ row.code }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{ row.supplier }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{ row.supplier }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount"
                        >{{ appSettings.currency }}
                        {{ addComma(row.balance) }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <div class="form-control-wrap">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              {{ appSettings.currency }}
                            </span>
                          </div>
                          <input
                            type="number"
                            class="form-control"
                            placeholder="Payment Amount"
                            v-model="items[i].amount"
                            v-validate="'required|max_value:' + items[i].amount"
                            :name="'Amount' + i"
                            :class="{
                              'is-invalid': errors.has('Amount' + i),
                            }"
                          />
                        </div>
                        <span class="text-danger">{{
                          errors.first("Amount" + i)
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="nk-tb-item">
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"><h6>Grand Total</h6></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col">
                      <h6>{{ appSettings.currency }} {{ grandTotal }}</h6>
                    </div>
                  </div>
                  <div class="nk-tb-item">
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col">
                      <div class="form-group">
                        <div class="form-control-select">
                          <select class="form-control" v-model="payment_type">
                            <option value="null" hidden disabled>
                              Payment Type
                            </option>
                            <option>CASH</option>
                            <option>MOMO</option>
                            <option>CHEQUE</option>
                            <option>BANK TRANSFER</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <wolf-button
                        caption="Confirm and Save"
                        @btn-role="handleSubmit"
                        :disabler="!items.length || !payment_type"
                        activator="handling_payment"
                        classes="btn btn-primary"
                      ></wolf-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "BulkPayment",
  data: () => ({
    vouchers: [],
    items: [],
    payment_type: null,
  }),
  computed: {
    grandTotal() {
      return this.items.reduce((a, b) => a + Number(b.amount), 0);
    },
  },
  created() {
    let vounchers = this.$route.query.vouchers;
    this.$store
      .dispatch(
        "getRequest",
        `finance/suppliers/payment/vounchers/${vounchers}`
      )
      .then((response) => {
        this.vouchers = response.data.vouchers;
        this.vouchers.forEach((item) => {
          this.items.push({
            id: item.id,
            code: item.code,
            supplier: item.vendor.name,
            received_date: item.received_date,
            amount: (
              Number(item.total_amount) - Number(item.amount_paid)
            ).toFixed(2),
            balance: Number(item.total_amount) - Number(item.amount_paid),
          });
        });
      });
  },
  methods: {
    handleSubmit() {
      this.$store
        .dispatch("postRequest", {
          url: "finance/suppliers/payment/bulk",
          formData: this.formData({
            items: this.items,
            payment_type: this.payment_type,
          }),
        })
        .then((response) => {
          if (response.data.status) {
            this.$store.commit("SET_FLASH_MESSAGE", {
              type: "success",
              message: response.data.message,
            });
            this.$router.push({ name: "SupplierPayments" });
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
