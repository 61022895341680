<template>
  <div
    class="
      nk-block nk-block-middle nk-auth-body
      wide-xs
      min-vh-100
      d-flex
      justify-content-center
      align-items-center
    "
  >
    <div class="card w-100">
      <div class="card-inner card-inner-lg">
        <div class="nk-block-head">
          <div class="brand-logo">
            <router-link :to="{ name: '' }" class="logo-link"
              ><img
                class="logo-dark logo-img logo-img-lg"
                :src="`${apiURL}/${appSettings.site_logo}`"
                alt="logo"
            /></router-link>
          </div>
          <div class="nk-block-head-content">
            <h4 class="nk-block-title">Login</h4>
          </div>
        </div>
        <form action="" method="POST" @submit.prevent="authenticateUser">
          <div class="form-group">
            <div class="form-label-group">
              <label class="form-label" for="default-01">Username</label>
            </div>
            <div class="form-control-wrap">
              <input
                type="text"
                class="form-control form-control-lg"
                id="default-01"
                placeholder="Enter your username"
                name="Username"
                v-validate="'required'"
                :class="{ 'is-invalid': errors.has('Username') }"
                v-model="credentials.email"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="form-label-group">
              <label class="form-label" for="password">Password</label>
            </div>
            <div class="form-control-wrap">
              <a
                href="javascript:void(0)"
                @click.prevent="handleVisibility"
                class="form-icon form-icon-right passcode-switch lg"
                ><em
                  class="passcode-icon icon-show icon ni ni-eye"
                  v-if="passwordType == 'password'"
                ></em
                ><em
                  class="passcode-icon icon-hide icon ni ni-eye-off d-block"
                  v-else
                ></em></a
              ><input
                :type="passwordType"
                class="form-control form-control-lg"
                id="password"
                placeholder="Enter your password"
                name="password"
                :class="{ 'is-invalid': errors.has('password') }"
                v-validate="'required'"
                v-model="credentials.password"
              />
            </div>
          </div>
          <template v-if="false">
            <div class="alert alert-danger my-2" v-if="invalidLogin">
              <span>Invalid password or Email addess</span>
            </div>
            <div
              class="alert alert-success fade show my-2"
              role="alert"
              v-if="loginSucceed"
            >
              <strong>Success!</strong> Login Success. Redirecting...
            </div>
          </template>
          <div class="form-group">
            <wolf-button
              caption="Login"
              button-type="submit"
              @btn-role="authenticateUser"
              :disabler="!credentials.email || !credentials.password"
              activator="signing"
              >Login</wolf-button
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      credentials: {
        email: null,
        password: null,
      },
      invalidLogin: false,
      loginSucceed: false,
      passwordType: "password",
    };
  },
  created() {
    if (this.$store.getters.isLoggedIn) {
      if (navigator.userAgent.indexOf("Firefox") > 0)
        setTimeout(() => {
          location.replace("/dashboard");
        }, 2000);
      else location.replace("/dashboard");
    }
  },
  methods: {
    handleVisibility() {
      if (this.passwordType == "password") this.passwordType = "text";
      else this.passwordType = "password";
    },
    authenticateUser() {
      this.$store
        .dispatch("authRequest", {
          formData: this.formData(this.credentials),
          url: "auth/login",
        })
        .then((response) => {
          if (response.data.status) {
            /*this.invalidLogin = false;
            this.loginSucceed = true; */
            this.$store.commit("SET_FLASH_MESSAGE", {
              type: "success",
              message: "Login Succeed. Redirecting...",
            });
            if (navigator.userAgent.indexOf("Firefox") > 0)
              setTimeout(() => {
                location.replace("/dashboard");
              }, 3000);
            else location.replace("/dashboard");
          }
        })
        .catch((err) => {
          if (err.response.status == 401) {
            /*this.invalidLogin = true;
            this.loginSucceed = false; */
            this.$store.commit("SET_FLASH_MESSAGE", {
              type: "danger",
              message: "Invalid Password Or Username. Try Again",
            });
          }
        });
    },
  },
};
</script>
<style>
.logo-img-lg {
  max-height: 100px;
}
body {
  background-image: url("/img/bg-01.jpg");
}
</style>
