<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch p-4">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">
                      Requisition History
                    </h3>
                    <div class="nk-block-des text-soft">
                      <p>You have total {{ totalRows }} requests.</p>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="javascript:void(0)"
                        class="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="more-options"
                        ><em class="icon ni ni-more-v"></em
                      ></a>
                      <div
                        class="toggle-expand-content"
                        data-content="more-options"
                      >
                        <ul class="nk-block-tools g-3">
                          <li>
                            <div class="dropdown filter-dropdown">
                              <a
                                href="#"
                                class="
                                  dropdown-toggle
                                  btn btn-white btn-dim btn-outline-light
                                "
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                ><em
                                  class="
                                    d-none d-sm-inline
                                    icon
                                    ni ni-filter-alt
                                  "
                                ></em
                                ><span>Filtered By</span
                                ><em
                                  class="dd-indc icon ni ni-chevron-right"
                                ></em
                              ></a>
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                style=""
                              >
                                <div class="p-3" style="width: 400px">
                                  <div class="row w-100">
                                    <div class="col-lg-12">
                                      <h5 class="mb-3">Filter Results</h5>
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-control-wrap mb-2">
                                        <div class="form-icon form-icon-right">
                                          <em class="icon ni ni-search"></em>
                                        </div>
                                        <input
                                          type="search"
                                          class="form-control"
                                          id="default-04"
                                          placeholder="Search by Voucher No"
                                          v-model="filterKey"
                                          @keyup="handlePaginationSearch"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <select
                                          class="form-control form-select"
                                          v-model="newFilter.type"
                                        >
                                          <option value="all">All Items</option>
                                          <option value="STOCK">
                                            MAIN STORABLE
                                          </option>
                                          <option value="PERISHABLES">
                                            PERISHABLES
                                          </option>
                                          <option value="PRODUCTION_STOCK">
                                            PRODUCTION
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <select
                                          class="form-control form-select"
                                          v-model="newFilter.status"
                                        >
                                          <option value="all">All Items</option>
                                          <option>PENDING</option>
                                          <option>ACCEPTED</option>
                                          <option>DENIED</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.from"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.to"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-group text-right">
                                        <button
                                          type="button"
                                          class="
                                            btn btn-primary
                                            d-none d-md-inline-flex
                                          "
                                          :disabled="empty(newFilter)"
                                          @click="handlePaginationFilter"
                                        >
                                          <em class="icon ni ni-filter"></em
                                          ><span>Filter</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="nk-block-tools-opt">
                            <router-link
                              :to="{ name: 'CreateRequest' }"
                              class="btn btn-primary d-none d-md-inline-flex"
                              ><em class="icon ni ni-plus"></em
                              ><span>Create New</span></router-link
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="totalRows > 0">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col nk-tb-col-check">
                      <div
                        class="
                          custom-control custom-control-sm custom-checkbox
                          notext
                        "
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="uid"
                        /><label class="custom-control-label" for="uid"></label>
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Reference</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Date</span>
                    </div>
                    <div class="nk-tb-col" v-if="empty(loggedUser.branch)">
                      <span class="sub-text">Source/Branch</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Destination</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Total Amount</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Status</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Created By</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1 my-n1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="
                                dropdown-toggle
                                btn btn-icon btn-trigger
                                mr-n1
                              "
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <a href="javascript:void(0)"
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Remove Seleted</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(item, i) in rows"
                    :key="'item' + i"
                  >
                    <div class="nk-tb-col nk-tb-col-check">
                      <div
                        class="
                          custom-control custom-control-sm custom-checkbox
                          notext
                        "
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="uid1"
                        /><label
                          class="custom-control-label"
                          for="uid1"
                        ></label>
                      </div>
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount"
                        >#{{ item.reference || "N/A" }}</span
                      >
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount">{{
                        chatDate(item.created_at)
                      }}</span>
                    </div>
                    <div
                      class="nk-tb-col tb-col-mb"
                      v-if="empty(loggedUser.branch)"
                    >
                      <span class="tb-amount" v-if="!empty(item.source)">{{
                        item.source.name || "N/A"
                      }}</span>
                      <span class="tb-amount" v-else>{{
                        item.branch.name || "N/A"
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span
                        v-if="item.type == 'STOCK' || item.type == 'PRODUCTION'"
                        >MAIN STOCK</span
                      >
                      <span v-else-if="item.type == 'PRODUCTION_STOCK'"
                        >PRODUCTION</span
                      >
                      <span v-else>PERISHABLES</span>
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount"
                        >{{ appSettings.currency }}
                        {{ addComma(item.total_amount) || "0" }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span
                        class="tb-status text-warning"
                        v-if="item.status == 'PENDING'"
                        >Pending</span
                      >
                      <span
                        class="tb-status text-success"
                        v-if="item.status == 'ACCEPTED'"
                        >Accepted</span
                      >
                      <span
                        class="tb-status text-danger"
                        v-if="item.status == 'DENIED'"
                        >Denied</span
                      >
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount">{{
                        item.creator.name || "N/A"
                      }}</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger"
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      getRequisitionItems(item.reference)
                                    "
                                    ><em class="icon ni ni-eye"></em
                                    ><span>View Details</span></a
                                  >
                                </li>
                                <template
                                  v-if="
                                    item.status == 'PENDING' &&
                                    empty(loggedUser.branch)
                                  "
                                >
                                  <li v-if="item.status == 'PENDING'">
                                    <router-link
                                      :to="{
                                        name: 'StockDelivery',
                                        params: { reference: item.reference },
                                      }"
                                      ><em class="icon ni ni-send"></em
                                      ><span>Deliver</span></router-link
                                    >
                                  </li>
                                  <li>
                                    <a href="javascript:void(0)"
                                      ><em class="icon ni ni-na"></em
                                      ><span>Rejected</span></a
                                    >
                                  </li>
                                </template>
                                <li v-if="item.status == 'PENDING'">
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      handleDeleteAlertModal({
                                        tableName: 'items',
                                        entityIdentifer: 'item',
                                        entity: `${item.name || ''}`,
                                        url: `settings/items/${item.id}`,
                                        callback: () => {
                                          rows.splice(i, 1);
                                          $store.commit(
                                            'REDUCE_NOTIFICATIONS',
                                            'requisitions'
                                          );
                                        },
                                      })
                                    "
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Remove</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row g-gs" v-else>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="example-alert">
                          <div class="alert alert-fill alert-danger alert-icon">
                            <em class="icon ni ni-cross-circle"></em>
                            <strong>No Requisition History found</strong>!
                            Please check your search keywords or place a new
                            request.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bootstrap-modal
      v-if="modalOpen"
      @close="toggleModal"
      style="display: block"
      :modalSize="'modal-lg'"
      :showFooter="false"
    >
      <span slot="head" style="padding-top: 10px">Requested Items</span>
      <div slot="body">
        <div class="table-responsive">
          <div class="card card-full">
            <div class="nk-tb-list mt-n2">
              <div class="nk-tb-item nk-tb-head">
                <div class="nk-tb-col"><span>Product #</span></div>
                <div class="nk-tb-col"><span>Name</span></div>
                <div class="nk-tb-col"><span>Type</span></div>
                <div class="nk-tb-col"><span>Cost Price</span></div>
                <div class="nk-tb-col"><span>Requested Qty</span></div>
                <div class="nk-tb-col"><span>Amount</span></div>
                <div class="nk-tb-col" v-if="false">
                  <span>Received Qty</span>
                </div>
              </div>
              <div
                class="nk-tb-item"
                v-for="(item, i) in items"
                :key="'item' + i"
              >
                <div class="nk-tb-col">
                  <span class="tb-lead"
                    ><a href="javascript:void(0)"
                      >#{{ item.product.code }}</a
                    ></span
                  >
                </div>
                <div class="nk-tb-col">
                  <div class="user-card">
                    <div class="user-name">
                      <span class="tb-lead">{{ item.product.name }}</span>
                    </div>
                  </div>
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub">{{ item.product.type }}</span>
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub"
                    >{{ appSettings.currency }}
                    {{
                      addComma(item.product.cost_price || item.product.cost)
                    }}</span
                  >
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub">{{ item.requested_quantity }}</span>
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub tb-amount"
                    ><span>{{ appSettings.currency }} </span
                    >{{
                      addComma(
                        (item.product.cost_price || item.product.cost) *
                          item.requested_quantity
                      )
                    }}</span
                  >
                </div>
                <div class="nk-tb-col" v-if="false">
                  <span>{{ item.received_quantity }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </bootstrap-modal>
  </section>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
export default {
  name: "Requests",
  mixins: [dashboardServices],
  data: () => ({
    fetchUrl: "requisitions",
    items: [],
    newFilter: {
      from: null,
      to: null,
      type: "all",
      status: "all",
    },
  }),
  created() {
    /* this.newFilter.type = "all";
    this.newFilter.status = "all"; */
  },
  methods: {
    getRequisitionItems(reference) {
      this.items = [];
      this.$store
        .dispatch("getRequest", `requisitions/items/${reference}`)
        .then((response) => {
          this.items = response.data.items;
          this.toggleModal();
        });
    },
  },
};
</script>
