<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch p-4">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h5 class="nk-block-title page-title">
                      Spoiled Items Report
                      <small>
                        <a href="javascript:void(0)">({{ reportLabel }})</a>
                      </small>
                    </h5>
                    <div class="nk-block-des text-soft">
                      <!-- <p>You have total {{ totalRows }} requests.</p> -->
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="javascript:void(0)"
                        class="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="more-options"
                        ><em class="icon ni ni-more-v"></em
                      ></a>
                      <div
                        class="toggle-expand-content"
                        data-content="more-options"
                      >
                        <ul class="nk-block-tools g-3">
                          <li>
                            <div class="form-control-wrap">
                              <div class="form-icon form-icon-right">
                                <em class="icon ni ni-search"></em>
                              </div>
                              <input
                                type="search"
                                class="form-control"
                                id="default-04"
                                placeholder="Search by product name"
                                v-model="filterKey"
                                @keyup="handlePaginationSearch"
                              />
                            </div>
                          </li>
                          <li>
                            <div class="dropdown filter-dropdown">
                              <a
                                href="#"
                                class="
                                  dropdown-toggle
                                  btn btn-white btn-dim btn-outline-light
                                "
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                ><em
                                  class="
                                    d-none d-sm-inline
                                    icon
                                    ni ni-filter-alt
                                  "
                                ></em
                                ><span>Filtered By</span
                                ><em
                                  class="dd-indc icon ni ni-chevron-right"
                                ></em
                              ></a>
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                style=""
                              >
                                <div class="p-3" style="width: 400px">
                                  <div class="row w-100">
                                    <div class="col-lg-12">
                                      <h5 class="mb-3">Filter Results</h5>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.from"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.to"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-group text-right">
                                        <button
                                          type="button"
                                          class="
                                            btn btn-primary
                                            d-none d-md-inline-flex
                                          "
                                          :disabled="empty(newFilter)"
                                          @click="handlePaginationFilter"
                                        >
                                          <em class="icon ni ni-search"></em
                                          ><span>Search</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="totalRows">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col nk-tb-col-check">
                      <div
                        class="
                          custom-control custom-control-sm custom-checkbox
                          notext
                        "
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="pid"
                        /><label class="custom-control-label" for="pid"></label>
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Item Name</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Item Code</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Item Type</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Quantity</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Unit Price</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Tot. Amount</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Comment</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1 my-n1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="
                                dropdown-toggle
                                btn btn-icon btn-trigger
                                mr-n1
                              "
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <a href="javascript:void(0)"
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Remove Seleted</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(row, i) in rows"
                    :key="'row' + i"
                  >
                    <div class="nk-tb-col nk-tb-col-check">
                      <div
                        class="
                          custom-control custom-control-sm custom-checkbox
                          notext
                        "
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="pid1"
                        /><label
                          class="custom-control-label"
                          for="pid1"
                        ></label>
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <span class="title">{{ row.product.name }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">#{{ row.product.code }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span>{{ row.product.type }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{ addComma(row.quantity) }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead"
                        >{{ appSettings.currency
                        }}{{ addComma(row.price) }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount"
                        >{{ appSettings.currency }}
                        {{ addComma(row.total_amount) }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span>{{ row.comment || "N/A" }}</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger"
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <a href="javascript:void(0)"
                                    ><em class="icon ni ni-eye"></em
                                    ><span>Edit</span></a
                                  >
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      handleDeleteAlertModal({
                                        tableName: 'items',
                                        entityIdentifer: 'Spoiled Items',
                                        entity: `${row.product.name || ''}`,
                                        url: `stock/products/spoiled/delete/${row.id}`,
                                        callback: () => {
                                          rows.splice(i, 1);
                                        },
                                      })
                                    "
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Delete</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row g-gs" v-else>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="example-alert">
                          <div class="alert alert-fill alert-danger alert-icon">
                            <em class="icon ni ni-cross-circle"></em>
                            <strong>No records found </strong>! Please check
                            your search keywords and try again
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
export default {
  name: "SpoiledItems",
  mixins: [dashboardServices],
  data: () => ({
    fetchUrl: "reports/items/spoiled",
  }),
  computed: {},
  mounted() {},
  methods: {},
};
</script>
