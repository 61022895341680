<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="wide-md mx-auto">
          <div class="card card-stretch p-4">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title mb-0">
                      Sale produced products
                    </h3>
                    <div class="nk-block-des text-soft">
                      <p>You have total {{ rows.length }} products in stock</p>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="javascript:void(0)"
                        class="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                        ><em class="icon ni ni-more-v"></em
                      ></a>
                      <div
                        class="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul class="nk-block-tools g-3">
                          <li style="width: 250px">
                            <multiselect
                              v-model="newItem.product"
                              :options="rows"
                              placeholder="Select item"
                              label="name"
                              track-by="name"
                            ></multiselect>
                          </li>
                          <li style="width: 120px">
                            <input
                              type="number"
                              v-model="newItem.quantity"
                              class="form-control form-control-lg"
                              placeholder="Quantity"
                            />
                          </li>
                          <li class="nk-block-tools-opt">
                            <div class="drodown">
                              <button
                                type="button"
                                class="dropdown-toggle btn btn-icon btn-primary"
                                @click.prevent="handleSelectedItem"
                                :disabled="
                                  !newItem.quantity || empty(newItem.product)
                                "
                              >
                                <em class="icon ni ni-plus"></em>
                              </button>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="items.length > 0">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Name</span></div>
                    <div class="nk-tb-col"><span>Qty In</span></div>
                    <div class="nk-tb-col"><span>Cost Price</span></div>
                    <div class="nk-tb-col"><span>Sale Price</span></div>
                    <div class="nk-tb-col"><span>Quantity</span></div>
                    <div class="nk-tb-col"><span>Sub Total</span></div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>
                  <div
                    class="nk-tb-item"
                    v-for="(product, i) in items"
                    :key="'product' + i"
                  >
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ i + 1 }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ product.name }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span
                        >{{ items[i].current_qty }}
                        {{ items[i].unit || " " }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span>{{
                        `${appSettings.currency} ${
                          addComma(items[i].cost) || 0
                        }`
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <div class="form-control-wrap">
                        <div class="form-text-hint">
                          <span class="overline-title">{{
                            appSettings.currency
                          }}</span>
                        </div>
                        <input
                          type="number"
                          class="form-control"
                          :name="'Sale Price' + i"
                          v-model="items[i].price"
                          :class="{ 'is-invalid': errors.has('Quantity' + i) }"
                          v-validate="'required'"
                          @keyup="findTotalAmount(product)"
                        />
                      </div>
                      <span
                        class="text-danger"
                        v-if="errors.has('Sale Price' + i)"
                        >Sale Price is required
                      </span>
                    </div>
                    <div class="nk-tb-col">
                      <div class="form-control-wrap">
                        <div class="form-text-hint">
                          <span class="overline-title">{{ product.unit }}</span>
                        </div>
                        <input
                          type="number"
                          :name="'Quantity' + i"
                          v-model="items[i].quantity"
                          :class="{ 'is-invalid': errors.has('Quantity' + i) }"
                          v-validate="
                            'required|max_value:' + items[i].current_qty
                          "
                          class="form-control"
                          placeholder="Quantity"
                          @keyup="findTotalAmount(product)"
                        />
                      </div>
                      <span
                        class="text-danger"
                        v-if="errors.has('Quantity' + i)"
                        >Quantity is required and <br />
                        must be less or equal to
                        {{ items[i].current_qty }}
                      </span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title"
                          >{{ appSettings.currency }}
                          {{ addComma(items[i].total_amount) }}</span
                        ></span
                      >
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <a
                        href="javascript:void(0)"
                        @click.prevent="removeItem(i)"
                        ><em class="icon ni ni-trash text-danger"></em
                        ><span></span
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="w-100" v-if="items.length > 0">
                  <hr class="preview-hr" />
                  <div class="float-right w-25">
                    <h6
                      class="d-flex mb-3 align-items-center justify-content-end"
                    >
                      Grand Total:
                      <span class="ml-auto"
                        >{{ appSettings.currency }}
                        {{ addComma(grandTotal) }}</span
                      >
                    </h6>
                  </div>
                  <div class="w-100">
                    <div class="d-flex justify-content-end actions w-100">
                      <div class="col-4 px-1">
                        <div class="d-block">
                          <type-head
                            url="shared/clients/search"
                            label="name"
                            tracker="name"
                            :loadDefaults="true"
                            placeholder="Search by client name"
                            @changed="handleSelectedClient"
                            v-show="empty(newSales.client)"
                          ></type-head>
                          <div
                            class="form-control-wrap"
                            v-if="!empty(newSales.client)"
                          >
                            <div
                              class="form-text-hint"
                              @click="resetClient"
                              style="cursor: pointer"
                            >
                              <span class="overline-title text-danger"
                                ><em class="icon ni ni-cross"></em
                              ></span>
                            </div>
                            <input
                              type="text"
                              class="form-control form-control-lg"
                              :value="newSales.client.name"
                            />
                          </div>
                          <a
                            href="javascript:void(0)"
                            class="w-100"
                            style="text-decoration: underline; float:right; text-center"
                            @click.prevent="clientModalOpen = true"
                            >Add New Client?</a
                          >
                        </div>
                      </div>
                      <div class="col-2 px-1">
                        <button
                          type="button"
                          class="btn btn-primary ml-2"
                          @click="openPaymentModal"
                          :disabled="submitDisabled"
                        >
                          <span
                            ><span>Continue</span>&nbsp;<em
                              class="icon ni ni-arrow-right-circle"
                            ></em
                          ></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row g-gs" v-else>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="example-alert">
                          <div class="alert alert-pro alert-danger">
                            <div class="alert-text">
                              <h6>You haven't added an item</h6>
                              <p>
                                No items found in sales list. Search for product
                                and add to the list.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <client-creator
      @closed="clientModalOpen = false"
      @saved="handleSelectedClient"
      v-if="clientModalOpen"
    ></client-creator>
    <off-canvas
      v-if="modalOpen"
      @close="toggleModal"
      style="display: block"
      :modalSize="'default'"
      position="end"
    >
      <span slot="head">Payment Information</span>
      <div slot="body">
        <form class>
          <div class="form-group row align-items-center">
            <label class="col-sm-4 mt-0">Total Amount:</label>
            <div class="col-sm-8">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text px-2">{{
                    appSettings.currency
                  }}</span>
                </div>
                <input
                  type="number"
                  v-model="grandTotal"
                  class="form-control form-control-lg"
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="form-group row align-items-center">
            <label class="col-sm-4 mt-0">Discount</label>
            <div class="col-sm-4">
              <div class="input-group">
                <input
                  type="text"
                  v-model="newSales.discount"
                  @keyup="calculateDiscountedAmount"
                  class="form-control form-control-lg"
                />
                <div class="input-group-append">
                  <span class="input-group-text">%</span>
                </div>
              </div>
            </div>
            <div class="col-sm-4 pl-0">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text px-2">{{
                    appSettings.currency
                  }}</span>
                </div>
                <input
                  type="text"
                  v-model="newSales.discount_amount"
                  class="form-control form-control-lg"
                />
              </div>
            </div>
          </div>
          <div class="form-group row align-items-center">
            <label class="col-sm-4 mt-0">Discounted Amount:</label>
            <div class="col-sm-8">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text px-2">{{
                    appSettings.currency
                  }}</span>
                </div>
                <input
                  type="number"
                  v-model="newSales.discounted_total"
                  class="form-control form-control-lg"
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="form-group row align-items-center">
            <label class="col-sm-4 mt-0">Paid Amount:</label>
            <div class="col-sm-8">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text px-2">{{
                    appSettings.currency
                  }}</span>
                </div>
                <input
                  type="number"
                  @keyup="calculateAmountRemain"
                  v-model="newSales.amount_paid"
                  class="form-control form-control-lg"
                  placeholder="Directly paid"
                />
              </div>
            </div>
          </div>
          <div class="form-group row align-items-center">
            <label class="col-sm-4 mt-0">Remaining Amount:</label>
            <div class="col-sm-8">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text px-2">{{
                    appSettings.currency
                  }}</span>
                </div>
                <input
                  type="number"
                  v-model="newSales.amount_remain"
                  class="form-control form-control-lg"
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="form-group row align-items-center">
            <label class="col-sm-4 mt-0">Payment Method</label>
            <div class="col-sm-8">
              <select
                class="form-control form-control-lg"
                v-model="newSales.payment_method"
              >
                <option>CASH</option>
                <option>MOMO</option>
                <option>CHEQUE</option>
                <option>BANK TRANSFER</option>
              </select>
            </div>
          </div>
          <div class="form-group row align-items-center">
            <label class="col-sm-4 mt-0">Issued date</label>
            <div class="col-sm-8">
              <input
                type="date"
                v-model="newSales.committed_date"
                class="form-control form-control-lg"
                placeholder="Issued date"
              />
            </div>
          </div>
          <div
            class="form-group row align-items-center"
            v-if="newSales.payment_method != 'CHEQUEs'"
          >
            <label class="col-sm-4 mt-0">Payment date</label>
            <div class="col-sm-8">
              <input
                type="date"
                v-model="newSales.payment_date"
                class="form-control form-control-lg"
                placeholder="Payment date"
              />
            </div>
          </div>
          <div class="form-group row align-items-center">
            <label class="col-sm-4 mt-0">Comment</label>
            <div class="col-sm-8">
              <textarea
                v-model="newSales.comment"
                rows="3"
                class="form-control form-control-lg"
                style="height: auto !important"
                placeholder="Write some comment"
              ></textarea>
            </div>
          </div>
          <hr />
          <div class="mt-3 w-100">
            <wolf-button
              classes="btn btn-primary ml-2"
              @btn-role="handleSubmit"
              :disabler="submitDisabled"
              activator="saving_records"
            >
              <span slot="caption"
                ><em class="icon ni ni-save"></em
                ><span>Confirm and Save</span></span
              >
            </wolf-button>
          </div>
        </form>
      </div>
    </off-canvas>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import TypeHead from "@/components/TypeHead.vue";
import ClientCreator from "@/index/Shared/ClientCreator.vue";
export default {
  name: "Sales",
  components: { TypeHead, Multiselect, ClientCreator },
  data: () => ({
    items: [],
    rows: [],
    selectedItemIds: [],
    clientModalOpen: false,
    client: null,
    newItem: {
      product: null,
      quantity: 1,
    },
    newSales: {
      record_id: null,
      payment_method: "CASH",
      client: null,
      total_amount: 0,
      discounted_total: 0,
      committed_date: new Date()
        .toLocaleString("fr-CA", { timeZone: "Africa/Kigali" })
        .slice(0, 10),
      payment_date: new Date()
        .toLocaleString("fr-CA", { timeZone: "Africa/Kigali" })
        .slice(0, 10),
      amount_paid: 0,
      discount_amount: 0,
      comment: null,
      amount_remain: 0,
      discount: 0,
      type: "PRODUCTION",
    },
    record: {},
  }),
  computed: {
    submitDisabled() {
      return !this.newSales.client || !this.items.length;
    },
    grandTotal() {
      return this.items.reduce(
        (prev, cur) => prev + Number(cur.total_amount),
        0
      );
    },
  },
  beforeCreate() {
    this.$store
      .dispatch("getRequest", "production/sales/assets")
      .then((response) => {
        this.rows = response.data.rows;
      });
  },
  created() {
    let reference = this.$route.params.reference;
    if (reference) {
      this.$store
        .dispatch("getRequest", `sales/list/${reference}`)
        .then((response) => {
          let items = response.data.items;
          items.forEach((item) => {
            this.items.push({
              id: item.product.id,
              name: item.product.name,
              code: item.product.code,
              quantity: item.quantity,
              cost: item.product.cost_price || 0,
              price: item.price,
              total_amount: item.total_amount,
              current_qty: item.quantity,
              unit: item.product.unit,
            });
            this.selectedItemIds.push(item.product.id);
          });

          this.record = response.data.record;
          this.newSales.record_id = this.record.id;
          this.newSales.client = this.record.client;
          this.newSales.discount = this.record.client.discount || 0;
          this.newSales.committed_date = new Date(this.record.committed_date)
            .toLocaleString("fr-CA", { timeZone: "Africa/Kigali" })
            .slice(0, 10);
          this.newSales.payment_date = new Date(this.record.payment_date)
            .toLocaleString("fr-CA", { timeZone: "Africa/Kigali" })
            .slice(0, 10);
        });
    }
  },
  methods: {
    resetClient() {
      this.newSales.client = null;
      this.newSales.discount = 0;
    },
    handleSelectedClient(client) {
      this.newSales.client = client;
      this.newSales.discount = client.discount || 0;
      this.clientModalOpen = false;
    },
    removeItem(key) {
      this.items.splice(key, 1);
      this.selectedItemIds.splice(key, 1);
    },
    handleSelectedItem() {
      var item = this.newItem.product;
      if (
        this.selectedItemIds.includes(item.id) ||
        this.empty(this.newItem.product)
      ) {
        this.$notifier({
          type: "info",
          message: "Item already added to the list",
        });
      } else {
        this.items.push({
          id: item.id,
          name: item.name,
          code: item.code,
          quantity: this.newItem.quantity,
          cost: item.cost_price || 0,
          price: item.price || 0,
          total_amount: item.price * this.newItem.quantity,
          current_qty: item.quantity,
          unit: item.unit,
        });
        this.selectedItemIds.push(item.id);
      }
    },
    findTotalAmount(item) {
      item.total_amount = Number(item.price) * Number(item.quantity);
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.newSales.total_amount = this.grandTotal;
          this.newSales.items = this.items;
          this.$store
            .dispatch("postRequest", {
              url: this.empty(this.record) ? "sales/create" : "sales/edit",
              formData: this.formData(this.newSales),
            })
            .then((response) => {
              if (response.data.status) {
                this.clearObject(this.newSales);
                this.newSales = {
                  payment_method: "CASH",
                  client: null,
                  total_amount: 0,
                  discounted_total: 0,
                  committed_date: new Date()
                    .toLocaleString("fr-CA", { timeZone: "Africa/Kigali" })
                    .slice(0, 10),
                  payment_date: new Date()
                    .toLocaleString("fr-CA", { timeZone: "Africa/Kigali" })
                    .slice(0, 10),
                  amount_paid: 0,
                  discount_amount: 0,
                  comment: null,
                  amount_remain: 0,
                  discount: 0,
                  type: "PRODUCTION",
                };
                this.toggleModal();
                this.items = [];
                this.selectedItemIds = [];
                this.$store.commit("SET_FLASH_MESSAGE", {
                  type: "success",
                  message: response.data.message,
                });
                this.$router.replace({ name: "ProductionInvoices" });
              }
            })
            .catch((err) => console.log(err));
        }
      });
    },
    calculateDiscountedAmount() {
      let discountAmount =
        (Number(this.grandTotal) * Number(this.newSales.discount)) / 100;
      this.newSales.discount_amount = discountAmount;
      this.newSales.discounted_total =
        Number(this.grandTotal) - Number(discountAmount);
      this.newSales.amount_paid =
        Number(this.grandTotal) - Number(discountAmount);
      this.newSales.amount_remain =
        Number(this.newSales.discounted_total) -
        Number(this.newSales.amount_paid);
    },
    calculateAmountRemain() {
      this.newSales.amount_remain = (
        Number(this.newSales.discounted_total) -
        Number(this.newSales.amount_paid)
      ).toFixed(3);
    },
    openPaymentModal() {
      this.calculateDiscountedAmount();
      this.toggleModal();
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
a.cart-alt {
  color: #232323;
  display: block;
  padding: 21px 15px;
  position: relative;
}
a.cart-alt span.badge {
  position: absolute;
  top: 50%;
  margin-top: -20px;
  right: 5px;
  font-size: 10px;
  padding: 2px 0 0 1px;
  width: 20px;
  height: 21px;
  color: #fff;
  border-radius: 18px;
  line-height: 15px;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  white-space: nowrap;
}
</style>
