<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="wide-xl mx-auto">
          <div class="card card-stretch p-4">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">Stock Transfer</h3>
                    <div class="nk-block-des text-soft">
                      <!-- <p>You have total {{ totalRows }} products</p> -->
                    </div>
                  </div>
                  <div class="nk-block-head-content w-25">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="javascript:void(0)"
                        class="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                        ><em class="icon ni ni-more-v"></em
                      ></a>
                      <div
                        class="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul class="nk-block-tools g-3">
                          <li class="w-100" v-if="!isRequisition">
                            <div class="form-control-wrap w-100">
                              <type-head
                                url="stock/products/search"
                                label="name"
                                tracker="name"
                                placeholder="Search by item name"
                                @changed="handleSelectedItem"
                              ></type-head>
                              <auto-complete
                                v-if="false"
                                :tracks="rows"
                                label="name"
                                placeholder="Search by item name"
                                value="id"
                                @selected="handleSelectedItem"
                              ></auto-complete>
                            </div>
                          </li>
                          <li class="nk-block-tools-opt" v-if="false">
                            <button
                              type="button"
                              class="btn btn-icon btn-primary d-md-none"
                            >
                              <em class="icon ni ni-plus"></em>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="items.length > 0">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Name</span></div>
                    <div class="nk-tb-col"><span>Code</span></div>
                    <div class="nk-tb-col"><span>Cost Price</span></div>
                    <div class="nk-tb-col"><span>Qty In</span></div>
                    <div class="nk-tb-col"><span>Requested. Qty</span></div>
                    <div class="nk-tb-col"><span>Total Amount</span></div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>
                  <div
                    class="nk-tb-item"
                    v-for="(product, i) in items"
                    :key="'product' + i"
                  >
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ i + 1 }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ product.name }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">#{{ product.code }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title"
                          >{{ appSettings.currency }}
                          {{ addComma(product.price) }}</span
                        ></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span>{{ addComma(product.current_qty) }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <input
                        type="number"
                        :name="'Quantity' + i"
                        v-model="items[i].quantity"
                        :class="{ 'is-invalid': errors.has('Quantity' + i) }"
                        v-validate="
                          'required|max_value:' + items[i].current_qty
                        "
                        class="form-control"
                        placeholder="Quantity"
                        @keyup="findTotalAmount(product)"
                      />
                      <span
                        class="text-danger"
                        v-if="errors.has('Quantity' + i)"
                        >Quantity is required and <br />
                        must be less or equal to
                        {{ items[i].current_qty }}
                      </span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-sub">
                        <span
                          >{{ appSettings.currency }}
                          {{ addComma(items[i].total_amount) }}</span
                        >
                      </span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <a
                        href="javascript:void(0)"
                        @click.prevent="removeItem(i)"
                        ><em class="icon ni ni-trash text-danger"></em
                        ><span></span
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="w-100" v-if="items.length > 0">
                  <hr class="preview-hr" />
                  <div class="float-right w-25">
                    <h6
                      class="d-flex mb-3 align-items-center justify-content-end"
                    >
                      Grand Total:
                      <span class="ml-auto"
                        >{{ appSettings.currency }}
                        {{ addComma(grandTotal) }}</span
                      >
                    </h6>
                  </div>
                  <div class="w-100">
                    <div
                      class="
                        d-flex
                        justify-content-end
                        align-items-center
                        actions
                        w-100
                      "
                    >
                      <div class="col-3 px-1">
                        <multiselect
                          v-if="requisition.type != 'PRODUCTION'"
                          v-model="branch"
                          :options="branches"
                          placeholder="Select for branch"
                          label="name"
                          track-by="name"
                        ></multiselect>
                        <multiselect
                          v-else
                          v-model="source"
                          :options="sources"
                          placeholder="Select source"
                          label="name"
                          track-by="name"
                        ></multiselect>
                      </div>
                      <div class="form-group col-3 mb-0 px-1">
                        <input
                          type="text"
                          v-model="transporter"
                          class="form-control form-control-lg"
                          placeholder="Employee Name"
                        />
                      </div>
                      <div class="form-group col-3 mb-0 px-1">
                        <input
                          type="date"
                          v-model="delivery_date"
                          class="form-control form-control-lg"
                          placeholder="Delivery Date"
                        />
                      </div>
                      <wolf-button
                        classes="btn btn-primary ml-2"
                        @btn-role="handleSubmit"
                        :disabler="submitDisabled || !transporter"
                        activator="saving_records"
                      >
                        <span slot="caption"
                          ><em class="icon ni ni-save"></em
                          ><span>Confirm and Save</span></span
                        >
                      </wolf-button>
                    </div>
                  </div>
                </div>
                <div class="row g-gs" v-else>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="example-alert">
                          <div class="alert alert-pro alert-danger">
                            <div class="alert-text">
                              <h6>You haven't added an item</h6>
                              <p>
                                No items found in Stock Transfer list. Search
                                for product and add to the list.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AutoComplete from "@/components/AutoComplete.vue";
import TypeHead from "@/components/TypeHead.vue";
import Multiselect from "vue-multiselect";
import { mapState } from "vuex";
export default {
  name: "Deliver",
  components: { AutoComplete, Multiselect, TypeHead },
  data: () => ({
    items: [],
    rows: [],
    selectedItemIds: [],
    sources: [],
    branch: null,
    source: null,
    transporter: null,
    object_id: null,
    object_type: null,
    requisition: {},
    delivery_date: new Date()
      .toLocaleString("fr-CA", { timeZone: "Africa/Kigali" })
      .slice(0, 10),
    record: {},
    editingMode: false,
  }),
  computed: {
    submitDisabled() {
      return (
        (!this.branch && !this.source) ||
        !this.items.length ||
        this.items.some((item) => !item.current_qty)
      );
    },
    isRequisition() {
      return this.object_type == "REQUISITION";
    },
    grandTotal() {
      return this.items.reduce(
        (prev, cur) => prev + Number(cur.total_amount),
        0
      );
    },
    ...mapState({
      branches: (state) => state.branches,
    }),
  },
  beforeCreate() {
    let reference = this.$route.params.reference;
    if (reference) {
      this.$store
        .dispatch("getRequest", `requisitions/items/${reference}`)
        .then((response) => {
          let items = response.data.items;
          items.forEach((item) => {
            this.items.push({
              id: item.product_id,
              name: item.product.name,
              code: item.product.code,
              quantity: item.requested_quantity,
              total_amount:
                (item.product.cost_price || item.product.cost) *
                item.requested_quantity,
              price: item.product.cost_price || item.product.cost,
              current_qty:
                item.product.status == "PRODUCTION"
                  ? item.stock_quantity
                  : item.product.quantity,
            });
          });

          let requisition = response.data.requisition;
          this.requisition = requisition;
          this.object_type = "REQUISITION";
          this.object_id = requisition.id;
          this.branch = requisition.branch;
          this.source = requisition.source;
          this.transporter = requisition.initiator;
        });
    } else {
      let action = this.$route.query.action;
      let reference = this.$route.query.reference;
      if (typeof action == "string" && action == "edit") {
        this.editingMode = true;
        localStorage.removeItem("deliveryItems");
        this.$store
          .dispatch("getRequest", `/reports/transfer/${reference}/voucher`)
          .then((response) => {
            let items = response.data.items;
            this.record = response.data.record;
            this.branch = this.record.branch;
            this.source = this.record.source;
            this.transporter = this.record.transporter;
            this.delivery_date = new Date(this.record.delivery_date)
              .toLocaleString("fr-CA", { timeZone: "Africa/Kigali" })
              .slice(0, 10);
            items.forEach((item) => {
              this.items.push({
                id: item.product.id,
                name: item.product.name,
                code: item.product.code,
                quantity: item.quantity,
                total_amount: item.quantity * item.price,
                price: item.price,
                current_qty: item.product.quantity,
              });
              this.selectedItemIds.push(item.product.id);
            });
          });
      }
    }
  },
  created() {
    let reference = this.$route.params.reference;
    if (!reference) {
      let cart = localStorage.getItem("deliveryItems");
      if (cart) {
        let result = JSON.parse(cart);
        this.items = result.items;
        this.selectedItemIds = result.selectedItemIds;
      }
    } else localStorage.removeItem("deliveryItems");
    let action = this.$route.query.action;
    this.editingMode = typeof action == "string" && action == "edit";
  },
  beforeDestroy() {
    if (!this.editingMode) {
      if (this.items.length) {
        localStorage.setItem(
          "deliveryItems",
          JSON.stringify({
            items: this.items,
            selectedItemIds: this.selectedItemIds,
          })
        );
      }
    }
  },
  watch: {
    items() {
      if (!this.items.length) {
        localStorage.removeItem("deliveryItems");
      }
    },
  },
  methods: {
    removeItem(key) {
      this.items.splice(key, 1);
      this.selectedItemIds.splice(key, 1);
      let reference = this.$route.params.reference;
      if (!reference) {
        localStorage.setItem(
          "deliveryItems",
          JSON.stringify({
            items: this.items,
            selectedItemIds: this.selectedItemIds,
          })
        );
      }
    },
    handleSelectedItem(item) {
      if (!this.selectedItemIds.includes(item.id)) {
        if (item.quantity == 0)
          this.$notifier({
            type: "warning",
            message: "Item Quantity is 0",
          });
        else {
          this.items.push({
            id: item.id,
            name: item.name,
            code: item.code,
            quantity: 1,
            total_amount: item.cost_price || item.cost,
            price: item.cost || 0,
            current_qty: item.quantity,
          });
          this.selectedItemIds.push(item.id);
        }
      } else {
        this.$notifier({
          type: "info",
          message: "Item already added to the list",
        });
      }
    },
    findTotalAmount(item) {
      item.total_amount = Number(item.price) * Number(item.quantity);
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          var source = null,
            branch = null;
          if (!this.empty(this.branch)) branch = this.branch.id;
          if (!this.empty(this.source)) source = this.source.id;
          this.$store
            .dispatch("postRequest", {
              url: "stock/products/deliver",
              formData: this.formData({
                branch: branch,
                source: source,
                items: this.items,
                delivery_date: this.delivery_date,
                transporter: this.transporter,
                object_id: this.object_id,
                object_type: this.object_type,
                requisition_id: this.requisition.id || null,
                record: this.record.id || null,
                record_items: this.selectedItemIds,
              }),
            })
            .then((response) => {
              if (response.data.status) {
                this.$store.commit("SET_FLASH_MESSAGE", {
                  type: "success",
                  message: response.data.message,
                });
                localStorage.removeItem("deliveryItems");
                let action = this.$route.query.action;
                if (typeof action == "string" && action == "edit") {
                  this.$router.replace({ name: "TransferReport" });
                } else {
                  if (!this.empty(this.requisition)) {
                    this.$router.replace({ name: "Requests" });
                    this.$store.commit("REDUCE_NOTIFICATIONS", "requisitions");
                  } else {
                    this.items = [];
                    this.branch = {};
                    this.source = {};
                    location.reload();
                  }
                }
              }
            })
            .catch((err) => console.log(err));
        }
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
