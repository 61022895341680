<template>
  <div class="col-xxl-3 col-md-6">
    <div class="card card-full overflow-hidden card-bordered">
      <div class="nk-ecwg nk-ecwg7 h-100">
        <div class="card-inner flex-grow-1">
          <div class="card-title-group mb-4">
            <div class="card-title">
              <h6 class="title">Purchase Orders Chart</h6>
            </div>
          </div>
          <div class="nk-ecwg7-ck">
            <Doughnut
              ref="receivesTransfersChart"
              :chart-data="chartData"
              :options="options"
            />
          </div>
          <ul class="nk-ecwg7-legends">
            <li>
              <div class="title">
                <span
                  class="dot dot-lg sq"
                  style="background-color: #816bff"
                ></span
                ><span>Completed</span>
              </div>
            </li>
            <li>
              <div class="title">
                <span
                  class="dot dot-lg sq"
                  style="background-color: #13c9f2"
                ></span
                ><span>Pending</span>
              </div>
            </li>
            <li>
              <div class="title">
                <span
                  class="dot dot-lg sq"
                  style="background-color: #ff82b7"
                ></span
                ><span>Cancelled</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Doughnut from "@/components/Doughnut";
export default {
  name: "PurchaseOrdersChart",
  props: ["records"],
  components: { Doughnut },
  data: () => ({
    options: {
      legend: {
        display: false,
        rtl: false,
        labels: { boxWidth: 12, padding: 20, fontColor: "#6783b8" },
      },
      rotation: -1.5,
      cutoutPercentage: 70,
      maintainAspectRatio: false,
      tooltips: {
        enabled: true,
        rtl: false,
        callbacks: {
          title: function (a, t) {
            return t.labels[a[0].index];
          },
          label: function (a, t) {
            return t.datasets[a.datasetIndex].data[a.index] + " $";
          },
        },
        backgroundColor: "#1c2b46",
        titleFontSize: 13,
        titleFontColor: "#fff",
        titleMarginBottom: 6,
        bodyFontColor: "#fff",
        bodyFontSize: 12,
        bodySpacing: 4,
        yPadding: 10,
        xPadding: 10,
        footerMarginTop: 0,
        displayColors: false,
      },
    },
  }),
  computed: {
    chartData() {
      return {
        labels: ["Completed", "Pending", "Cancelled"],
        dataUnit: this.appSettings.currency,
        legend: false,
        datasets: [
          {
            borderColor: "#fff",
            hoverBorderColor: "#fff",
            backgroundColor: ["#816bff", "#13c9f2", "#ff82b7"],
            borderWidth: 2,
            data: this.records,
          },
        ],
      };
    },
  },
};
</script>
<style>
.nk-ecwg7-ck div {
  height: 100%;
  max-height: 100%;
}
</style>
