<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch p-4">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">
                      List of all suppliers
                    </h3>
                    <div class="nk-block-des text-soft">
                      <p>You have total {{ totalRows }} suppliers.</p>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="javascript:void(0)"
                        class="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="more-options"
                        ><em class="icon ni ni-more-v"></em
                      ></a>
                      <div
                        class="toggle-expand-content"
                        data-content="more-options"
                      >
                        <ul class="nk-block-tools g-3">
                          <li>
                            <div class="form-control-wrap">
                              <div class="form-icon form-icon-right">
                                <em class="icon ni ni-search"></em>
                              </div>
                              <input
                                type="text"
                                class="form-control"
                                id="default-04"
                                placeholder="Search by name"
                                v-model="filterKey"
                                @keyup="handlePaginationSearch"
                              />
                            </div>
                          </li>
                          <li v-if="false">
                            <div class="dropdown">
                              <a
                                href="javascript:void(0)"
                                class="
                                  dropdown-toggle dropdown-indicator
                                  btn btn-outline-light btn-white
                                "
                                data-toggle="dropdown"
                                >Status</a
                              >
                              <div class="dropdown-menu dropdown-menu-right">
                                <ul class="link-list-opt no-bdr">
                                  <li>
                                    <a href="javascript:void(0)"
                                      ><span>Actived</span></a
                                    >
                                  </li>
                                  <li>
                                    <a href="javascript:void(0)"
                                      ><span>Disabled</span></a
                                    >
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </li>
                          <li class="nk-block-tools-opt">
                            <a
                              href="javascript:void(0)"
                              @click.prevent="toggleModal"
                              class="btn btn-icon btn-primary d-md-none"
                              ><em class="icon ni ni-plus"></em></a
                            ><a
                              href="javascript:void(0)"
                              @click.prevent="toggleModal"
                              class="btn btn-primary d-none d-md-inline-flex"
                              ><em class="icon ni ni-plus"></em
                              ><span>Add</span></a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="totalRows > 0">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col nk-tb-col-check">
                      <div
                        class="
                          custom-control custom-control-sm custom-checkbox
                          notext
                        "
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="uid"
                        /><label class="custom-control-label" for="uid"></label>
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Code</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Business Name</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Category</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Phone</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Address</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Created By</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Status</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1 my-n1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="
                                dropdown-toggle
                                btn btn-icon btn-trigger
                                mr-n1
                              "
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <a href="javascript:void(0)"
                                    ><em class="icon ni ni-na"></em
                                    ><span>Disable Selected</span></a
                                  >
                                </li>
                                <li>
                                  <a href="javascript:void(0)"
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Remove Seleted</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(supplier, i) in rows"
                    :key="'supplier' + i"
                  >
                    <div class="nk-tb-col nk-tb-col-check">
                      <div
                        class="
                          custom-control custom-control-sm custom-checkbox
                          notext
                        "
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="uid1"
                        /><label
                          class="custom-control-label"
                          for="uid1"
                        ></label>
                      </div>
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount"
                        >#{{ supplier.code || "N/A" }}</span
                      >
                    </div>

                    <div class="nk-tb-col">
                      <div class="user-card">
                        <avatar :name="`${supplier.name}`"></avatar>
                        <div class="user-info">
                          <span class="lead-text">{{ `${supplier.name}` }}</span
                          ><span class="sub-text">{{
                            supplier.email || "N/A"
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <span>{{ supplier.type || "N/A" }}</span>
                    </div>

                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount">{{
                        supplier.phone || "N/A"
                      }}</span>
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount">{{
                        supplier.address || "N/A"
                      }}</span>
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount">{{
                        supplier.created_by || "N/A"
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span
                        class="tb-status text-success"
                        v-if="supplier.status == 1"
                        >Active</span
                      >
                      <span
                        class="tb-status text-danger"
                        v-if="supplier.status == 0"
                        >Disabled</span
                      >
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger"
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="handleEdit(supplier, i)"
                                    ><em class="icon ni ni-edit"></em
                                    ><span>Edit Supplier</span></a
                                  >
                                </li>
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      handleDeleteAlertModal({
                                        tableName: 'suppliers',
                                        entityIdentifer: 'Supplier',
                                        entity: `${supplier.name || ''}`,
                                        url: `settings/suppliers/delete/${supplier.id}`,
                                        callback: () => {
                                          rows.splice(i, 1);
                                        },
                                      })
                                    "
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Remove</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row g-gs" v-else>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="example-alert">
                          <div class="alert alert-fill alert-danger alert-icon">
                            <em class="icon ni ni-cross-circle"></em>
                            <strong>No Suppliers found</strong>! Please check
                            your search keywords or add new supplier.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <supplier-creator
      @closed="toggleModal"
      @saved="handleAddedSupplier"
      v-if="modalOpen"
      :supplier="supplier"
    ></supplier-creator>
  </section>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
import SupplierCreator from "@/index/Shared/SupplierCreator.vue";
import Avatar from "@/components/Avatar";
export default {
  name: "Suppliers",
  mixins: [dashboardServices],
  components: {
    SupplierCreator,
    Avatar,
  },
  data: () => ({
    fetchUrl: "settings/suppliers",
    supplier: {},
    selectedIndex: null,
  }),

  methods: {
    handleAddedSupplier(supplier) {
      if (this.selectedIndex != null) {
        this.rows[this.selectedIndex] = supplier;
        this.supplier = {};
        this.selectedIndex = null;
      } else {
        this.rows = [...[supplier], ...this.rows];
      }
      this.toggleModal();
    },
    handleEdit(supplier, i) {
      this.supplier = supplier;
      this.selectedIndex = i;
      this.toggleModal();
    },
  },
};
</script>
