<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch p-4">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">
                      Purchase Orders Report
                    </h3>
                    <div class="nk-block-des text-soft">
                      <p>You have total {{ totalRows }} requests.</p>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="javascript:void(0)"
                        class="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="more-options"
                        ><em class="icon ni ni-more-v"></em
                      ></a>
                      <div
                        class="toggle-expand-content"
                        data-content="more-options"
                      >
                        <ul class="nk-block-tools g-3">
                          <li>
                            <div class="form-control-wrap">
                              <div class="form-icon form-icon-right">
                                <em class="icon ni ni-search"></em>
                              </div>
                              <input
                                type="text"
                                class="form-control"
                                id="default-04"
                                placeholder="Search by reference"
                              />
                            </div>
                          </li>
                          <li v-if="false">
                            <div class="dropdown">
                              <a
                                href="javascript:void(0)"
                                class="
                                  dropdown-toggle dropdown-indicator
                                  btn btn-outline-light btn-white
                                "
                                data-toggle="dropdown"
                                >Status</a
                              >
                              <div class="dropdown-menu dropdown-menu-right">
                                <ul class="link-list-opt no-bdr">
                                  <li>
                                    <a href="javascript:void(0)"
                                      ><span>Actived</span></a
                                    >
                                  </li>
                                  <li>
                                    <a href="javascript:void(0)"
                                      ><span>Disabled</span></a
                                    >
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </li>
                          <li class="nk-block-tools-opt">
                            <router-link
                              :to="{ name: 'PurchaseOrders' }"
                              class="btn btn-primary d-none d-md-inline-flex"
                              ><em class="icon ni ni-plus"></em
                              ><span>Create New</span></router-link
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="totalRows > 0">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col nk-tb-col-check">
                      <div
                        class="
                          custom-control custom-control-sm custom-checkbox
                          notext
                        "
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="uid"
                        /><label class="custom-control-label" for="uid"></label>
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Order Code</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Date</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Supplier</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Tot. Items</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Total Amount</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Status</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Created By</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1 my-n1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="
                                dropdown-toggle
                                btn btn-icon btn-trigger
                                mr-n1
                              "
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <a href="javascript:void(0)"
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Remove Seleted</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(item, i) in rows"
                    :key="'item' + i"
                  >
                    <div class="nk-tb-col nk-tb-col-check">
                      <div
                        class="
                          custom-control custom-control-sm custom-checkbox
                          notext
                        "
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="uid1"
                        /><label
                          class="custom-control-label"
                          for="uid1"
                        ></label>
                      </div>
                    </div>
                    <div class="nk-tb-colmb">
                      <span class="tb-amount">#{{ item.code || "N/A" }}</span>
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount">{{
                        chatDate(item.purchase_date)
                      }}</span>
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount" v-if="!empty(item.vendor)">{{
                        item.vendor.name || "N/A"
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span>{{ addComma(item.total_items) }}</span>
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount"
                        >{{ appSettings.currency }}
                        {{ addComma(item.total_amount) || "0" }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span
                        class="tb-status text-warning"
                        v-if="item.status == 'PENDING'"
                        >Pending</span
                      >
                      <span
                        class="tb-status text-success"
                        v-if="item.status == 'PURCHASED'"
                        >Purchased</span
                      >
                      <span
                        class="tb-status text-danger"
                        v-if="item.status == 'CANCELLED'"
                        >Cancelled</span
                      >
                    </div>
                    <div class="nk-tb-col tb-col-mb">
                      <span class="tb-amount">{{
                        item.creator.name || "N/A"
                      }}</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger"
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      getOrderItems(item.reference)
                                    "
                                    ><em class="icon ni ni-eye"></em
                                    ><span>View Details</span></a
                                  >
                                </li>
                                <li>
                                  <router-link
                                    :to="{
                                      name: 'OrdersInvoice',
                                      params: { reference: item.reference },
                                    }"
                                    ><em class="icon ni ni-file-text"></em
                                    ><span>Invoice</span></router-link
                                  >
                                </li>
                                <template v-if="item.status == 'PENDING'">
                                  <li>
                                    <router-link
                                      :to="{
                                        name: 'ProductReceive',
                                        params: { reference: item.reference },
                                      }"
                                      ><em class="icon ni ni-send"></em
                                      ><span>Receive</span></router-link
                                    >
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0)"
                                      @click.prevent="
                                        $alert({
                                          title: 'Cancelling purchase order',
                                          content:
                                            'Are you sure you want to cancel this purchase order',
                                          classes: 'btn btn-danger',
                                          actionText: 'Continue',
                                          closeText: 'Discard',
                                          action: () => {
                                            cancelPurchaseOrder(item);
                                          },
                                        })
                                      "
                                      ><em class="icon ni ni-na"></em
                                      ><span>Cancel</span></a
                                    >
                                  </li>
                                </template>
                                <li>
                                  <a
                                    href="javascript:void(0)"
                                    @click.prevent="
                                      handleDeleteAlertModal({
                                        tableName: 'items',
                                        entityIdentifer: 'Purchase Order',
                                        entity: `${item.reference || ''}`,
                                        url: `orders/items/delete/${item.id}`,
                                        callback: () => {
                                          rows.splice(i, 1);
                                        },
                                      })
                                    "
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Remove</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row g-gs" v-else>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="example-alert">
                          <div class="alert alert-fill alert-danger alert-icon">
                            <em class="icon ni ni-cross-circle"></em>
                            <strong>No Purchase ordersfound </strong>!
                            Please check your search keywords or place a new
                            order.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bootstrap-modal
      v-if="modalOpen"
      @close="toggleModal"
      style="display: block"
      :modalSize="'modal-lg'"
      :showFooter="false"
    >
      <span slot="head" style="padding-top: 10px"
        >Items On Purchase Order
        <a href="javascript:void(0)"> #{{ order.code }}</a></span
      >
      <div slot="body">
        <div class="table-responsive">
          <div class="card card-full">
            <div class="nk-tb-list mt-n2">
              <div class="nk-tb-item nk-tb-head">
                <div class="nk-tb-col"><span>Product #</span></div>
                <div class="nk-tb-col"><span>Name</span></div>
                <div class="nk-tb-col"><span>Type</span></div>
                <div class="nk-tb-col"><span>Cost Price</span></div>
                <div class="nk-tb-col"><span>Ordered Qty</span></div>
                <div class="nk-tb-col"><span>Amount</span></div>
                <div
                  class="nk-tb-col nk-tb-col-tools"
                  v-if="order.status == 'PENDING'"
                ></div>
              </div>
              <div
                class="nk-tb-item"
                v-for="(item, i) in items"
                :key="'item' + i"
              >
                <div class="nk-tb-col">
                  <span class="tb-lead"
                    ><a href="javascript:void(0)"
                      >#{{ item.product.code }}</a
                    ></span
                  >
                </div>
                <div class="nk-tb-col">
                  <div class="user-card">
                    <div class="user-name">
                      <span class="tb-lead">{{ item.product.name }}</span>
                    </div>
                  </div>
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub">{{ item.product.type }}</span>
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub"
                    >{{ appSettings.currency }} {{ addComma(item.price) }}</span
                  >
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub">{{ addComma(item.quantity) }}</span>
                </div>
                <div class="nk-tb-col">
                  <span class="tb-sub tb-amount"
                    ><span>{{ appSettings.currency }} </span
                    >{{ addComma(item.total_amount) }}</span
                  >
                </div>
                <div
                  class="nk-tb-col nk-tb-col-tools"
                  v-if="order.status == 'PENDING'"
                >
                  <a
                    href="javascript:void(0)"
                    class="text-danger"
                    @click.prevent="handleItemDeletion(item, i)"
                    ><em class="icon ni ni-trash text-danger"></em><span></span
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </bootstrap-modal>
  </section>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
export default {
  name: "PurchaseOrders",
  mixins: [dashboardServices],
  data: () => ({
    fetchUrl: "orders/report",
    items: [],
    order: {},
  }),
  methods: {
    cancelPurchaseOrder(item) {
      this.$store
        .dispatch("getRequest", `orders/items/${item.id}/cancel`)
        .then((response) => {
          if (response.data.status) {
            item.status = "CANCELLED";
          }
        });
    },
    handleItemDeletion(item, key) {
      this.$store.commit("SET_REQUEST_FLAG", "MODAL_REQUEST");
      this.$store
        .dispatch("getRequest", `orders/items/details/${item.id}/delete`)
        .then((response) => {
          if (response.data.status) {
            this.items.splice(key, 1);
          }
        });
    },
    getOrderItems(reference) {
      this.items = [];
      this.order = {};
      this.$store
        .dispatch("getRequest", `/orders/report/${reference}/details`)
        .then((response) => {
          this.items = response.data.items;
          this.order = response.data.order;
          this.toggleModal();
        });
    },
  },
};
</script>
