<template>
  <div class="container-fluid">
    <bootstrap-modal
      v-if="modalOpen"
      @close="toggleModal"
      style="display: block"
      :modalSize="'default'"
      :showFooter="false"
    >
      <span slot="head" v-if="!newBranch.id"> Add a new branch</span>
      <span slot="head" v-else> Edit branch</span>
      <div slot="body">
        <div class="row">
          <div class="col-12">
            <div class="form-group mb-3">
              <label class="form-label" for="name">Branch Name</label>
              <div class="form-control-wrap">
                <input
                  type="text"
                  v-model="newBranch.name"
                  class="form-control"
                  id="name"
                  placeholder="Name of the competition"
                />
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group mb-3">
              <label class="form-label" for="location">Location</label>
              <div class="form-control-wrap">
                <input
                  type="text"
                  v-model="newBranch.location"
                  class="form-control"
                  id="location"
                  placeholder="Branch Address"
                />
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group mb-3">
              <label class="form-label" for="phone">Phone number</label>
              <div class="form-control-wrap">
                <input
                  type="text"
                  v-model="newBranch.phone"
                  class="form-control"
                  id="phone"
                  placeholder="Phone Number"
                />
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group mb-3">
              <label class="form-label" for="description">Description</label>
              <div class="form-control-wrap">
                <textarea
                  v-model="newBranch.description"
                  class="form-control"
                  id="description"
                  placeholder="Optional comments"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group mb-3">
              <wolf-button
                :caption="newBranch.id ? 'Saved Changes' : 'Save and Close'"
                @btn-role="handleSubmit"
                :disabler="!newBranch.name"
                activator="saving_user"
                classes="btn btn-primary"
              ></wolf-button>
            </div>
          </div>
        </div>
      </div>
    </bootstrap-modal>
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="nk-block-head nk-block-head-sm">
          <div class="nk-block-between g-3">
            <div class="nk-block-head-content">
              <h3 class="nk-block-title page-title">Branches List</h3>
              <div class="nk-block-des text-soft">
                <p>You have total {{ branches.length }} Branches.</p>
              </div>
            </div>
            <div class="nk-block-head-content">
              <ul class="nk-block-tools g-3">
                <li>
                  <div class="form-control-wrap">
                    <div class="form-icon form-icon-right">
                      <em class="icon ni ni-search"></em>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      id="default-04"
                      placeholder="Quick search by name"
                    />
                  </div>
                </li>
                <li>
                  <div class="drodown">
                    <a
                      href="javascript:void(0)"
                      class="dropdown-toggle btn btn-icon btn-primary"
                      @click.prevent="toggleModal"
                      ><em class="icon ni ni-plus"></em
                    ></a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="nk-block nk-block-lg">
          <div class="row g-gs" v-if="branches.length">
            <div
              class="col-sm-6 col-lg-4 col-xxl-3"
              v-for="(branch, i) in branches"
              :key="'branch' + i"
            >
              <div class="card card-bordered shadow">
                <div class="card-inner">
                  <div class="team">
                    <div class="team-status bg-light text-black">
                      <em class="icon ni ni-check-thick"></em>
                    </div>
                    <div class="team-options">
                      <div class="drodown">
                        <a
                          href="#"
                          class="
                            dropdown-toggle
                            btn btn-sm btn-icon btn-trigger
                          "
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><em class="icon ni ni-more-h"></em
                        ></a>
                        <div class="dropdown-menu dropdown-menu-end" style="">
                          <ul class="link-list-opt no-bdr">
                            <li>
                              <a
                                href="javascript:void(0)"
                                @click.prevent="handleEdit(branch)"
                                ><em class="icon ni ni-edit"></em
                                ><span>Edit</span></a
                              >
                            </li>
                            <li>
                              <a href="javascript:void(0)"
                                ><em class="icon ni ni-focus"></em
                                ><span>Quick View</span></a
                              >
                            </li>
                            <li>
                              <a href="javascript:void(0)"
                                ><em class="icon ni ni-eye"></em
                                ><span>View Sales</span></a
                              >
                            </li>
                            <li>
                              <router-link :to="{ name: 'CreateRequest' }"
                                ><em class="icon ni ni-arrow-right-round"></em
                                ><span>Create Requisition</span></router-link
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="user-card user-card-s2">
                      <div class="user-avatar lg bg-transparen">
                        <img
                          :src="`${publicPath}img/icons8_company_500px.png`"
                          alt=""
                        />
                        <div class="status dot dot-lg dot-success"></div>
                      </div>
                      <div class="user-info">
                        <h6>{{ branch.name }}</h6>
                        <span class="sub-text"
                          ><em class="icon ni ni-call"></em>
                          {{ branch.phone || "N/A" }}</span
                        >
                        <span class="sub-text"
                          ><em class="icon ni ni-location"></em>
                          {{ branch.location || "N/A" }}</span
                        >
                      </div>
                    </div>
                    <div class="team-details">
                      <p class="two-line">{{ branch.description }}</p>
                    </div>
                    <ul class="team-statistics">
                      <li>
                        <span>{{
                          `${appSettings.currency} ${addComma(
                            Math.round(branch.receives)
                          )}`
                        }}</span
                        ><span>Receiving</span>
                      </li>
                      <li><span>0</span><span>Total Sells</span></li>
                      <li>
                        <span>{{
                          `${appSettings.currency} ${addComma(
                            Math.round(branch.requisitions)
                          )}`
                        }}</span
                        ><span>Requisitions</span>
                      </li>
                    </ul>
                    <div class="team-view">
                      <router-link
                        :to="{ name: '' }"
                        class="btn btn-round btn-outline-light w-150px"
                        ><span>View Dashboard</span></router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-lg-12">
              <div class="example-alert">
                <div class="alert alert-pro alert-danger">
                  <div class="alert-text">
                    <h6>Not records found</h6>
                    <p>
                      We couldn't find any branch. Change your search key or
                      create a new one.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Index",
  data: () => ({
    branches: [],
    newBranch: {
      name: null,
      description: null,
      location: null,
      phone: null,
    },
  }),
  created() {
    this.$store.dispatch("getRequest", "/branches").then((response) => {
      if (response.data.status) {
        this.branches = response.data.branches;
      }
    });
  },
  methods: {
    handleEdit(branch) {
      this.newBranch = branch;
      this.toggleModal();
    },
    handleSubmit() {
      let url = this.newBranch.id ? "branches/update" : "branches/create";
      this.$store
        .dispatch("postRequest", {
          formData: this.formData(this.newBranch),
          url,
        })
        .then((response) => {
          if (response.data.status) {
            location.reload();
            this.branches = [...[response.data.branch], ...this.branches];
            this.clearObject(this.newBranch);
            //this.toggleModal();
          }
        });
    },
  },
};
</script>
<style scoped>
.sub-text {
  font-size: 18px;
}
</style>
