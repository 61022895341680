<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch p-4">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">
                      Production Stock Report
                      <small>
                        <a href="javascript:void(0)">({{ reportLabel }})</a>
                      </small>
                    </h3>
                    <div class="nk-block-des text-soft">
                      <!-- <p>You have total {{ totalRows }} requests.</p> -->
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="javascript:void(0)"
                        class="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="more-options"
                        ><em class="icon ni ni-more-v"></em
                      ></a>
                      <div
                        class="toggle-expand-content"
                        data-content="more-options"
                      >
                        <ul class="nk-block-tools g-3">
                          <li>
                            <div class="dropdown filter-dropdown">
                              <a
                                href="#"
                                class="
                                  dropdown-toggle
                                  btn btn-white btn-dim btn-outline-light
                                "
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                ><em
                                  class="
                                    d-none d-sm-inline
                                    icon
                                    ni ni-filter-alt
                                  "
                                ></em
                                ><span>Filtered By</span
                                ><em
                                  class="dd-indc icon ni ni-chevron-right"
                                ></em
                              ></a>
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                style=""
                              >
                                <div class="p-3" style="width: 400px">
                                  <div class="row w-100">
                                    <div class="col-lg-12">
                                      <h5 class="mb-3">Filter Results</h5>
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-control-wrap mb-2">
                                        <div class="form-icon form-icon-right">
                                          <em class="icon ni ni-search"></em>
                                        </div>
                                        <input
                                          type="search"
                                          class="form-control"
                                          id="default-04"
                                          placeholder="Search by product name"
                                          v-model="filterKey"
                                          @keyup="handlePaginationSearch"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <select
                                          class="form-control form-select"
                                          v-model="newFilter.type"
                                        >
                                          <option value="all">All Items</option>
                                          <!-- <option value="PRODUCTION">
                                            Produnc Items
                                          </option>
                                          <option value="STORABLE">
                                            Storable Items
                                          </option> -->
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <select
                                          class="form-control form-select"
                                          v-model="newFilter.preference"
                                        >
                                          <option>FIGURES</option>
                                          <option>VALUES</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.from"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group mb-2">
                                        <input
                                          type="date"
                                          class="form-control"
                                          v-model="newFilter.to"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-lg-12">
                                      <div class="form-group text-right">
                                        <button
                                          type="button"
                                          class="
                                            btn btn-primary
                                            d-none d-md-inline-flex
                                          "
                                          :disabled="empty(newFilter)"
                                          @click="handlePaginationFilter"
                                        >
                                          <em class="icon ni ni-filter"></em
                                          ><span>Filter</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="totalRows">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Item Name</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Item Type</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Cost Price</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Open. {{ preferenceLabel }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text"
                        >Produced. {{ preferenceLabel }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Used {{ preferenceLabel }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text"
                        >Spoiled {{ preferenceLabel }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Close. {{ preferenceLabel }}</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1 my-n1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="
                                dropdown-toggle
                                btn btn-icon btn-trigger
                                mr-n1
                              "
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <a href="javascript:void(0)"
                                    ><em class="icon ni ni-trash"></em
                                    ><span>Remove Seleted</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    class="nk-tb-item"
                    v-for="(item, i) in rows"
                    :key="'item' + i"
                  >
                    <div class="nk-tb-col">
                      <span>{{ i + 1 }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{ item.name }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span>{{ item.type }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${appSettings.currency} ${addComma(item.cost_price)}`
                      }}</span>
                    </div>
                    <template v-if="preferenceLabel == 'Qty'">
                      <div class="nk-tb-col">
                        <span class="tb-amount">{{
                          `${addComma(item.open)} ${item.unit}`
                        }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-amount">{{
                          `${addComma(item.receivedItems)} ${item.unit}`
                        }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-amount">{{
                          `${addComma(item.transferredItems)} ${item.unit}`
                        }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-amount">{{
                          `${addComma(item.spoiledItems)} ${item.unit}`
                        }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-amount">{{
                          `${addComma(item.close)} ${item.unit}`
                        }}</span>
                      </div>
                    </template>
                    <template v-else>
                      <div class="nk-tb-col">
                        <span class="tb-amount">{{
                          `${appSettings.currency} ${addComma(
                            item.open * item.cost_price
                          )}`
                        }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-amount">{{
                          `${appSettings.currency} ${addComma(
                            item.receivedItems * item.cost_price
                          )}`
                        }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-amount">{{
                          `${appSettings.currency} ${addComma(
                            item.transferredItems * item.cost_price
                          )}`
                        }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-amount">{{
                          `${appSettings.currency} ${addComma(
                            item.spoiledItems * item.cost_price
                          )}`
                        }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-amount">{{
                          `${appSettings.currency} ${addComma(
                            item.close * item.cost_price
                          )}`
                        }}</span>
                      </div>
                    </template>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <div class="dropdown">
                            <a
                              href="javascript:void(0)"
                              class="dropdown-toggle btn btn-icon btn-trigger"
                              data-toggle="dropdown"
                              aria-expanded="false"
                              ><em class="icon ni ni-more-h"></em
                            ></a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              style=""
                            >
                              <ul class="link-list-opt no-bdr">
                                <li>
                                  <router-link
                                    :to="{
                                      name: 'ItemTracker',
                                      params: { code: item.code },
                                    }"
                                    ><em class="icon ni ni-eye"></em
                                    ><span>View Details</span></router-link
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="nk-tb-item stick-row">
                    <div class="nk-tb-col"><span></span></div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">Total Amount:</span>
                    </div>
                    <div class="nk-tb-col"><span></span></div>
                    <div class="nk-tb-col"><span></span></div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${appSettings.currency} ${addComma(totalOpen)}`
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${appSettings.currency} ${addComma(totalReceived)}`
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${appSettings.currency} ${addComma(totalTransferred)}`
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${appSettings.currency} ${addComma(totalSpoiled)}`
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${appSettings.currency} ${addComma(totalClose)}`
                      }}</span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>
                </div>
                <div class="row g-gs" v-else>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="example-alert">
                          <div class="alert alert-fill alert-danger alert-icon">
                            <em class="icon ni ni-cross-circle"></em>
                            <strong>No records found </strong>! Please check
                            your search keywords and try again
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
export default {
  name: "ProductionStockStatus",
  mixins: [dashboardServices],
  data: () => ({
    fetchUrl: "production/items/track",
    newFilter: {
      preference: "FIGURES",
    },
  }),
  computed: {
    preferenceLabel() {
      return this.newFilter.preference == "FIGURES" ? "Qty" : "Amount";
    },
    totalOpen() {
      return this.rows.reduce(
        (a, b) => a + Number(b.open) * Number(b.cost_price),
        0
      );
    },
    totalClose() {
      return this.rows.reduce(
        (a, b) => a + Number(b.close) * Number(b.cost_price),
        0
      );
    },
    totalReceived() {
      return this.rows.reduce(
        (a, b) => a + Number(b.receivedItems) * Number(b.cost_price),
        0
      );
    },
    totalTransferred() {
      return this.rows.reduce(
        (a, b) => a + Number(b.transferredItems) * Number(b.cost_price),
        0
      );
    },
    totalSpoiled() {
      return this.rows.reduce(
        (a, b) => a + Number(b.spoiledItems) * Number(b.cost_price),
        0
      );
    },
  },
  created() {
    this.newFilter.type = "all";
    this.newFilter.preference = "FIGURES";
  },
};
</script>
<style scoped>
.stick-row {
  position: sticky;
  bottom: 0px;
  z-index: 90;
  background: var(--base-color);
}
.stick-row:hover {
  background: var(--base-color);
}
.stick-row .nk-tb-col,
.stick-row .tb-amount {
  color: #fff;
  font-weight: 600;
  font-size: 1.2rem;
}
</style>
