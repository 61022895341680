<template>
  <div style="min-width: 250px">
    <multiselect
      v-model="selectedRecords"
      id="ajax"
      :label="label"
      :track-by="tracker"
      :placeholder="placeholder"
      open-direction="bottom"
      :options="records"
      :multiple="false"
      :searchable="true"
      :loading="isLoading"
      :internal-search="false"
      :clear-on-select="true"
      :close-on-select="true"
      :options-limit="300"
      :show-no-results="false"
      :hide-selected="false"
      @search-change="handleSearch"
      @select="$emit('changed', $event)"
    >
    </multiselect>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
export default {
  name: "TypeHead",
  props: {
    url: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    tracker: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    loadDefaults: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  components: {
    Multiselect,
  },
  data: () => ({
    selectedRecords: [],
    records: [],
    isLoading: false,
  }),
  created() {
    if (this.loadDefaults) {
      this.$store.commit("SET_REQUEST_FLAG", "AUTOCOMPLETE_REQUEST");
      this.$store.dispatch("getRequest", this.url).then((response) => {
        this.records = response.data || [];
        this.isLoading = false;
      });
    }
  },
  methods: {
    limitText(count) {
      return `and ${count} other records`;
    },
    handleSearch(query) {
      if (query.trim().length > 0) {
        this.isLoading = true;
        this.$store.commit("SET_REQUEST_FLAG", "AUTOCOMPLETE_REQUEST");
        this.$store
          .dispatch("getRequest", `${this.url}?query=${query}`)
          .then((response) => {
            this.records = response.data || [];
            this.isLoading = false;
          });
      }
    },
    handleChange(event) {
      this.$emit("changed", event);
    },
    clearAll() {
      this.selectedRecords = [];
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
