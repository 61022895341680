<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="wide-lg mx-auto">
          <div class="card card-stretch p-4">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">
                      Create Requisition
                    </h3>
                    <div class="nk-block-des text-soft">
                      <!-- <p>You have total {{ totalRows }} products</p> -->
                    </div>
                  </div>
                  <div class="nk-block-head-content w-25">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="javascript:void(0)"
                        class="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                        ><em class="icon ni ni-more-v"></em
                      ></a>
                      <div
                        class="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul class="nk-block-tools g-3">
                          <li class="w-100">
                            <div class="form-control-wrap w-100">
                              <auto-complete
                                :tracks="rows"
                                label="name"
                                placeholder="Search by item name"
                                value="id"
                                @selected="handleSelectedItem"
                              ></auto-complete>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="items.length > 0">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col nk-tb-col-check">
                      <div
                        class="
                          custom-control custom-control-sm custom-checkbox
                          notext
                        "
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="pid"
                        /><label class="custom-control-label" for="pid"></label>
                      </div>
                    </div>
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Name</span></div>
                    <div class="nk-tb-col"><span>Code</span></div>
                    <div class="nk-tb-col"><span>Type</span></div>
                    <div class="nk-tb-col"><span>Cost Price</span></div>
                    <div class="nk-tb-col"><span>Quantity</span></div>
                    <div class="nk-tb-col"><span>Total Amount</span></div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>
                  <div
                    class="nk-tb-item"
                    v-for="(product, i) in items"
                    :key="'product' + i"
                  >
                    <div class="nk-tb-col nk-tb-col-check">
                      <div
                        class="
                          custom-control custom-control-sm custom-checkbox
                          notext
                        "
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="pid1"
                        /><label
                          class="custom-control-label"
                          for="pid1"
                        ></label>
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ i + 1 }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ product.name }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">#{{ product.code }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ product.type }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span
                        >{{ appSettings.currency }}
                        {{ addComma(product.price) }}</span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <div class="form-control-wrap">
                        <div class="form-text-hint">
                          <span class="overline-title">{{ product.unit }}</span>
                        </div>
                        <input
                          type="number"
                          :name="'Quantity' + i"
                          v-model="items[i].quantity"
                          :class="{ 'is-invalid': errors.has('Quantity' + i) }"
                          v-validate="'required'"
                          class="form-control"
                          placeholder="Quantity"
                          @keyup="findTotalAmount(product)"
                        />
                        <span
                          class="text-danger"
                          v-if="errors.has('Quantity' + i)"
                          >Quantity is required
                        </span>
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-sub">
                        {{ appSettings.currency }}
                        {{ addComma(items[i].total_amount) }}
                      </span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <a
                        href="javascript:void(0)"
                        @click.prevent="removeItem(i)"
                        ><em class="icon ni ni-trash text-danger"></em
                        ><span></span
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="w-100" v-if="items.length > 0">
                  <hr class="preview-hr" />
                  <div class="float-right w-25">
                    <h6 class="d-flex mb-3 align-items-center">
                      Grand Total:
                      <span class="ml-auto"
                        >{{ appSettings.currency }}
                        {{ addComma(grandTotal) }}</span
                      >
                    </h6>
                  </div>
                  <div class="w-100">
                    <div
                      class="
                        d-flex
                        justify-content-end
                        align-items-center
                        actions
                        w-100
                      "
                    >
                      <div class="col-3">
                        <multiselect
                          v-model="branch"
                          :options="sources"
                          :show-no-results="false"
                          :hide-selected="false"
                          placeholder="Select branch"
                          label="name"
                          track-by="name"
                          v-show="empty(loggedUser.branch)"
                        ></multiselect>
                      </div>
                      <div class="col-3 mb-0 px-1">
                        <input
                          type="date"
                          v-model="committed_date"
                          class="form-control form-control-lg"
                          placeholder="Created Date"
                        />
                      </div>
                      <wolf-button
                        classes="btn btn-primary ml-2"
                        @btn-role="handleSubmit"
                        :disabler="submitDisabled"
                        activator="saving_records"
                      >
                        <span slot="caption"
                          ><em class="icon ni ni-save"></em
                          ><span>Confirm and Save</span></span
                        >
                      </wolf-button>
                    </div>
                  </div>
                </div>
                <div class="row g-gs" v-else>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="example-alert">
                          <div class="alert alert-pro alert-danger">
                            <div class="alert-text">
                              <h6>You haven't added an item</h6>
                              <p>
                                No items found in Requisition list. Search for
                                product and add to the list.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AutoComplete from "@/components/AutoComplete.vue";
import Multiselect from "vue-multiselect";
import { mapState } from "vuex";
export default {
  name: "Reload",
  components: { AutoComplete, Multiselect },
  data: () => ({
    items: [],
    rows: [],
    selectedItemIds: [],
    branch: null,
    committed_date: new Date()
      .toLocaleString("fr-CA", { timeZone: "Africa/Kigali" })
      .slice(0, 10),
  }),
  computed: {
    ...mapState({
      branches: (state) => state.branches,
      lockedBranch: (state) => state.branch,
    }),
    submitDisabled() {
      return (
        !this.items.length ||
        this.items.some((item) => !item.quantity) ||
        (this.empty(this.branch) && this.empty(this.loggedUser.branch))
      );
    },
    me() {
      return this.$store.getters.user;
    },
    grandTotal() {
      return this.items.reduce(
        (prev, cur) => prev + Number(cur.total_amount),
        0
      );
    },
    sources() {
      if (!this.empty(this.lockedBranch)) {
        this.branch = this.lockedBranch;
        return [];
      }
      return this.branches;
    },
  },
  beforeCreate() {
    this.$store.dispatch("getRequest", "shared/products").then((response) => {
      this.rows = response.data.products;
    });
  },
  methods: {
    removeItem(key) {
      this.items.splice(key, 1);
      this.selectedItemIds.splice(key, 1);
    },
    handleSelectedItem(item) {
      if (!this.selectedItemIds.includes(item.id)) {
        this.items.push({
          id: item.id,
          name: item.name,
          type: item.type,
          unit: item.unit,
          code: item.code,
          quantity: 1,
          total_amount: item.cost_price || item.cost,
          price: item.cost_price || item.cost,
          current_qty: item.quantity,
          items_count: 0,
          status: item.status,
        });
        this.selectedItemIds.push(item.id);
      } else {
        this.$notifier({
          type: "info",
          message: "Item already added to the list",
        });
      }
    },
    findTotalAmount(item) {
      item.total_amount = Number(item.price) * Number(item.quantity);
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          var branch = null;
          if (this.lockedBranch) branch = this.lockedBranch.id;
          if (this.empty(branch)) {
            if (!this.empty(this.branch)) branch = this.branch.id;
          }
          let productionItems = this.items.filter((item) => {
            return item.status == "PRODUCTION";
          });
          let stockItems = this.items.filter((item) => {
            return item.status == "STORABLE";
          });
          let perishableItems = this.items.filter((item) => {
            return item.status == "DIRECT_USE";
          });
          this.$store
            .dispatch("postRequest", {
              url: "requisitions/create",
              formData: this.formData({
                branch_id: branch,
                production_items: {
                  items: productionItems,
                  total_amount: productionItems.reduce(
                    (prev, cur) => prev + Number(cur.total_amount),
                    0
                  ),
                },
                stock_items: {
                  items: stockItems,
                  total_amount: stockItems.reduce(
                    (prev, cur) => prev + Number(cur.total_amount),
                    0
                  ),
                },
                perishable_items: {
                  items: perishableItems,
                  total_amount: perishableItems.reduce(
                    (prev, cur) => prev + Number(cur.total_amount),
                    0
                  ),
                },
                committed_date: this.committed_date,
              }),
            })
            .then((response) => {
              if (response.data.status) {
                this.$store.commit("SET_FLASH_MESSAGE", {
                  type: "success",
                  message: response.data.message,
                });
                this.$router.push({ name: "Requests" });
              }
            })
            .catch((err) => console.log(err));
        }
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
