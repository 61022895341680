<template>
  <div class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch p-4">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">Purchase Orders</h3>
                    <div class="nk-block-des text-soft">
                      <!-- <p>You have total {{ totalRows }} products</p> -->
                    </div>
                  </div>
                  <div class="nk-block-head-content w-25">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="javascript:void(0)"
                        class="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                        ><em class="icon ni ni-more-v"></em
                      ></a>
                      <div
                        class="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul class="nk-block-tools g-3">
                          <li class="w-100">
                            <div class="form-control-wrap w-100">
                              <type-head
                                url="stock/products/search"
                                label="name"
                                tracker="name"
                                placeholder="Search by item name"
                                @changed="handleSelectedItem"
                              ></type-head>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="items.length > 0">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col nk-tb-col-check">
                      <div
                        class="
                          custom-control custom-control-sm custom-checkbox
                          notext
                        "
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="pid"
                        /><label class="custom-control-label" for="pid"></label>
                      </div>
                    </div>
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Name</span></div>
                    <div class="nk-tb-col"><span>Code</span></div>
                    <div class="nk-tb-col"><span>Type</span></div>
                    <div class="nk-tb-col"><span>Qty In</span></div>
                    <div class="nk-tb-col"><span>Cost Price</span></div>
                    <div class="nk-tb-col"><span>Quantity</span></div>
                    <div class="nk-tb-col"><span>Total Amount</span></div>
                    <div class="nk-tb-col nk-tb-col-tools"></div>
                  </div>
                  <div
                    class="nk-tb-item"
                    v-for="(product, i) in items"
                    :key="'product' + i"
                  >
                    <div class="nk-tb-col nk-tb-col-check">
                      <div
                        class="
                          custom-control custom-control-sm custom-checkbox
                          notext
                        "
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="pid1"
                        /><label
                          class="custom-control-label"
                          for="pid1"
                        ></label>
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ i + 1 }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ product.name }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">#{{ product.code }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-product">
                        <span class="title">{{ product.type }}</span></span
                      >
                    </div>
                    <div class="nk-tb-col">
                      <span> {{ addComma(product.quantity) }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <div class="form-control-wrap">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">{{
                              appSettings.currency
                            }}</span>
                          </div>
                          <input
                            type="number"
                            class="form-control"
                            v-model="items[i].cost"
                            :placeholder="`Price in ${appSettings.currency}`"
                            :name="'Cost Price' + i"
                            :class="{
                              'is-invalid': errors.has('Cost Price' + i),
                            }"
                            v-validate="'required'"
                            @keyup="findTotalAmount(product)"
                          />
                        </div>
                        <span
                          class="text-danger"
                          v-if="errors.has('Cost Price' + i)"
                          >Cost Price is required</span
                        >
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <div class="form-control-wrap">
                        <div class="form-text-hint">
                          <span class="overline-title">{{ product.unit }}</span>
                        </div>
                        <input
                          type="number"
                          :name="'Quantity' + i"
                          v-model="items[i].store_purchase"
                          :class="{ 'is-invalid': errors.has('Quantity' + i) }"
                          v-validate="'required'"
                          class="form-control"
                          placeholder="Quantity"
                          @keyup="findTotalAmount(product)"
                        />
                        <span
                          class="text-danger"
                          v-if="errors.has('Quantity' + i)"
                          >Quantity is required and
                        </span>
                      </div>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-sub">
                        {{ appSettings.currency }}
                        {{ addComma(items[i].total_amount) }}
                      </span>
                    </div>
                    <div class="nk-tb-col nk-tb-col-tools">
                      <a
                        href="javascript:void(0)"
                        @click.prevent="removeItem(i)"
                        ><em class="icon ni ni-trash text-danger"></em
                        ><span></span
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="w-100" v-if="items.length > 0">
                  <hr class="preview-hr" />
                  <div class="float-right">
                    <h6 class="d-flex mb-3 align-items-center">
                      Grand Total:
                      <span class="ml-auto"
                        >{{ appSettings.currency }}
                        {{ addComma(grandTotal) }}</span
                      >
                    </h6>
                    <div
                      class="
                        d-flex
                        justify-content-center
                        align-items-center
                        actions
                      "
                    >
                      <multiselect
                        v-model="supplier"
                        :options="suppliers"
                        placeholder="Select supplier"
                        label="name"
                        track-by="name"
                      ></multiselect>
                      <div class="form-group col mb-0">
                        <input
                          type="date"
                          v-model="purchase_date"
                          class="form-control form-control-lg"
                          placeholder="Receiving Date"
                        />
                      </div>
                      <wolf-button
                        classes="btn btn-primary ml-2"
                        @btn-role="handleSubmit"
                        :disabler="submitDisabled"
                        activator="saving_records"
                      >
                        <span slot="caption"
                          ><em class="icon ni ni-save"></em
                          ><span>Confirm and Save</span></span
                        >
                      </wolf-button>
                    </div>
                  </div>
                </div>
                <div class="row g-gs" v-else>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="example-alert">
                          <div class="alert alert-pro alert-danger">
                            <div class="alert-text">
                              <h6>No Purchase Order available</h6>
                              <p>
                                No items found in purchase order list. Search
                                for product and add to the list.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import TypeHead from "@/components/TypeHead.vue";
export default {
  name: "PurchaseOrders",
  components: { TypeHead, Multiselect },
  data: () => ({
    items: [],
    rows: [],
    selectedItemIds: [],
    suppliers: [],
    supplier: null,
    purchase_date: new Date()
      .toLocaleString("fr-CA", { timeZone: "Africa/Kigali" })
      .slice(0, 10),
  }),
  computed: {
    submitDisabled() {
      return !this.items.length;
    },
    grandTotal() {
      return this.items.reduce(
        (prev, cur) => prev + Number(cur.total_amount),
        0
      );
    },
  },
  beforeCreate() {
    this.$store.dispatch("getRequest", "orders/items").then((response) => {
      this.items = response.data.rows;
      this.items.forEach((item) => {
        this.selectedItemIds.push(item.id);
        // item.total_amount = Number(item.cost) * Number(item.store_purchase);
      });
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.$store
        .dispatch("getRequest", "settings/suppliers")
        .then((response) => {
          this.suppliers = response.data.rows.data;
        });
    });
  },
  methods: {
    removeItem(key) {
      this.items.splice(key, 1);
      this.selectedItemIds.splice(key, 1);
    },
    handleSelectedItem(item) {
      if (!this.selectedItemIds.includes(item.id)) {
        item.total_amount = Number(item.cost) * Number(item.store_purchase);
        this.items.push(item);
        this.selectedItemIds.push(item.id);
      } else {
        this.$notifier({
          type: "info",
          message: "Item already added to the list",
        });
      }
    },
    findTotalAmount(item) {
      if (!this.empty(item.cost)) {
        item.total_amount = Number(item.cost) * Number(item.store_purchase);
      }
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          var supplier = null;
          if (!this.empty(this.supplier)) {
            supplier = this.supplier.id;
          }
          this.$store
            .dispatch("postRequest", {
              url: "orders/store",
              formData: this.formData({
                supplier: supplier,
                items: this.items,
                purchase_date: this.purchase_date,
                total_amount: this.grandTotal,
              }),
            })
            .then((response) => {
              if (response.data.status) {
                this.$store.commit("SET_FLASH_MESSAGE", {
                  type: "success",
                  message: response.data.message,
                });
                this.$router.push({ name: "OrdersReports" });
              }
            })
            .catch((err) => console.log(err));
        }
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
