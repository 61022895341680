<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="wide-xl mx-auto">
          <div class="card card-stretch p-4">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">
                      Initalize Products Quantity
                    </h3>
                    <div class="nk-block-des text-soft">
                      <p>
                        You have total {{ items.length }} products waiting for
                        quantity initialization
                      </p>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="javascript:void(0)"
                        class="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                        ><em class="icon ni ni-more-v"></em
                      ></a>
                      <div
                        class="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul class="nk-block-tools g-3">
                          <li>
                            <div class="form-control-wrap">
                              <div class="form-icon form-icon-right">
                                <em class="icon ni ni-search"></em>
                              </div>
                              <input
                                type="search"
                                class="form-control"
                                id="default-04"
                                placeholder="Search by name"
                                v-model="searchKey"
                              />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="items.length">
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>Name</span></div>
                    <div class="nk-tb-col"><span>Code</span></div>
                    <div class="nk-tb-col"><span>Item Type</span></div>
                    <div class="nk-tb-col"><span>Cost Price</span></div>
                    <div class="nk-tb-col"><span>Current Qty</span></div>
                    <div class="nk-tb-col"><span>Initial Quantity</span></div>
                  </div>
                  <template v-for="(product, i) in items">
                    <div
                      class="nk-tb-item"
                      v-if="includesInSeasrch(product)"
                      :key="'product' + i"
                    >
                      <div class="nk-tb-col">
                        <span class="tb-product">
                          <span class="title">{{ product.name }}</span></span
                        >
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-product">
                          <span class="title">#{{ product.code }}</span></span
                        >
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-lead">{{ product.type || "N/A" }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-lead"
                          >{{ appSettings.currency }}
                          {{ addComma(product.cost) || "N/A" }}</span
                        >
                      </div>
                      <div class="nk-tb-col">
                        <span class="tb-sub">{{
                          `${addComma(product.quantity)} ${product.unit || ""}`
                        }}</span>
                      </div>
                      <div class="nk-tb-col">
                        <input
                          type="number"
                          :name="'Quantity' + i"
                          v-model="items[i].new_qty"
                          :class="{ 'is-invalid': errors.has('Quantity' + i) }"
                          v-validate="'required'"
                          class="form-control"
                          placeholder="Quantity"
                        />
                      </div>
                    </div>
                  </template>

                  <div
                    class="nk-tb-item"
                    style="position: sticky; bottom: 0; background: #f8f9fc"
                  >
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col">
                      <wolf-button
                        classes="btn btn-primary ml-2"
                        @btn-role="handleSubmit"
                        :disabler="buttonDisabled"
                        activator="saving_records"
                      >
                        <span slot="caption"
                          ><em class="icon ni ni-save"></em
                          ><span>Confirm and Save</span></span
                        >
                      </wolf-button>
                    </div>
                  </div>
                </div>
                <div class="row g-gs" v-else>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="example-alert">
                          <div class="alert alert-fill alert-danger alert-icon">
                            <em class="icon ni ni-cross-circle"></em>
                            <strong>No Products found for initialization</strong
                            >.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Initializer",
  data: () => ({
    items: [],
    searchKey: null,
  }),
  created() {
    this.getItems();
  },
  computed: {
    buttonDisabled() {
      return this.items.every((i) => i.new_qty == 0);
    },
    searchKeyWord() {
      return this.searchKey && this.searchKey.toLowerCase();
    },
    filteredItems() {
      let items = this.items;
      if (searchKey) {
        items = items.filter((row) => {
          return Object.keys(row).some((key) => {
            return String(row[key]).toLowerCase().indexOf(searchKey) > -1;
          });
        });
      }
      return items;
    },
  },
  methods: {
    includesInSeasrch(row) {
      if (!this.empty(this.searchKeyWord)) {
        return Object.keys(row).some((key) => {
          return (
            String(row[key]).toLowerCase().indexOf(this.searchKeyWord) > -1
          );
        });
      }
      return true;
    },
    getItems() {
      this.$store
        .dispatch("getRequest", "shared/initial-products")
        .then((response) => {
          this.items = response.data.products;
        });
    },
    handleSubmit() {
      this.$store
        .dispatch("postRequest", {
          url: "stock/products/initialize-qty",
          formData: this.formData({ items: this.items }),
        })
        .then((response) => {
          if (response.data.status) this.getItems();
        });
    },
  },
};
</script>
