<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="mx-0">
          <div class="card card-stretch p-4">
            <div class="card-inner-group">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between">
                  <div class="nk-block-head-content">
                    <h5 class="nk-block-title page-title">
                      Item Tracking Report<small>
                        <a href="javascript:void(0)">({{ reportLabel }})</a>
                      </small>
                    </h5>
                    <div class="nk-block-des text-soft">
                      <!-- <p>You have total {{ totalRows }} requests.</p> -->
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <div class="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="javascript:void(0)"
                        class="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="more-options"
                        ><em class="icon ni ni-more-v"></em
                      ></a>
                      <div
                        class="toggle-expand-content"
                        data-content="more-options"
                      >
                        <ul class="nk-block-tools g-3">
                          <li>
                            <div class="d-flex align-items-center">
                              <span class="mr-2"> From: </span>
                              <input
                                type="date"
                                class="form-control"
                                v-model="newFilter.from"
                              />
                            </div>
                          </li>
                          <li>
                            <div class="d-flex align-items-center">
                              <span class="mr-2"> To: </span>
                              <input
                                type="date"
                                class="form-control"
                                v-model="newFilter.to"
                              />
                            </div>
                          </li>
                          <li class="nk-block-tools-opt">
                            <button
                              type="button"
                              class="btn btn-primary d-none d-md-inline-flex"
                              :disabled="empty(newFilter.from)"
                              @click="handlePaginationFilter"
                            >
                              <em class="icon ni ni-filter"></em
                              ><span>Filter</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="nk-tb-list is-compact mb-3" v-if="totalRows">
                  <h6
                    class="text-right"
                    style="
                      width: 100%;
                      display: table-caption;
                      padding-right: 7rem;
                    "
                  >
                    Opening Qty:
                    {{ `${fetchedData.open_qty} ${fetchedData.product.unit}` }}
                  </h6>
                  <div class="nk-tb-item nk-tb-head">
                    <div class="nk-tb-col"><span>$/N</span></div>
                    <div class="nk-tb-col"><span>Date</span></div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Item Name</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Description</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Destination</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Received</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Transfered</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Spoiled</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="sub-text">Closed</span>
                    </div>
                  </div>
                  <div
                    class="nk-tb-item"
                    v-for="(row, i) in rows"
                    :key="'row' + i"
                  >
                    <div class="nk-tb-col">
                      <span>{{ i + 1 }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="title">{{
                        chatDate(row.committed_date)
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{
                        fetchedData.product.name
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span>{{
                        fetchedData.product.description || "N/A"
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-lead">{{
                        row.branch || row.source
                      }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span
                        class="tb-amount"
                        v-if="row.transaction_type == 'receive'"
                      >
                        {{
                          `${addComma(row.quantity)} ${
                            fetchedData.product.unit
                          }`
                        }}</span
                      >
                      <span v-else>{{ `0 ${fetchedData.product.unit}` }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span
                        class="tb-amount"
                        v-if="row.transaction_type == 'transfer'"
                      >
                        {{
                          `${addComma(row.quantity)} ${
                            fetchedData.product.unit
                          }`
                        }}</span
                      >
                      <span v-else>{{ `0 ${fetchedData.product.unit}` }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span
                        class="tb-amount"
                        v-if="row.transaction_type == 'spoiled'"
                      >
                        {{
                          `${addComma(row.quantity)} ${
                            fetchedData.product.unit
                          }`
                        }}</span
                      >
                      <span v-else>{{ `0 ${fetchedData.product.unit}` }}</span>
                    </div>
                    <div class="nk-tb-col">
                      <span class="tb-amount">{{
                        `${addComma(closeQuantities[i])}  ${
                          fetchedData.product.unit
                        }`
                      }}</span>
                    </div>
                  </div>
                  <div class="nk-tb-item">
                    <div class="nk-tb-col"><span></span></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col"></div>
                    <div class="nk-tb-col">
                      <h6>
                        {{
                          `${addComma(fetchedData.received_qty)} ${
                            fetchedData.product.unit
                          }`
                        }}
                      </h6>
                    </div>
                    <div class="nk-tb-col">
                      <h6>
                        {{
                          `${addComma(fetchedData.transfered_qty)} ${
                            fetchedData.product.unit
                          }`
                        }}
                      </h6>
                    </div>
                     <div class="nk-tb-col">
                      <h6>
                        {{
                          `${addComma(fetchedData.spoiled_qty)} ${
                            fetchedData.product.unit
                          }`
                        }}
                      </h6>
                    </div>
                    <div class="nk-tb-col"></div>
                  </div>
                </div>
                <div class="row g-gs" v-else>
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="example-alert">
                          <div class="alert alert-fill alert-danger alert-icon">
                            <em class="icon ni ni-cross-circle"></em>
                            <strong>No records found</strong>! Please
                            check your search keywords and try again
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <pagination
                  :from="recordsFrom"
                  :to="recordsTo"
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="handlePagination"
                  :total_records="totalRows"
                  :page_range="pageRange"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { dashboardServices } from "@/_helpers/services";
export default {
  name: "ItemTracker",
  mixins: [dashboardServices],
  data: () => ({
    items: [],
    record: {},
    suppliers: [],
    supplier: null,
  }),
  created() {
    this.fetchUrl = `reports/track-items/${this.$route.params.code}`;
    this.handlePaginationSearch();
  },
  computed: {
    grandTotal() {
      return this.rows.reduce((a, b) => a + Number(b.total_amount), 0);
    },
    closeQuantities() {
      var openQty = this.fetchedData.open_qty;
      /*if (!openQty) {
        return [];
      } */
      var results = [];
      this.rows.forEach((row) => {
        if (row.transaction_type == "receive") openQty += row.quantity;
        else openQty -= row.quantity;
        results.push(openQty);
      });
      return results;
    },
  },
  methods: {},
};
</script>
<style scoped>
.colspan {
  max-width: 1px;
  overflow: visible;
}
.colspan2 {
  /* What to do here? */
  display: table-caption;
}
</style>
