<template>
  <div class="row gy-gs">
    <div class="col-lg-5 col-xl-4">
      <div class="nk-block h-100">
        <div class="nk-block-head-xs">
          <div class="nk-block-head-content">
            <h5 class="nk-block-title title">Overview</h5>
          </div>
        </div>
        <div class="nk-block" style="height: 88%">
          <div class="card card-bordered text-light is-dark h-100">
            <div class="card-inner">
              <div class="nk-wg7">
                <div class="nk-wg7-stats">
                  <div class="nk-wg7-title">Stock in hand</div>
                  <div class="number-lg amount">
                    {{
                      `${appSettings.currency} ${addComma(
                        Math.round(
                          stock_value + branches_value + production_value
                        )
                      )}`
                    }}
                  </div>
                </div>
                <div class="nk-wg7-stats-group">
                  <div class="nk-wg7-stats cl-4">
                    <div class="nk-wg7-title">Main Store</div>
                    <div class="number">
                      {{
                        `${appSettings.currency} ${addComma(
                          Math.round(stock_value)
                        )}`
                      }}
                    </div>
                  </div>
                  <div class="nk-wg7-stats cl-4">
                    <div class="nk-wg7-title">Production</div>
                    <div class="number">
                      {{
                        `${appSettings.currency} ${addComma(
                          Math.round(production_value)
                        )}`
                      }}
                    </div>
                  </div>
                  <div class="nk-wg7-stats cl-4">
                    <div class="nk-wg7-title">Branches</div>
                    <div class="number">
                      {{
                        `${appSettings.currency} ${addComma(
                          Math.round(branches_value)
                        )}`
                      }}
                    </div>
                  </div>
                </div>
                <div class="nk-wg7-foot">
                  <span class="nk-wg7-note"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-7 col-xl-8">
      <div class="nk-block">
        <div class="nk-block-head-xs">
          <div class="nk-block-between-md g-2">
            <div class="nk-block-head-content">
              <h5 class="nk-block-title title">Store overral</h5>
            </div>
            <div class="nk-block-head-content">
              <router-link :to="{ name: '' }" class="link link-primary"
                >See All</router-link
              >
            </div>
          </div>
        </div>
        <div class="row g-2">
          <div class="col-sm-4">
            <div class="card bg-light">
              <div class="nk-wgw sm">
                <a class="nk-wgw-inner" href="javascript:void(0)"
                  ><div class="nk-wgw-name">
                    <div class="nk-wgw-icon">
                      <em class="icon ni ni-activity-round-fill"></em>
                    </div>
                    <h5 class="nk-wgw-title title">Tot. Items</h5>
                  </div>
                  <div class="nk-wgw-balance">
                    <div class="amount">
                      {{ addComma(products) }}
                    </div>
                  </div></a
                >
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card bg-light">
              <div class="nk-wgw sm">
                <a class="nk-wgw-inner" href="javascript:void(0)"
                  ><div class="nk-wgw-name">
                    <div class="nk-wgw-icon">
                      <em class="icon ni ni-activity-round-fill"></em>
                    </div>
                    <h5 class="nk-wgw-title title">Item Types</h5>
                  </div>
                  <div class="nk-wgw-balance">
                    <div class="amount">{{ addComma(types) }}</div>
                  </div></a
                >
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card bg-light">
              <div class="nk-wgw sm">
                <a class="nk-wgw-inner" href="javascript:void(0)"
                  ><div class="nk-wgw-name">
                    <div class="nk-wgw-icon">
                      <em class="icon ni ni-activity-round-fill"></em>
                    </div>
                    <h5 class="nk-wgw-title title">Low Stock Items</h5>
                  </div>
                  <div class="nk-wgw-balance">
                    <div class="amount">
                      {{ addComma(low_stock) }}
                    </div>
                  </div></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="nk-block nk-block-md">
        <div class="nk-block-head-xs">
          <div class="nk-block-between-md g-2">
            <div class="nk-block-head-content">
              <h6 class="nk-block-title title">Pending Payments</h6>
            </div>
            <div class="nk-block-head-content">
              <router-link :to="{ name: '' }" class="link link-primary"
                >See All</router-link
              >
            </div>
          </div>
        </div>
        <div class="row g-2">
          <div class="col-sm-4">
            <div class="card bg-light">
              <div class="nk-wgw sm">
                <a class="nk-wgw-inner" href="javascript:void(0)"
                  ><div class="nk-wgw-name">
                    <div class="nk-wgw-icon">
                      <em class="icon ni ni-activity-round-fill"></em>
                    </div>
                    <h5 class="nk-wgw-title title">Supplier Payments</h5>
                  </div>
                  <div class="nk-wgw-balance">
                    <div class="amount">
                      <span class="currency currency-nio">{{
                        appSettings.currency
                      }}</span
                      >{{ addComma(supplier_payments) }}
                    </div>
                  </div></a
                >
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card bg-light">
              <div class="nk-wgw sm">
                <a class="nk-wgw-inner" href="javascript:void(0)"
                  ><div class="nk-wgw-name">
                    <div class="nk-wgw-icon">
                      <em class="icon ni ni-activity-round-fill"></em>
                    </div>
                    <h5 class="nk-wgw-title title">Production Invoices</h5>
                  </div>
                  <div class="nk-wgw-balance">
                    <div class="amount">
                      <span class="currency currency-nio">{{
                        appSettings.currency
                      }}</span>
                      {{ addComma(production_invoices) }}
                    </div>
                  </div></a
                >
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card bg-light">
              <div class="nk-wgw sm">
                <a class="nk-wgw-inner" href="javascript:void(0)"
                  ><div class="nk-wgw-name">
                    <div class="nk-wgw-icon">
                      <em class="icon ni ni-activity-round-fill"></em>
                    </div>
                    <h5 class="nk-wgw-title title">Sales Invoices</h5>
                  </div>
                  <div class="nk-wgw-balance">
                    <div class="amount">
                      <span class="currency currency-nio">{{
                        appSettings.currency
                      }}</span
                      >{{ addComma(0) }}
                    </div>
                  </div></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Overview",
  data: () => ({
    stock_value: 0,
    branches_value: 0,
    production_value: 0,
    low_stock: 0,
    low_branches: 0,
    types: 0,
    products: 0,
    supplier_payments: 0,
    production_invoices: 0,
  }),
  created() {
    this.$store.dispatch("getRequest", "stock/overview").then((response) => {
      this.stock_value = response.data.stock_value;
      this.branches_value = response.data.branches_value;
      this.production_value = response.data.production_value;
      this.low_stock = response.data.low_stock;
      this.low_branches = response.data.low_branches;
      this.types = response.data.types;
      this.products = response.data.products;
      this.supplier_payments = response.data.supplier_payments;
      this.production_invoices = response.data.production_invoices;
    });
  },
};
</script>
<style scoped>
.card.is-dark {
  background: #0b3175;
}
.card-bordered {
  border: 1px solid #dbdfea;
}
.card-bordered.is-dark {
  border-color: #0b3175;
}
.card-inner {
  padding: 1.25rem;
}
@media (min-width: 576px) {
  .card-inner {
    padding: 1.5rem;
  }
}
.g-2:not(.row) {
  margin: -0.375rem;
}
.g-2:not(.row) > div {
  padding: 0.375rem;
}
.nk-block + .nk-block {
  padding-top: 28px;
}
.nk-block-head-xs {
  padding-bottom: 0.75rem;
}
@media (min-width: 768px) {
  .nk-block-between-md {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.nk-wg7 {
  display: flex;
  flex-wrap: wrap;
}
.nk-wg7 > * {
  flex-grow: 1;
  width: 100%;
}
.nk-wg7-stats {
  line-height: 1.3;
}
.nk-wg7-stats-group {
  display: flex;
  align-items: flex-start;
  margin-top: 1.5rem;
}
.nk-wg7-stats .number {
  font-size: 1rem;
  font-weight: 700;
}
.nk-wg7-stats .number-lg {
  font-size: 2.25rem;
  letter-spacing: -0.03em;
  font-weight: 400;
}
.nk-wg7-title,
.nk-wg7-note {
  font-size: 13px;
  font-weight: 500;
  color: #526484;
}
.is-dark .nk-wg7-title,
.is-dark .nk-wg7-note {
  color: #6baafe;
}
.nk-wg7-title + .number {
  margin-top: 0.4375rem;
}
.nk-wg7-title + .number-lg {
  margin-top: 0.25rem;
}
.nk-wg7-foot {
  width: 100%;
  margin-top: 0.75rem;
}
.nk-wg7-note {
  font-weight: 400;
  font-style: italic;
}
@media (min-width: 992px) {
  .nk-wg7 > * {
    width: 100%;
  }
  .nk-wg7-foot {
    margin-top: 0.75rem;
  }
}
.nk-wgw {
  position: relative;
}
.nk-wgw-inner {
  padding: 1.25rem;
  display: block;
}
.sm.nk-wgw .nk-wgw-inner {
  padding: 1.25rem;
}
.nk-wgw-name {
  display: flex;
  align-items: center;
}
.nk-wgw-icon {
  position: relative;
  height: 32px;
  width: 32px;
  font-size: 20px;
  border-radius: 50%;
  background: #8094ae;
  color: #fff;
  margin-right: 0.75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.nk-wgw-title {
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0;
  color: #526484;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nk-wgw-balance {
  line-height: 1.3;
  padding-top: 1.25rem;
}
.nk-wgw-balance .amount {
  font-size: 1.5rem;
  font-weight: 500;
  color: #364a63;
}
.nk-wgw-balance .amount span {
  font-weight: 400;
  opacity: 0.6;
}
.nk-wgw-balance .currency {
  padding-left: 0.25rem;
}
@media (min-width: 576px) {
  .nk-wgw {
    position: relative;
  }
  .nk-wgw-inner {
    padding: 1.5rem;
  }
  .sm.nk-wgw .nk-wgw-inner {
    padding: 1rem 1.25rem;
  }
}
.nk-wgw.sm .nk-wgw-icon {
  height: 24px;
  width: 24px;
  font-size: 14px;
  margin-right: 0.5rem;
}
.nk-wgw.sm .nk-wgw-title {
  font-weight: 700;
  font-size: 13px;
}
.nk-wgw.sm .nk-wgw-balance {
  padding-top: 0.75rem;
}
.nk-wgw.sm .nk-wgw-balance .amount {
  font-size: 1rem;
}
.cl-4 {
  width: 33.33333333%;
}
</style>
