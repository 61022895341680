<template>
  <bootstrap-modal
    @close="$emit('closed')"
    style="display: block"
    :modalSize="'modal-lg'"
    :showFooter="false"
  >
    <span slot="head" style="padding-top: 10px">
      {{ newUser.id ? "Update System User" : "Add a new System User" }}
    </span>
    <div slot="body">
      <form class="forms-sample">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <div class="form-group mb-3">
                <label class="form-label">First Name</label>
                <input
                  type="text"
                  v-model="newUser.first_name"
                  name="First Name"
                  v-validate="'required'"
                  :class="{ 'is-invalid': errors.has('First Name') }"
                  class="form-control"
                  placeholder="First Name"
                />
                <div class="invalid-feedback">
                  {{ errors.first("First Name") }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-3">
              <label class="form-label">Last Name</label>
              <input
                type="text"
                v-model="newUser.last_name"
                name="Last Name"
                v-validate="'required'"
                :class="{ 'is-invalid': errors.has('Last Name') }"
                class="form-control"
                placeholder="Last Name"
              />
              <div class="invalid-feedback">
                {{ errors.first("Last Name") }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label">Gender</label>
              <br />
              <div
                class="custom-control custom-radio mr-3"
                :class="{ checked: newUser.type == 'M' }"
              >
                <input
                  type="radio"
                  id="GenderType1"
                  name="gender"
                  :value="'M'"
                  v-model="newUser.gender"
                  class="custom-control-input"
                /><label class="custom-control-label" for="GenderType1"
                  >Male</label
                >
              </div>
              <div
                class="custom-control custom-radio mr-3"
                :class="{ checked: newUser.gender == 'F' }"
              >
                <input
                  type="radio"
                  id="GenderType2"
                  name="gender"
                  :value="'F'"
                  v-model="newUser.gender"
                  class="custom-control-input"
                /><label class="custom-control-label" for="GenderType2"
                  >Female</label
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-3">
              <label class="form-label">ID Number</label>
              <input
                type="text"
                v-model="newUser.id_number"
                name="ID Number"
                class="form-control"
                placeholder="ID Number"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group mb-3">
              <label class="form-label">Phone Number</label>
              <input
                type="text"
                v-model="newUser.phone"
                name="Phone"
                v-validate="'required'"
                :class="{ 'is-invalid': errors.has('Phone') }"
                class="form-control"
                placeholder="Phone Number"
              />
              <div class="invalid-feedback">
                {{ errors.first("Phone Number") }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-3">
              <label class="form-label">Email</label>
              <input
                type="text"
                v-model="newUser.email"
                name="Email"
                v-validate="'required|email'"
                :class="{ 'is-invalid': errors.has('Email') }"
                class="form-control"
                placeholder="Email Address"
              />
              <div class="invalid-feedback">
                {{ errors.first("Email") }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-3">
              <label class="form-label">Address</label>
              <input
                type="text"
                v-model="newUser.address"
                class="form-control"
                placeholder="Address Line"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-3">
              <label class="form-label">Salary</label>
              <input
                type="text"
                v-model="newUser.salary"
                class="form-control"
                placeholder="Salary"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-3">
              <label class="form-label">Branch</label>
              <div class="form-control-wrap">
                <div class="form-control-select">
                  <select
                    class="form-control"
                    id="default-06"
                    v-model="newUser.branch_id"
                    name="Branch"
                  >
                    <option value="null" hidden disabled>Select Branch</option>
                    <option
                      :value="item.id"
                      v-for="(item, i) in branches"
                      :key="'branch' + i"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <div class="invalid-feedback">
                    {{ errors.first("Branch") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-3">
              <label class="form-label">Role / Priveledge</label>
              <div class="form-control-wrap">
                <div class="form-control-select">
                  <select
                    class="form-control"
                    id="default-06"
                    v-model="newUser.role_id"
                    name="Role"
                    v-validate="'required'"
                    :class="{ 'is-invalid': errors.has('Role') }"
                  >
                    <option value="null" hidden disabled>Select Role</option>
                    <option
                      :value="item.id"
                      v-for="(item, i) in roles"
                      :key="'role' + i"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <div class="invalid-feedback">
                    {{ errors.first("Role") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <hr class="preview-hr mt-0 mb-2" />
            <span class="preview-title-lg overline-title"
              >Login Credentials</span
            >
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="form-group mb-3">
                <label class="form-label">Username</label>
                <input
                  type="email"
                  :value="newUser.email"
                  name="Username"
                  v-validate="'required'"
                  :class="{ 'is-invalid': errors.has('Username') }"
                  class="form-control"
                  placeholder="Username"
                  readonly
                />
                <div class="invalid-feedback">
                  {{ errors.first("Username") }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6" v-if="empty(user)">
            <div class="form-group mb-3">
              <label class="form-label">Password</label>
              <input
                type="Password"
                v-model="newUser.password"
                name="Password"
                v-validate="'required'"
                :class="{ 'is-invalid': errors.has('Password') }"
                class="form-control"
                placeholder="Password"
              />
              <div class="invalid-feedback">
                {{ errors.first("Password") }}
              </div>
            </div>
          </div>
        </div>

        <div class="my-3">
          <wolf-button
            classes="btn btn-primary"
            caption="Save and Close"
            @btn-role="handleSubmit"
            :disabler="buttonDisabled"
            activator="saving_records"
          ></wolf-button>
        </div>
      </form>
    </div>
  </bootstrap-modal>
</template>
<script>
export default {
  name: "UserCreator",
  props: ["roles", "branches", "user"],
  data: () => ({
    newUser: {
      first_name: null,
      last_name: null,
      email: null,
      password: null,
      address: null,
      phone: null,
      gender: null,
      id_number: null,
      salary: null,
      department_id: null,
      branch_id: null,
      role_id: null,
      avatar: null,
      username: null,
    },
  }),
  computed: {
    buttonDisabled() {
      return (
        !this.newUser.first_name || !this.newUser.email || !this.newUser.phone
      );
    },
  },
  created() {
    if (!this.empty(this.user)) {
      this.newUser = { ...this.newUser, ...this.user };
    }
  },
  methods: {
    handleUploadedImage(file) {
      this.newUser.avatar = file.file_path;
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store
            .dispatch("postRequest", {
              formData: this.formData(this.newUser),
              url: "users/create",
            })
            .then((response) => {
              if (response.data.status) {
                this.$emit("saved", response.data.user);
                this.clearObject(this.newUser);
                this.$validator.reset();
              }
            });
        }
      });
    },
  },
};
</script>
