<template>
  <bootstrap-modal
    @close="$emit('closed')"
    style="display: block"
    :modalSize="'modal-lg'"
    :showFooter="false"
  >
    <span slot="head" style="padding-top: 10px">
      {{ newSupplier.id ? "Update Supplier" : "Add a new Supplier" }}
    </span>
    <div slot="body">
      <form class="forms-sample">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label">Category</label>
              <br />
              <div
                class="custom-control custom-radio mr-3"
                :class="{ checked: newSupplier.type == 'STORE' }"
              >
                <input
                  type="radio"
                  id="supplierType1"
                  name="supplierType"
                  :value="'STORE'"
                  v-model="newSupplier.type"
                  class="custom-control-input"
                /><label class="custom-control-label" for="supplierType1"
                  >STORE</label
                >
              </div>
              <div
                class="custom-control custom-radio mr-3"
                :class="{ checked: newSupplier.type == 'SERVICE' }"
              >
                <input
                  type="radio"
                  id="supplierType2"
                  name="supplierType"
                  :value="'SERVICE'"
                  v-model="newSupplier.type"
                  class="custom-control-input"
                /><label class="custom-control-label" for="supplierType2"
                  >SERVICE</label
                >
              </div>

              <div
                class="custom-control custom-radio mr-3"
                :class="{ checked: newSupplier.type == 'OTHER' }"
              >
                <input
                  type="radio"
                  id="supplierType4"
                  name="supplierType"
                  :value="'OTHER'"
                  v-model="newSupplier.type"
                  class="custom-control-input"
                /><label class="custom-control-label" for="supplierType4"
                  >OTHER</label
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-3">
              <label class="form-label">Supplier Name</label>
              <input
                type="text"
                v-model="newSupplier.name"
                name="Supplier Name"
                v-validate="'required'"
                :class="{ 'is-invalid': errors.has('Supplier Name') }"
                class="form-control"
                placeholder="Supplier Name"
              />
              <div class="invalid-feedback">
                {{ errors.first("Supplier Name") }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group mb-3">
              <label class="form-label">Phone Number</label>
              <input
                type="text"
                v-model="newSupplier.phone"
                class="form-control"
                placeholder="Business Phone"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-3">
              <label class="form-label">Email</label>
              <input
                type="text"
                v-model="newSupplier.email"
                class="form-control"
                placeholder="Email Address"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-3">
              <label class="form-label">Address</label>
              <input
                type="text"
                v-model="newSupplier.address"
                class="form-control"
                placeholder="Address Line"
              />
            </div>
            <files-uploader
              :input-id="'csupplier-document'"
              @after-uploading="handleUploadedImage"
              :extentions="['pdf', 'docx', 'doc', 'ico', 'webp']"
            ></files-uploader>
            <div class="form-group">
              <label class="form-label">File / Document</label>
              <div class="form-control-wrap">
                <div class="form-file">
                  <input
                    type="file"
                    class="form-file-input"
                    id="customFile"
                    @click.prevent="showFilePicker('csupplier-document')"
                  /><label class="form-file-label" for="customFile"
                    >Choose file</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-3">
              <label class="form-label">Description</label>
              <textarea
                type="text"
                v-model="newSupplier.description"
                name="description"
                class="form-control"
                placeholder="Write a brief comments"
              ></textarea>
              <div class="invalid-feedback">
                {{ errors.first("description") }}
              </div>
            </div>
          </div>
        </div>

        <div class="my-3">
          <wolf-button
            classes="btn btn-primary"
            caption="Save and Close"
            @btn-role="handleSubmit"
            :disabler="!newSupplier.phone || !newSupplier.name"
            activator="saving_records"
          ></wolf-button>
        </div>
      </form>
    </div>
  </bootstrap-modal>
</template>
<script>
import WolfFilesUploader from "@/components/WolfFilesUploader.vue";
export default {
  name: "Suppliers",
  props: {
    supplier: {
      type: Object,
      require: false,
    },
  },
  components: {
    "files-uploader": WolfFilesUploader,
  },
  data: () => ({
    newSupplier: {
      name: null,
      address: null,
      phone: null,
      email: null,
      document: null,
      status: 1,
      type: "STORE",
      balance: 0,
    },
  }),
  created() {
    if (!this.empty(this.supplier)) {
      this.newSupplier = this.supplier;
    }
  },
  methods: {
    handleUploadedImage(file) {
      this.newSupplier.document = file.file_path;
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let url = this.newSupplier.id
            ? "settings/suppliers/update"
            : "settings/suppliers/create";
          this.$store
            .dispatch("postRequest", {
              formData: this.formData(this.newSupplier),
              url,
            })
            .then((response) => {
              if (response.data.status) {
                this.$emit("saved", response.data.supplier);
                this.clearObject(this.newSupplier);
                this.$validator.reset();
              }
            });
        }
      });
    },
  },
};
</script>
