<template>
  <section class="container-fluids">
    <div class="nk-content-inner">
      <div class="nk-content-body">
        <div class="nk-block-head nk-block-head-sm">
          <div class="nk-block-between">
            <div class="nk-block-head-content">
              <h3 class="nk-block-title page-title">Permissions Matrix</h3>
            </div>
            <div class="nk-block-head-content">
              <div class="toggle-wrap nk-block-tools-toggle"></div>
            </div>
          </div>
        </div>
        <div class="nk-block">
          <div class="card card-stretch pb-4">
            <div class="card-inner mb-n2">
              <div class="card-title-group">
                <div class="card-title card-title-sm">
                  <h6 class="title">Set user privileges</h6>
                </div>
              </div>
            </div>
            <div class="card-inner-group">
              <div class="card-inner p-0">
                <div class="nk-tb-list is-compact mb-3">
                  <div
                    class="nk-tb-item nk-tb-head"
                    :class="{ 'stick-table': scrolled }"
                  >
                    <!-- <div class="nk-tb-col nk-tb-col-check">
                      <span class="sub-text">#</span>
                    </div> -->
                    <div class="nk-tb-col">
                      <span class="sub-text">Privilege</span>
                    </div>
                    <div
                      class="nk-tb-col"
                      v-for="(role, i) in roles"
                      :key="'roles' + i"
                    >
                      <span class="sub-text">{{ role.name }}</span>
                    </div>
                  </div>
                  <template v-for="(item, i) in permissions">
                    <div class="nk-tb-item" :key="'menu' + i">
                      <div class="nk-tb-col">
                        <h6>{{ item.menu }}</h6>
                      </div>
                    </div>
                    <div
                      class="nk-tb-item"
                      v-for="(privilege, key) in getPrivileges(item)"
                      :key="'item' + i + key"
                    >
                      <!-- <div class="nk-tb-col">
                        <span>{{ key + 1 }}</span>
                      </div> -->
                      <div class="nk-tb-col">
                        <span class="tb-lead">{{ inputTitle(privilege) }}</span>
                      </div>
                      <div
                        class="nk-tb-col nk-tb-col-check text-center"
                        v-for="(i, index) in Array(roles.length)"
                        :key="privilege + 'roles' + index"
                      >
                        <div
                          class="
                            custom-control custom-control-sm custom-checkbox
                            notext
                          "
                        >
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            :id="privilege + '-roles-' + index"
                            v-model="roles[index].permissions"
                            :value="privilege"
                          /><label
                            class="custom-control-label"
                            :for="privilege + '-roles-' + index"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="row g-3">
                  <div class="col-lg-12 text-right">
                    <div class="form-group mt-2 pr-4">
                      <wolf-button
                        classes="btn btn-lg btn-primary"
                        @btn-role="handleUpdate"
                        :disabler="false"
                        activator="saving_records"
                      >
                        <span slot="caption"
                          ><em class="icon ni ni-save"></em
                          ><span>Update Privileges</span></span
                        >
                      </wolf-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Permissions",
  data: () => ({
    roles: [],
    permissions: [],
    scrolled: false,
  }),
  beforeCreate() {
    this.$store
      .dispatch("getRequest", "shared/assets/permissions")
      .then((response) => {
        this.roles = response.data.roles;
        this.permissions = response.data.permissions;
      });
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 100;
    },
    getPrivileges(permission) {
      return permission.privileges.split(",");
    },
    inputTitle(text) {
      return this.capitalizeFirstLetter(text.split("_").join(" "));
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    handleUpdate() {
      this.$store
        .dispatch("postRequest", {
          url: "users/permissions/update",
          formData: this.formData({
            permissions: this.roles,
          }),
        })
        .then(() => {});
    },
  },
};
</script>
<style scoped>
.stick-table {
  position: sticky;
  top: 60px;
  background: var(--base-color);
  z-index: 90;
}
.nk-tb-head.stick-table .nk-tb-col,
.nk-tb-head.stick-table .sub-text {
  color: #fff;
  font-weight: bolder;
}
</style>
