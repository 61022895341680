<template>
  <div class="container-fluid">
    <div class="nk-content-inner">
      <div class="nk-content-body" v-if="empty(loggedUser.branch)">
        <div class="nk-block-head nk-block-head-sm">
          <div class="nk-block-between">
            <div class="nk-block-head-content">
              <h3 class="nk-block-title page-title">Dashboard</h3>
            </div>
            <div class="nk-block-head-content">
              <div class="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#"
                  class="btn btn-icon btn-trigger toggle-expand me-n1"
                  data-target="pageMenu"
                  ><em class="icon ni ni-more-v"></em
                ></a>
                <div class="toggle-expand-content" data-content="pageMenu">
                  <ul class="nk-block-tools g-3">
                    <li>
                      <div class="drodown filter-dropdown position-relative">
                        <a
                          href="javascript:void(0)"
                          class="
                            dropdown-toggle
                            btn btn-white btn-dim btn-outline-light
                          "
                          data-bs-toggle="dropdown"
                          ><em
                            class="d-none d-sm-inline icon ni ni-calender-date"
                          ></em
                          ><span
                            ><span class="d-none d-md-inline">Last</span> 30
                            Days</span
                          ><em class="dd-indc icon ni ni-chevron-right"></em
                        ></a>
                        <div class="dropdown-menu dropdown-menu-end">
                          <ul class="link-list-opt no-bdr">
                            <li>
                              <a href="#"><span>Last 30 Days</span></a>
                            </li>
                            <li>
                              <a href="#"><span>Last 6 Months</span></a>
                            </li>
                            <li>
                              <a href="#"><span>Last 1 Years</span></a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li class="nk-block-tools-opt">
                      <a href="#" class="btn btn-primary"
                        ><em class="icon ni ni-reports"></em
                        ><span>Reports</span></a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nk-block" v-if="dataAvailable">
          <inventory-cards
            :receives="receives"
            :transfers="transfers"
            :orders="orders"
            :spoiled="spoiled"
          ></inventory-cards>
          <div class="row g-gs">
            <in-out-chart :records="inOut"></in-out-chart>
            <purchase-orders-chart
              :records="orders.stats"
            ></purchase-orders-chart>
            <div class="col-xxl-3 col-md-6">
              <div class="card h-100 card-bordered">
                <div class="card-inner">
                  <div class="card-title-group mb-2">
                    <div class="card-title">
                      <h6 class="title">Store Statistics</h6>
                    </div>
                  </div>
                  <ul class="nk-store-statistics">
                    <li class="item">
                      <div class="info">
                        <div class="title">Total Items</div>
                        <div class="count">
                          {{ addComma(numbers.store_items) }}
                        </div>
                      </div>
                      <em class="icon bg-primary-dim ni ni-box"></em>
                    </li>
                    <li class="item">
                      <div class="info">
                        <div class="title">Production Items</div>
                        <div class="count">
                          {{ addComma(numbers.production_items) }}
                        </div>
                      </div>
                      <em class="icon bg-info-dim ni ni-box"></em>
                    </li>
                    <li class="item">
                      <div class="info">
                        <div class="title">Sales Items</div>
                        <div class="count">
                          {{ addComma(numbers.sales_items) }}
                        </div>
                      </div>
                      <em class="icon bg-pink-dim ni ni-box"></em>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-xxl-8">
              <overview></overview>
            </div>
            <production-sales :records="production"></production-sales>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InOutChart from "./InOutChart.vue";
import InventoryCards from "./InventoryCards.vue";
import PurchaseOrdersChart from "./PurchaseOrdersChart.vue";
import ProductionSales from "./ProductionSales.vue";
import Overview from "./Overview.vue";
export default {
  name: "Dashboard",
  components: {
    InventoryCards,
    InOutChart,
    PurchaseOrdersChart,
    ProductionSales,
    Overview,
  },
  data: () => ({
    inOut: {},
    receives: {},
    transfers: {},
    spoiled: {},
    numbers: {},
    production: {},
  }),
  computed: {
    dataAvailable() {
      return (
        !this.empty(this.inOut) &&
        !this.empty(this.receives) &&
        !this.empty(this.transfers)
      );
    },
  },
  beforeCreate() {
    this.$store.dispatch("getRequest", "reports/dashboard").then((response) => {
      this.inOut = response.data.inOut;
      this.receives = response.data.receives;
      this.transfers = response.data.transfers;
      this.orders = response.data.orders;
      this.spoiled = response.data.spoiled;
      this.numbers = response.data.numbers;
      this.production = response.data.production;
    });
  },
};
</script>
